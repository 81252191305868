import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import Root from "./components/Root";
import ErrorPage from "./components/ErrorPage";
import { MerchantPage, loader as MerchantLoader } from "./components/Merchant";
import { LocationPage, loader as LocationLoader } from "./components/Location";
import { Customer, loader as CardLoader } from "./components/Customer";
import {
  MerchantRedeemAccept,
  loader as RedeemLoader,
} from "./components/MerchantRedeemAccept";
import { MerchantShowClaim } from "./components/MerchantShowClaim";
import {
  CustomerAcceptClaim,
  loader as CustomerAcceptClaimLoader,
  action as CustomerAcceptClaimAction,
} from "./components/CustomerAcceptClaim";
import { Settings } from "./components/Settings";
import { Checkout, action as CheckoutAction } from "./components/Checkout";
import { CheckoutSuccess } from "./components/CheckoutSuccess";
import { CreateClaim } from "./components/CreateClaim";
import {
  CreateMerchant,
  action as CreateMerchantAction,
} from "./components/CreateMerchant";
import {
  CustomerRedeemClaim,
  loader as CustomerRedeemClaimLoader,
} from "./components/CustomerRedeemClaim";
import {
  CustomerRedeemShow,
  loader as CustomerRedeemShowLoader,
} from "./components/CustomerRedeemShow";
import {
  CustomerCard,
  loader as CustomerCardLoader,
} from "./components/CustomerCard";
import {
  LocationStartClaim,
  loader as LocationStartClaimLoader,
} from "./components/LocationStartClaim";
import {
  LocationClaim,
  loader as LocationClaimLoader,
} from "./components/LocationClaim";
import { Gateway } from "./components/Gateway";
import {
  CustomerStartClaim,
  loader as CustomerStartClaimLoader,
} from "./components/CustomerStartClaim";
import { Scan } from "./components/Scan";
import {
  Dashboard,
  loader as DashboardLoader,
  action as DashboardAction,
} from "./components/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement: <ErrorPage />,
        loader: DashboardLoader,
        action: DashboardAction,
      },
      {
        path: "location/:locationId",
        element: <LocationStartClaim />,
        loader: LocationStartClaimLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "gateway/:type/:id/:id2",
        element: <Gateway />,
        errorElement: <ErrorPage />,
      },
      {
        path: "gateway/:type/:id",
        element: <Gateway />,
        errorElement: <ErrorPage />,
      },
      {
        path: "merchant",
        element: <MerchantPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "merchant/new",
        element: <CreateMerchant />,
        errorElement: <ErrorPage />,
        action: CreateMerchantAction,
      },
      {
        path: "merchant/redeem/:redeemId",
        element: <MerchantRedeemAccept />,
        errorElement: <ErrorPage />,
        loader: RedeemLoader,
      },
      {
        path: "merchant/:merchantId",
        element: <MerchantPage />,
        errorElement: <ErrorPage />,
        loader: MerchantLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return !currentUrl.pathname.endsWith("/scan");
        },
        children: [
          {
            path: "location/:locationId",
            element: <LocationPage />,
            loader: LocationLoader,
            children: [
              {
                path: "claim/:claimId",
                element: <MerchantShowClaim />,
              },
              {
                path: "create",
                element: <CreateClaim />,
              },
              {
                path: "scan",
                element: <Scan />,
                errorElement: <ErrorPage />,
              },
            ],
          },
          {
            path: "settings",
            element: <Settings />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        path: "claim/:locationId",
        element: <LocationClaim />,
        loader: LocationClaimLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "customer",
        element: <Customer />,
        children: [
          {
            path: "claim/:claimId",
            element: <CustomerAcceptClaim />,
            errorElement: <ErrorPage />,
            loader: CustomerAcceptClaimLoader,
            action: CustomerAcceptClaimAction,
          },
        ],
      },
      {
        path: "customer/:userId",
        element: <Customer />,
        loader: CardLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return !currentUrl.pathname.endsWith("/scan");
        },
        children: [
          {
            path: "card/:merchantId",
            element: <CustomerCard />,
            errorElement: <ErrorPage />,
            loader: CustomerCardLoader,
          },
          {
            path: "claim/:cardId",
            element: <CustomerStartClaim />,
            errorElement: <ErrorPage />,
            loader: CustomerStartClaimLoader,
          },
          {
            path: "redeem/:cardId",
            element: <CustomerRedeemClaim />,
            errorElement: <ErrorPage />,
            loader: CustomerRedeemClaimLoader,
          },
          {
            path: "redeemed/:redeemId",
            element: <CustomerRedeemShow />,
            errorElement: <ErrorPage />,
            loader: CustomerRedeemShowLoader,
          },
          {
            path: "checkout",
            action: CheckoutAction,
            errorElement: <ErrorPage />,
            element: <Checkout />,
          },
          {
            path: "checkout-success",
            element: <CheckoutSuccess />,
          },
          {
            path: "scan",
            element: <Scan />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  }
]);

const container = document.getElementById("root");
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(<RouterProvider router={router} />);
} else {
  <h1>No root found</h1>;
}
