export interface UserInfo {
  identityProvider: string | undefined;
  userDetails: string | undefined;
  userId: string;
  userRoles: string[];
  claims: string[];
}

export interface User {
  id: string;
  identity_provider: string;
  merchant_id: string | undefined;
  user_details: string | undefined;
  user_name: string | undefined;
  user_id: string;
  subscription_id: string | undefined;
  subscription_status: string | undefined;
  subscription_quantity: number | undefined;
  subscription_customer_id: string | undefined;
  subscription_plan_id: string | undefined;
  customer_name: string | undefined;
  email: string | undefined;
}

export interface CatalogObject {
  abbreviation: string;
  description_html: string;
  description_plaintext: string;
  id: string;
  name: string;
}

export interface Location {
  id: string;
  name: string;
  manual: boolean;
  active: boolean;
}

export interface Merchant {
  business_name: string;
  catalog_items: CatalogObject[] | undefined;
  subscription_locations: Location[] | undefined;
  country: string | undefined;
  logo_url: string | undefined;
  created_at: string;
  currency: string | undefined;
  expires_at: string | undefined;
  id: string;
  main_location_id: string;
  merchant_id: string | undefined;
  owner_email: string | undefined;
  redeem_index: number;
}

export interface Claim {
  id: string;
  user_id: string | undefined;
  quantity: number;
  card_id: string;
  merchant_id: string;
  location_id: string | undefined;
  created_at: string;
  user: User | undefined;
  is_pending: boolean | undefined;
}

export interface Card {
  id: string;
  user_id: string;
  merchant_id: string;
  redeemed_at: string;
  redeem_index: number;
  redeem_quantity: number;
  created_at: string;
  deleted_at: string;
  claims: [Claim];
  merchant: Merchant | undefined;
  redeems: [Redeem] | undefined;
}

export interface CardCache {
  cards: Card[];
  refreshed_at: string | undefined;
}

export interface SquareEvent {
  id: string;
  merchantId: string;
  locationId: string;
  referenceId: string;
  source: string;
  createdAt: string;
  closedAt: string;
  state: string;
  lineItems: {
    uid: string;
    quantity: number;
    name: string;
  }[];
  isPunched: boolean | undefined;
}

export interface Redeem {
  id: string;
  card_id: string;
  user_id: string;
  merchant_id: string;
  location_id: string;
  created_at: string;
  redeemed_at: string;
  card: Card;
  user: User;
}

export enum SubscriptionStatus {
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export interface GatewayRedirect {
  type: string;
  id: string;
}

export const SquareAPIUrl =
  process.env.NODE_ENV === "production"
    ? `https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-ac5sICV4oRb5ifigfJ52Qg&scope=MERCHANT_PROFILE_READ+ITEMS_READ+ORDERS_READ&session=false&state=82201dd8d83d23cc8a48caf52b&redirect_uri=${window.location.protocol}//${window.location.host}/merchant`
    : `https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-_z6bxqP2osj0ZT4lSZ5Ibw&scope=MERCHANT_PROFILE_READ+ITEMS_READ+ORDERS_READ&session=false&state=82201dd8d83d23cc8a48caf52b&redirect_uri=${window.location.protocol}//${window.location.host}/merchant`;
