import { useContext, useEffect, useState } from "react";
import {
  useLoaderData,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { DataContext } from "../lib/dataContext";
import { callFunction } from "../controllers/apiClient";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  CardHeader,
  Heading,
  CardFooter,
  Button,
  Card as ChakraCard,
  Text,
  Center,
  Avatar,
  CardBody,
  Divider,
  Select,
} from "@chakra-ui/react";
import ErrorPage from "./ErrorPage";
import { Merchant } from "../lib/constants";
import { motion, AnimatePresence } from "framer-motion";

export async function loader({ params }: any) {
  try {
    const cardId = params.cardId;
    if (cardId) {
      const merchantResponse = await callFunction(
        "merchant",
        "get",
        undefined,
        {
          merchant_card_id: cardId,
        }
      );
      return { merchantResponse };
    } else return;
  } catch (err) {
    throw new Error("Could not fetch Merchant data");
  }
}

const CustomerStartClaimHeading = "Punch your card!";
const ConfirmLocationDescription = "Which location are you at?";

export const CustomerStartClaim = () => {
  const { user, userInfo } = useContext(DataContext);
  const { merchantResponse } = useLoaderData() as any;
  const merchant = merchantResponse as Merchant;
  const [locationId, setLocationId] = useState<string>();
  const navigate = useNavigate();
  const isAtCustomerStartClaim = useMatch("/customer/:userId/claim/:cardId");
  const isCustomerStartClaimRoot = useMatch("/customer/:userId/claim/");

  useEffect(() => {
    if (isCustomerStartClaimRoot && userInfo && user && user.id) {
      navigate(`/customer/${user.id}`);
    }
  }, [isCustomerStartClaimRoot, user, userInfo, navigate]);

  useEffect(() => {
    if (
      merchant &&
      merchant.subscription_locations &&
      merchant.subscription_locations.filter((l) => l.active).length > 0
    ) {
      setLocationId(
        merchant.subscription_locations.filter((l) => l.active)[0].id
      );
    }
  }, [merchant]);

  if (
    merchant &&
    merchant.subscription_locations &&
    merchant.subscription_locations.filter((l) => l.active).length < 1
  )
    return <ErrorPage errorMessage="No active subscription locations" errorLocation="CustomerStartClaim" />;

  const handleStartClaim = () => {
    if(locationId){
      navigate(`/claim/${locationId}`)
    }
  };

  return (
    <Flex
      id="CustomerStartClaimContainer"
      width={[
        "90%", // 0-30em      // 480px
        "90%", // 30em-48em   // 768px
        "75%", // 48em-62em   // 992px
        "75%", // 62em-80em   // 1280px
        "75%", // 80em-96em+  // 1536px
      ]}
      maxW={"900"}
    >
      {isAtCustomerStartClaim && (
        <Center flexDirection={"column"} height={"100%"} width={"100%"}>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Button
              onClick={() => navigate("../")}
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              width={"fit-content"}
              marginBottom={"5"}
            >
              Back
            </Button>
          </Flex>
          <Heading marginBottom={"5"} textAlign={"center"}>{CustomerStartClaimHeading}</Heading>
          <Divider orientation="horizontal" mb={"5"} />
          <div style={{ width: "100%" }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <Flex flexDirection={"column"}>
                <ChakraCard
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  my={"5"}
                  mx={"auto"}
                  size={"sm"}
                  minW={"300px"}
                  width={"100%"}
                  maxW={"640px"}
                  bgGradient="linear(to-r, white, orange.50)"
                  borderBottom={"8px aqua"}
                >
                  <Stack width={"100%"}>
                    <CardHeader display={"flex"} flexDirection={"row"}>
                      <Avatar
                        name={merchantResponse?.business_name}
                        color="white"
                        src={merchantResponse?.logo_url || ""}
                        size="lg"
                      />
                      <Heading size="md" my="auto" ml="5">
                        {merchantResponse?.business_name}
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <AnimatePresence>
                        <motion.div>
                          <Center flexDirection={"column"}>
                            <motion.div
                              id="customerClaim"
                              initial={{ opacity: 0, x: 0 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0 }}
                              transition={{
                                duration: 0.3,
                                delay: 0.3,
                              }}
                            >
                              <Text textAlign={"center"}>
                                {ConfirmLocationDescription}
                              </Text>
                              {merchant &&
                                merchant.subscription_locations &&
                                merchant.subscription_locations.filter(
                                  (l) => l.active
                                ).length > 0 && (
                                  <>
                                    {merchant.subscription_locations.filter(
                                      (l) => l.active
                                    ).length === 1 && (
                                      <Text
                                        fontSize={"xl"}
                                        textAlign={"center"}
                                      >
                                        <b>
                                          {
                                            merchant.subscription_locations.filter(
                                              (l) => l.active
                                            )[0].name
                                          }
                                        </b>
                                      </Text>
                                    )}
                                    {merchant.subscription_locations.filter(
                                      (l) => l.active
                                    ).length > 1 && (
                                      <Select
                                        size="lg"
                                        variant="flushed"
                                        onChange={(e) =>
                                          setLocationId(e.target.value)
                                        }
                                      >
                                        {merchant.subscription_locations
                                          .filter((l) => l.active)
                                          .map((l) => (
                                            <option key={l.id} value={l.id}>
                                              {l.name}
                                            </option>
                                          ))}
                                      </Select>
                                    )}
                                  </>
                                )}
                            </motion.div>
                          </Center>
                        </motion.div>
                      </AnimatePresence>
                    </CardBody>
                    <CardFooter>
                      <Flex justifyContent={"space-evenly"} width={"100%"}>
                        <Button
                          variant={"solid"}
                          color="white"
                          width={"60%"}
                          bgGradient="linear(to-r, green.500, yellow.500)"
                          _hover={{
                            color: "white",
                            bgGradient: "linear(to-r, yellow.500, red.500,)",
                          }}
                          onClick={handleStartClaim}
                        >
                          Proceed
                        </Button>
                      </Flex>
                    </CardFooter>
                  </Stack>
                </ChakraCard>
              </Flex>
            </motion.div>
          </div>
        </Center>
      )}
    </Flex>
  );
};
