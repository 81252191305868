import { useContext, useEffect, useState } from "react";
import { Form, redirect, useNavigate, useNavigation } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tooltip,
  Divider,
  AbsoluteCenter,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { CgSquare } from "react-icons/cg";
import { SquareAPIUrl as url } from "../lib/constants";

export async function action({ request, params }: any) {
  try {
    const formData = await request.formData();
    const businessName = formData.get("businessName");
    const mainLocationId = formData.get("mainLocationId");
    const redeemIndex = formData.get("redeemIndex");

    if (businessName && mainLocationId && redeemIndex) {
      const result = await callFunction("merchant", "post", {
        businessName,
        mainLocationId,
        redeemIndex,
      });
      if (result) {
        const merchant_id = result.id;
        if (merchant_id) {
          return redirect(`/merchant/${merchant_id}?refresh=true`);
        } else {
          throw new Error("Merchant account could not be created");
        }
      } else {
        throw new Error("Could not get result");
      }
    } else {
      throw new Error("Parameters missing");
    }
  } catch (e) {
    console.log(e);
    throw new Error(e as any);
  }

  // if (type === "manual") {
  //   const user_id = formData.get("user_id");

  //   const result = await callFunction("stripe/portal", "post", {
  //     user_id,
  //   });
  //   if (result) {
  //     return redirect(result.url);
  //   } else {
  //     return redirect("/");
  //   }
  // }
}

// type ErrorType = {
//   businessName: string | undefined;
//   mainLocationId: string | undefined;
//   redeemIndex: string | undefined;
// };

export const CreateMerchant = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { merchant, user } = useContext(DataContext);
  const [isSettingUpSquare, setIsSettingUpSquare] = useState<boolean>(false);

  useEffect(() => {
    if (merchant) navigate("/");
    if (user) {
      if (
        !user.subscription_id ||
        !!user.merchant_id ||
        user.merchant_id !== null
      ) {
        navigate("/");
      }
    }
  }, [merchant, user, navigate]);

  return (
    <Flex
      id="CreateMerchantContainer"
      width={[
        "90%", // 0-30em      // 480px
        "90%", // 30em-48em   // 768px
        "75%", // 48em-62em   // 992px
        "75%", // 62em-80em   // 1280px
        "75%", // 80em-96em+  // 1536px
      ]}
      maxW={"900"}
    >
      {user && !merchant && (
        <Center height={"100%"} width={"100%"}>
          <Flex align="center" justify="center" flexDirection={"column"}>
            <Heading size={"lg"}>Create Business</Heading>
            <Box bg="white" p={6} rounded="md" w={"sm"}>
              <Form method="post">
                <VStack spacing={4} align="flex-start">
                  <FormControl>
                    <FormLabel htmlFor="name">
                      <Flex>
                        <>Business Name</>
                        <Tooltip
                          placement="top"
                          label={`Please provide your business name.`}
                        >
                          <QuestionOutlineIcon
                            ml="3"
                            boxSize="5"
                            color={"gray.500"}
                          />
                        </Tooltip>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="businessName"
                      type="input"
                      variant="filled"
                      name="businessName"
                      required
                      minLength={3}
                      maxLength={30}
                      isDisabled={isSettingUpSquare || navigation.state === "submitting"}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="location">
                      <Flex>
                        <>Location Nickname</>
                        <Tooltip
                          placement="top"
                          label={`Give a nickname that identifies the primary business location.`}
                        >
                          <QuestionOutlineIcon
                            ml="3"
                            boxSize="5"
                            color={"gray.500"}
                          />
                        </Tooltip>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="mainLocationId"
                      type="input"
                      variant="filled"
                      name="mainLocationId"
                      required
                      minLength={3}
                      maxLength={30}
                      isDisabled={isSettingUpSquare || navigation.state === "submitting"}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="redeemQty">
                      <Flex>
                        <>Number of punches on card?</>
                        <Tooltip
                          placement="top"
                          label={`The last punch is the free one (e.g. buy 9, get 1 free = 10 punches).`}
                        >
                          <QuestionOutlineIcon
                            ml="3"
                            boxSize="5"
                            color={"gray.500"}
                          />
                        </Tooltip>
                      </Flex>
                    </FormLabel>
                    <NumberInput
                      defaultValue={10}
                      max={20}
                      min={2}
                      id="redeemIndex"
                      name="redeemIndex"
                      variant="filled"
                      isDisabled={isSettingUpSquare || navigation.state === "submitting"}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="orange"
                    width="full"
                    isLoading={navigation.state === "submitting"}
                    loadingText={"Creating..."}
                    isDisabled={isSettingUpSquare}
                  >
                    Create
                  </Button>
                </VStack>
              </Form>
            </Box>
            <Box position="relative" padding="10" width={"100%"}>
              <Divider />
              <AbsoluteCenter color="gray.500" bg="white" px="4">
                Or use our integration
              </AbsoluteCenter>
            </Box>

            <Box bg="white" p={6} rounded="md" w={"sm"}>
              <Button
                isLoading={isSettingUpSquare}
                isDisabled={navigation.state === "submitting"}
                loadingText="Connecting to Square..."
                type="button"
                colorScheme="orange"
                leftIcon={
                  <Icon
                    as={CgSquare}
                    my="auto"
                    mr="1"
                    w={8}
                    h={8}
                    color="white"
                  />
                }
                width="full"
                onClick={() => {
                  window.open(url, "_self")?.focus();
                  setIsSettingUpSquare(true);
                }}
              >
                Connect Square Account
              </Button>
            </Box>
          </Flex>
        </Center>
      )}
    </Flex>
  );
};
