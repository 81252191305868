import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  Claim,
  Merchant,
  Location,
  User,
  UserInfo,
  SquareEvent,
  Redeem,
} from "./constants";
import { WebPubSubClient } from "@azure/web-pubsub-client";

export interface DataContent {
  isReloading: boolean;
  setIsReloading: (r: boolean) => void;
  merchant: Merchant | undefined;
  setMerchant: (m: Merchant) => void;
  location: Location | undefined;
  setLocation: (l: Location) => void;
  user: User | undefined;
  setUser: (u: User) => void;
  userInfo: UserInfo | undefined;
  getUserInfo: () => Promise<UserInfo | undefined> | undefined;
  setUserInfo: (u: UserInfo) => void;
  socketClient: WebPubSubClient | undefined;
  setSocketClient: Dispatch<SetStateAction<WebPubSubClient | undefined>>;
  connect: (l: string) => void;
  events: SquareEvent[] | undefined;
  setEvents: (s: SquareEvent[]) => void;
  squareAuthCode: string | null;
  setSquareAuthCode: (c: string | null) => void;
  newRedeem: Redeem | undefined;
  setNewRedeem: (r: Redeem | undefined) => void;
  redeemed: Redeem | undefined;
  setRedeemed: (r: Redeem | undefined) => void;
  redeemRejected: Redeem | undefined;
  setRedeemRejected: (r: Redeem | undefined) => void;
  claimAccepted: Claim | undefined;
  setClaimAccepted: (c: Claim | undefined) => void;
  claimRequested: Claim | undefined;
  setClaimRequested: (c: Claim | undefined) => void;
  claimRejected: Claim | undefined;
  setClaimRejected: (c: Claim | undefined) => void;
}

export const DataContext = createContext<DataContent>({
  isReloading: false,
  setIsReloading: () => {},
  merchant: undefined,
  setMerchant: () => {},
  location: undefined,
  setLocation: () => {},
  user: undefined,
  setUser: () => {},
  userInfo: undefined,
  getUserInfo: () => undefined,
  setUserInfo: () => {},
  socketClient: undefined,
  setSocketClient: () => {},
  connect: () => {},
  events: [],
  setEvents: () => {},
  squareAuthCode: null,
  setSquareAuthCode: () => {},
  newRedeem: undefined,
  setNewRedeem: () => {},
  redeemed: undefined,
  setRedeemed: () => {},
  redeemRejected: undefined,
  setRedeemRejected: () => {},
  claimAccepted: undefined,
  setClaimAccepted: () => {},
  claimRequested: undefined,
  setClaimRequested: () => {},
  claimRejected: undefined,
  setClaimRejected: () => {},
});

export const useDataContext = () => useContext(DataContext);
