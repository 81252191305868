import { useContext, useEffect, useRef, useState } from "react";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import localforage from "localforage";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import { AIContext } from "../lib/aiContext";
import { Claim, Location, Redeem, SquareEvent } from "../lib/constants";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Highlight,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ListItem,
  SimpleGrid,
  Stack,
  Tooltip,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  RepeatIcon,
  BellIcon,
  DeleteIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import { MdQrCode } from "react-icons/md";
import { AiFillCamera } from "react-icons/ai";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import Lottie from "lottie-react";
import * as animationData from "../lib/animations/tea-cup-loading.json";
import pluralize from "pluralize";
import { Punch } from "./Punch";
import { uniqBy } from "lodash";
import QrScanner from "qr-scanner";
dayjs.extend(relativeTime);

export async function loader({ params }: any) {
  try {
    const { locationId, merchantId } = params;
    if (locationId && merchantId) {
      // don't cache locations if the settings change!
      // let locationResponse = (await localforage.getItem(
      //   locationId
      // )) as Location;
      let eventResponse =
        ((await localforage.getItem(
          `events-${locationId}`
        )) as SquareEvent[]) || [];

      //if (!locationResponse) {
      const locationResponse = await callFunction(
        "location",
        "get",
        undefined,
        {
          location_id: locationId,
          merchant_id: merchantId,
        }
      );
      //  localforage.setItem(locationId, locationResponse);
      //}
      const redeemQueueResponse = (await callFunction(
        "redeem",
        "get",
        undefined,
        {
          location_id: locationId,
          merchant_id: merchantId,
        }
      )) as Redeem[];
      const claimResponse = (await callFunction("claim", "get", undefined, {
        locationId: locationId,
      })) as Claim[];
      return {
        locationResponse,
        redeemQueueResponse,
        eventResponse,
        claimResponse,
      };
    } else return;
  } catch (err) {
    throw new Error("Could not fetch Location data");
  }
}

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const LocationPage = () => {
  const { trackEvent } = useContext(AIContext);
  const data = useLoaderData() as any;
  const { locationId, merchantId, user, userInfo } = useParams();
  const locationResponse = data.locationResponse as Location;
  const [redeemQueue, setRedeemQueue] = useState<Redeem[]>(
    data.redeemQueueResponse as Redeem[]
  );
  // let redeemQueue = data.redeemQueueResponse as Redeem[];
  const [claimQueue, setClaimQueue] = useState<Claim[]>(
    data.claimResponse as Claim[]
  );
  // let claimQueue = data.claimResponse as Claim[];
  const eventCache = data.eventResponse as SquareEvent[];
  const {
    connect,
    events,
    setEvents,
    location,
    setLocation,
    merchant,
    newRedeem,
    setNewRedeem,
    claimRequested,
    setClaimRequested,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isAcceptingRedeem, setIsAcceptingRedeem] = useState<string>("");
  const [isRejectingRedeem, setIsRejectingRedeem] = useState<string>("");
  const [isAcceptingClaim, setIsAcceptingClaim] = useState<string>("");
  const [isRejectingClaim, setIsRejectingClaim] = useState<string>("");
  const [isDownloadingQR, setIsDownloadingQR] = useState<boolean>(false);
  const [claim, setClaim] = useState<Claim>();
  const drawDisclosure = useDisclosure();
  const btnRef = useRef(null);
  const toast = useToast();
  const combinedQueue: (Claim | Redeem)[] = [
    ...redeemQueue,
    ...claimQueue,
  ].sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateA - dateB;
  });
  const qrLocationDisclosure = useDisclosure({ defaultIsOpen: false });
  const [hasCamera, setHasCamera] = useState<boolean>(false);

  const qrLocButtonProps = qrLocationDisclosure.getButtonProps();

  async function fetchClaim() {
    const claimResponse = (await callFunction("claim", "get", undefined, {
      locationId: locationId,
    })) as Claim[];
    setClaimQueue(claimResponse);
  }

  async function fetchRedeem() {
    const redeemQueueResponse = (await callFunction(
      "redeem",
      "get",
      undefined,
      {
        location_id: locationId,
        merchant_id: merchantId,
      }
    )) as Redeem[];
    setRedeemQueue(redeemQueueResponse);
  }

  useEffect(() => {
    if (locationResponse) {
      // To-do: not sure why this was added: && locationResponse.id !== location?.id
      setLocation(locationResponse);
      connect(locationResponse.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationResponse]);

  useEffect(() => {
    // Set the events cache
    if (eventCache) setEvents(eventCache);
    // ...and prime the camera on first load
    QrScanner.hasCamera()
      .then(() => setHasCamera(true))
      .catch((e) => {
        console.log(e);
        setHasCamera(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localforage.setItem(`events-${locationResponse.id}`, events);
  }, [events, locationResponse.id]);

  useEffect(() => {
    if (newRedeem) {
      setNewRedeem(undefined);
      fetchRedeem();
      toast({
        title: "New Request to Redeem",
        position: "top",
        status: "info",
        duration: 1500,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRedeem]);

  useEffect(() => {
    if (claimRequested) {
      setClaimRequested(undefined);
      fetchClaim();
      toast({
        title: "New Request to Punch",
        position: "top",
        status: "info",
        duration: 1500,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimRequested]);

  const handleCreateClaim = async (data: SquareEvent) => {
    try {
      setIsCreating(true);
      let sum: number = 0;
      data.lineItems.forEach((l) => (sum += Number(l.quantity)));
      const claim = await callFunction("claim", "post", {
        ...data,
        quantity: sum,
      });
      if (claim) {
        navigate(`./claim/${claim.id}`);
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: msg.toString(),
        },
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleCreateManualClaim = async (n: number) => {
    try {
      const id = uuid();
      if (merchant && location) {
        setIsCreating(true);
        const claim = await callFunction("claim", "post", {
          id,
          quantity: n,
          merchantId: merchant.id,
          locationId: location.id,
        });
        if (claim) {
          setClaim(claim);
        }
      } else {
        throw new Error("No merchant or location data");
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: msg.toString(),
        },
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleAcceptRedeem = async (id: string) => {
    try {
      setIsAcceptingRedeem(id);
      const r = redeemQueue.find((i) => i.id === id);
      if (r) {
        const res = await callFunction("redeem", "put", {
          redeem_id: r.id,
          location_id: r.location_id,
          merchant_id: r.merchant_id,
          card_id: r.card_id,
        });
        if (res && res.redeemed_at) {
          fetchRedeem();
          toast({
            title: "Success",
            description: "You have accepted the free item.",
            position: "top",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          drawDisclosure.onClose();
        } else {
          throw new Error("Could not redeem.");
        }
      } else {
        throw new Error("Could not find the redemption.");
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: msg.toString(),
        },
      });
    } finally {
      setIsAcceptingRedeem("");
    }
  };

  const handleRejectRedeem = async (id: string) => {
    try {
      setIsRejectingRedeem(id);
      const r = redeemQueue.find((i) => i.id === id);
      if (r) {
        const res = await callFunction("redeem", "delete", {
          redeem_id: r.id,
        });

        if (res) {
          fetchRedeem();
          toast({
            title: "Deleted",
            description: "Request has been removed.",
            position: "top",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
          drawDisclosure.onClose();
        } else {
          throw new Error("Could not delete redeem.");
        }
      } else {
        throw new Error("Could not find the redemption.");
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: msg.toString(),
        },
      });
    } finally {
      setIsRejectingRedeem("");
    }
  };

  const handleAcceptClaim = async (id: string) => {
    try {
      setIsAcceptingClaim(id);
      const r = claimQueue.find((i) => i.id === id);
      if (r) {
        const res = await callFunction("card", "put", {
          cardId: r.card_id,
          claimId: r.id,
        });
        if (res) {
          fetchClaim();
          toast({
            title: "Success",
            description: "You have punched the card.",
            position: "top",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          drawDisclosure.onClose();
        } else {
          throw new Error("Could not punch card.");
        }
      } else {
        throw new Error("Could not find the customer request.");
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: msg.toString(),
        },
      });
    } finally {
      setIsAcceptingClaim("");
    }
  };

  const handleRejectClaim = async (id: string) => {
    try {
      setIsRejectingClaim(id);
      const r = claimQueue.find((i) => i.id === id);
      if (r) {
        const res = await callFunction("claim", "delete", {
          cardId: r.card_id,
          claimId: r.id,
        });
        if (res) {
          fetchClaim();
          toast({
            title: "Deleted",
            description: "Request has been removed.",
            position: "top",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
          drawDisclosure.onClose();
        } else {
          throw new Error("Could not delete claim.");
        }
      } else {
        throw new Error("Could not find the customer request.");
      }
    } catch (e) {
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRejectingClaim("");
    }
  };

  const handleDownloadQRCode = () => {
    try {
      setIsDownloadingQR(true);
      const canvas = document.getElementById(
        "location-qr-code"
      ) as HTMLCanvasElement | null;
      if (canvas) {
        const pngUrl = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode.png";
        downloadLink.click();
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: msg.toString(),
        },
      });
    } finally {
      setIsDownloadingQR(false);
    }
  };

  const variants = {
    pre: { opacity: 0 },
    visible: { opacity: 1 },
    hide: { opacity: 0, display: "none" },
  };

  const keypad = {
    hiddenRight: { opacity: 0, x: 50 },
    hiddenLeft: { opacity: 0, x: -50 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        delay: 0.3,
      },
    },
    exitRight: {
      opacity: 0,
      x: 50,
      transition: {
        duration: 0.3,
      },
    },
    exitLeft: {
      opacity: 0,
      x: -50,
      transition: {
        duration: 0.3,
      },
    },
  };

  const queueHeader =
    combinedQueue.length > 1
      ? "Customer requests are waiting a response!"
      : "A customer request has come in.";

  const collapsePoint = combinedQueue && combinedQueue.length > 0 ? "md" : "sm";

  return (
    <>
      <Flex id="LocationContainer" flexDirection={"column"}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
          }}
        >
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            // maxW={"600"}
            // minW={"400"}
            margin="auto"
            width="100%"
            height="60px"
          >
            <Flex>
              <Button
                onClick={() => navigate("../")}
                leftIcon={<ArrowBackIcon />}
                variant="ghost"
                width={"fit-content"}
                marginBottom={"5"}
                my="auto"
                hideBelow={collapsePoint}
              >
                Back
              </Button>
              <Button
                onClick={() => navigate("../")}
                variant="ghost"
                width={"fit-content"}
                marginBottom={"5"}
                my="auto"
                hideFrom={collapsePoint}
              >
                <ArrowBackIcon />
              </Button>
            </Flex>
            <Flex flexDirection={"row"}>
              {hasCamera && (
                <Tooltip
                  placement={"top-end"}
                  label={`Open Camera to scan a Punchy QR code.`}
                >
                  <Flex>
                    <Button
                      my="auto"
                      leftIcon={<AiFillCamera />}
                      hideBelow={collapsePoint}
                      onClick={() => navigate("./scan")}
                    >
                      Scan
                    </Button>
                    <Button
                      my="auto"
                      hideFrom={collapsePoint}
                      onClick={() => navigate("./scan")}
                    >
                      <AiFillCamera />
                    </Button>
                  </Flex>
                </Tooltip>
              )}

              {location && !location.manual && (
                <Tooltip
                  placement={"top-end"}
                  label={`Manually punch a card and generate a QR code.`}
                >
                  <Flex>
                    <Button
                      leftIcon={<Punch size={16} />}
                      variant={"solid"}
                      colorScheme="orange"
                      width={"fit-content"}
                      marginBottom={"5"}
                      marginLeft={"2"}
                      onClick={() => navigate("./create")}
                      my="auto"
                      hideBelow={collapsePoint}
                    >
                      Punch
                    </Button>
                    <Button
                      variant={"solid"}
                      colorScheme="orange"
                      width={"fit-content"}
                      marginBottom={"5"}
                      marginLeft={"2"}
                      onClick={() => navigate("./create")}
                      my="auto"
                      hideFrom={collapsePoint}
                    >
                      <Punch size={16} />
                    </Button>
                  </Flex>
                </Tooltip>
              )}
              {combinedQueue && combinedQueue.length > 0 && (
                <Tooltip label={queueHeader} placement={"top-end"}>
                  <Flex
                    backgroundColor={"orange.100"}
                    borderRadius={"5px"}
                    flexDirection={"row"}
                    onClick={drawDisclosure.onOpen}
                    padding={"1"}
                    style={{ cursor: "pointer" }}
                    ml={"5"}
                    my={{ base: "0.5em", sm: "auto" }}
                  >
                    <BellIcon
                      boxSize={{ base: "5", sm: "10" }}
                      my="auto"
                      color={"orange.500"}
                    />
                    <AvatarGroup size={{ base: "sm", sm: "md" }} max={2}>
                      {combinedQueue.map((q, i) => (
                        <Avatar
                          key={`notifyIndex-${i}-${q.user!.id}`}
                          name={q.user!.user_name}
                        />
                      ))}
                    </AvatarGroup>
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
          }}
        >
          <Center marginBottom={"5"} mt={"5"} flexDirection={"column"}>
            <Heading textAlign={"center"}>{location?.name}</Heading>
            {location && location.id && (
              <Tooltip
                placement={"top-end"}
                label={`Show a location QR Code you can display in your shop or to customers.`}
              >
                <Flex>
                  <Button
                    my="auto"
                    {...qrLocButtonProps}
                    leftIcon={<MdQrCode />}
                    marginLeft={"2"}
                    hideBelow={collapsePoint}
                    variant={"outline"}
                  >
                    Location QR
                  </Button>
                  <Button
                    {...qrLocButtonProps}
                    my="auto"
                    marginLeft={"2"}
                    hideFrom={collapsePoint}
                    variant={"outline"}
                  >
                    <MdQrCode />
                  </Button>
                </Flex>
              </Tooltip>
            )}
          </Center>
          <Divider orientation="horizontal" />
        </motion.div>
        <Flex flexDirection={"column"}>
          <LayoutGroup>
            <motion.ul layout style={{ listStyleType: "none" }}>
              {events && (
                <>
                  {uniqBy(events, "id").map((item, i) => (
                    <motion.li
                      key={`sq-event-${item.id}-${i}`}
                      layout
                      variants={variants}
                      initial="pre"
                      animate="visible"
                    >
                      <Card
                        direction={{ base: "column", sm: "row" }}
                        overflow="hidden"
                        margin={"5"}
                        size={"sm"}
                        key={item.id}
                      >
                        <Stack width={"100%"}>
                          <CardBody>
                            <Heading size="sm">
                              <Flex justifyContent={"flex-start"}>
                                <Badge ml="2" fontSize="0.6em" padding={"1"}>
                                  {dayjs(item.createdAt).fromNow()}
                                </Badge>
                              </Flex>
                            </Heading>
                            <UnorderedList
                              marginTop={"5"}
                              listStyleType={"none"}
                            >
                              {item.lineItems.map((l, i) => (
                                <ListItem key={`list-${l.uid}-i`}>
                                  {l.quantity}
                                  {" x "} {l.name}
                                </ListItem>
                              ))}
                            </UnorderedList>
                          </CardBody>
                          <CardFooter>
                            {/* <Link as={ReactLink} to={`./claim/${item.id}`}> */}
                            <Button
                              isDisabled={isCreating}
                              onClick={() => handleCreateClaim(item)}
                              rightIcon={<ArrowForwardIcon />}
                              variant="solid"
                            >
                              Show QR Code
                            </Button>
                            {/* </Link> */}
                          </CardFooter>
                        </Stack>
                      </Card>
                    </motion.li>
                  ))}
                </>
              )}
            </motion.ul>
          </LayoutGroup>
          {location && !location.manual && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              {(!events || (events && events.length === 0)) && (
                <Flex flexDirection={"column"} mt="10">
                  <Lottie
                    autoplay={true}
                    loop={true}
                    animationData={animationData}
                    style={{
                      height: "200px",
                      width: "200px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      transform: "translateX(-0px)",
                      opacity: "20%",
                    }}
                  />
                  <Flex width="135px" margin="auto">
                    <Heading
                      as="h4"
                      fontSize={"1.8rem"}
                      color={"orange.500"}
                      opacity={"20%"}
                      transform="translateY(-40px)"
                      className="loading"
                    >
                      Waiting
                    </Heading>
                  </Flex>
                </Flex>
              )}
            </motion.div>
          )}
          {location && location.manual && (
            <Flex mt={"10"} flexDirection={"column"} position={"relative"}>
              <AnimatePresence mode={"popLayout"}>
                {claim && (
                  <motion.div
                    variants={keypad}
                    initial="hiddenRight"
                    animate="show"
                    exit="exitRight"
                  >
                    <Center flexDirection={"column"} id={claim.id}>
                      <Center
                        w={{ base: "250px", sm: "320px" }}
                        h={{ base: "250px", sm: "320px" }}
                      >
                        <QRCodeSVG
                          style={{ margin: "auto" }}
                          height={"100%"}
                          width={"100%"}
                          value={`${window.location.protocol}//${window.location.host}/gateway/claim/${claim.id}`}
                        />
                      </Center>
                      {process.env.NODE_ENV !== "production" && (
                        <p>
                          {`${window.location.protocol}//${window.location.host}/gateway/claim/${claim.id}`}
                        </p>
                      )}
                      <Button
                        leftIcon={<RepeatIcon />}
                        variant="outline"
                        mt={"10"}
                        h={"16"}
                        maxW={"200px"}
                        minW={"200Px"}
                        onClick={() => {
                          setClaim(undefined);
                        }}
                      >
                        Return
                      </Button>
                    </Center>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!claim && (
                  <motion.div
                    variants={keypad}
                    initial="hiddenLeft"
                    animate="show"
                    exit="exitLeft"
                  >
                    <Heading as="h3" size="md" mb={"5"}>
                      Select quantity
                    </Heading>
                    <SimpleGrid
                      columns={3}
                      spacing={{ base: "2", sm: "2", md: "5" }}
                      id={uuid()}
                    >
                      {num.map((n) => (
                        <Box
                          as="button"
                          borderRadius="md"
                          bg={"orange.400"}
                          color="white"
                          px={4}
                          h={{ base: "20", sm: "24", md: "28" }}
                          key={n}
                          onClick={() => handleCreateManualClaim(n)}
                          disabled={isCreating}
                        >
                          {n}
                        </Box>
                      ))}
                    </SimpleGrid>
                  </motion.div>
                )}
              </AnimatePresence>
            </Flex>
          )}
        </Flex>
        <Drawer
          isOpen={drawDisclosure.isOpen}
          placement="right"
          onClose={drawDisclosure.onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{"Customer Requests"}</DrawerHeader>
            <DrawerBody>
              {combinedQueue.map((q, i) => {
                // Only claim requests have is_pending property
                if ("is_pending" in q) {
                  return (
                    <Card key={q.id} variant={"filled"} mb={"5"}>
                      <CardHeader display={"flex"} flexDirection={"row"}>
                        <Avatar
                          key={`${i}-${q.user!.id}`}
                          name={q.user!.user_name}
                        />
                        <Heading size="md" ml={"5"} my={"auto"} noOfLines={1}>
                          {`${q.user!.user_name}`}
                        </Heading>
                      </CardHeader>
                      <CardBody>
                        <Highlight
                          query={`${q.quantity} ${pluralize(
                            "time",
                            q.quantity
                          )}`}
                          styles={{ px: "1", py: "1", bg: "orange.100" }}
                        >
                          {`Wants to be punched ${q.quantity} ${pluralize(
                            "time",
                            q.quantity
                          )}`}
                        </Highlight>
                      </CardBody>
                      <CardFooter justifyContent={"space-between"}>
                        <Button
                          isLoading={isAcceptingClaim === q.id}
                          leftIcon={<Punch size={16} />}
                          variant={"solid"}
                          colorScheme="orange"
                          onClick={() => handleAcceptClaim(q.id)}
                          disabled={isAcceptingClaim !== ""}
                        >
                          Punch
                        </Button>
                        <Button
                          isLoading={isRejectingClaim === q.id}
                          variant={"solid"}
                          colorScheme="red"
                          onClick={() => handleRejectClaim(q.id)}
                          disabled={isRejectingClaim !== ""}
                        >
                          <DeleteIcon />
                        </Button>
                      </CardFooter>
                    </Card>
                  );
                } else {
                  return (
                    <Card key={q.id} variant={"filled"} mb={"5"}>
                      <CardHeader display={"flex"} flexDirection={"row"}>
                        <Avatar
                          key={`j-${i}-${q.user!.id}`}
                          name={q.user!.user_name}
                        />
                        <Heading size="md" ml={"5"} my={"auto"} noOfLines={1}>
                          {`${q.user!.user_name}`}
                        </Heading>
                      </CardHeader>
                      <CardBody>
                        <Highlight
                          query={`FREE`}
                          styles={{ px: "1", py: "1", bg: "orange.100" }}
                        >
                          {`Wants to be redeem a FREE item`}
                        </Highlight>
                      </CardBody>
                      <CardFooter justifyContent={"space-between"}>
                        <Button
                          isLoading={isAcceptingRedeem === q.id}
                          leftIcon={<CheckIcon />}
                          variant={"solid"}
                          colorScheme="orange"
                          onClick={() => handleAcceptRedeem(q.id)}
                          disabled={isAcceptingRedeem !== ""}
                        >
                          Accept
                        </Button>
                        <Button
                          isLoading={isRejectingRedeem === q.id}
                          variant={"solid"}
                          colorScheme="red"
                          onClick={() => handleRejectRedeem(q.id)}
                          disabled={isRejectingRedeem !== ""}
                        >
                          <DeleteIcon />
                        </Button>
                      </CardFooter>
                    </Card>
                  );
                }
              })}
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={drawDisclosure.onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        {location && location.id && (
          <Modal
            closeOnOverlayClick={true}
            isOpen={qrLocationDisclosure.isOpen}
            onClose={qrLocationDisclosure.onClose}
            isCentered
            motionPreset="scale"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Location Claim QR Code</ModalHeader>
              {/* <ModalCloseButton /> */}
              <ModalBody pb={6} pt={6}>
                <>
                  <QRCodeCanvas
                    id="location-qr-code"
                    size={250}
                    style={{ margin: "auto" }}
                    value={`${window.location.protocol}//${window.location.host}/gateway/location/${location.id}`}
                  />
                  {process.env.NODE_ENV !== "production" && (
                    <p>
                      {`${window.location.protocol}//${window.location.host}/gateway/location/${location.id}`}
                    </p>
                  )}
                </>
              </ModalBody>
              <ModalFooter flexDir={"row"} justifyContent={"space-between"}>
                <Button
                  onClick={handleDownloadQRCode}
                  colorScheme="orange"
                  isLoading={isDownloadingQR}
                >
                  Download
                </Button>
                <Button
                  onClick={qrLocationDisclosure.onClose}
                  isDisabled={isDownloadingQR}
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Flex>
      <Outlet />
    </>
  );
};
