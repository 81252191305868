import { useRef, useCallback, useState, useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  Flex,
  useToast,
  //   Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { isString } from "lodash";
import QrScanner from "qr-scanner";
import "./Scan.css";

export const Scan = () => {
  const video = useRef<HTMLVideoElement | null>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [scanner, setScanner] = useState<QrScanner | null>(null);
  const scannerRef = useRef<QrScanner | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(null);
  const toast = useToast();
  const navigate = useNavigate();

  const handleCloseQR = (navAway: boolean) => {
    if (scanner) {
      scanner.stop();
      scanner.destroy();
      setScanner(null);
    }
    toast.closeAll();
    if (navAway) {
      navigate("../");
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onClose: () => {
      handleCloseQR(true);
    },
    onOpen: () => {},
  });

  useEffect(() => {
    function errorClose() {
      toast({
        title: "QR code not recognized!",
        position: "top",
        status: "error",
        duration: 1500,
        isClosable: true,
      });
      onClose();
    }
    if (video.current !== null && !hasLoaded) {
      setHasLoaded(true);
      toast({
        title: `Press anywhere to close.`,
        position: "top",
        variant: "subtle",
        isClosable: false,
        duration: null,
        icon: false,
      });
      const scan = new QrScanner(
        video.current!,
        (result) => {
          if (result.data && isString(result.data)) {
            if (
              result.data.startsWith("https://punchy.kanvent.com/gateway") ||
              (process.env.NODE_ENV !== "production" &&
                result.data.startsWith("http://localhost"))
            ) {
              // Parse the URL
              const urlObject = new URL(result.data);
              // Extract the pathname and split it into parts
              const pathnameParts = urlObject.pathname.split("/");
              // 'type' will be the third part of the pathname
              const type = pathnameParts[2];
              // 'id' will be the fourth part of the pathname
              const id = pathnameParts[3];
              if (type && id) {
                setType(type);
                setId(id);
              } else {
                errorClose();
              }
            } else {
              errorClose();
            }
          } else {
            errorClose();
          }
        },
        {
          onDecodeError: (error) => {
            console.log(error);
          },
          highlightScanRegion: true,
          highlightCodeOutline: true,
          preferredCamera: "environment",
        }
      );
      scan.start();
      setScanner(scan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video, video.current, onOpen]);

  useEffect(() => {
    console.log(type, id);
    if (type && id) {
      switch (type) {
        case "claim":
          toast({
            title: "Opening QR code...",
            position: "top",
            status: "success",
            duration: 1500,
          });
          navigate(`/customer/claim/${id}`);
          // setType(null);
          // setId(null);
          break;
        case "location":
          toast({
            title: "Opening QR code...",
            position: "top",
            status: "success",
            duration: 1500,
          });
          navigate(`/claim/${id}`);
          // setType(null);
          // setId(null);
          break;
        case "redeem":
          toast({
            title: "Opening QR code...",
            position: "top",
            status: "success",
            duration: 1500,
          });
          navigate(`/merchant/redeem/${id}`);
          // setType(null);
          // setId(null);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  //We create a scannerRef using the useRef hook to create a mutable reference to the scanner variable.
  useEffect(() => {
    scannerRef.current = scanner;
  }, [scanner]);

  // In the cleanup function, we access the old value of scanner using scannerRef.current
  // This allows you to reference the previous value of scanner even after it has changed
  const cleanup = useCallback(() => {
    if (scannerRef.current) {
      scannerRef.current.stop();
      scannerRef.current.destroy();
    }
    toast.closeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanner, toast]);

  //
  useEffect(() => {
    // Code to run just before the component unmounts
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flex id="video-container" onClick={onClose}>
        <video ref={video} id="qr-video" />
      </Flex>
      <Modal
        isCentered
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={onClose}
        size={"full"}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent
          style={{ background: "unset", boxShadow: "unset" }}
        ></ModalContent>
      </Modal>
    </>
  );
};
