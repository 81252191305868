import {
  useEffect,
  useCallback,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import localforage from "localforage";
import {
  Link as ReactLink,
  Outlet,
  ScrollRestoration,
  useMatch,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import {
  Claim,
  GatewayRedirect,
  Location,
  Merchant,
  Redeem,
  SquareEvent,
  User,
  UserInfo,
} from "../lib/constants";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  ChakraProvider,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SkeletonCircle,
  Spinner,
  Stack,
  Text,
  UnorderedList,
  VStack,
  Wrap,
  WrapItem,
  extendTheme,
  useDisclosure,
  // useColorModeValue,
  // HStack,
  // List,
  // ListIcon,
  // PopoverFooter,
  // Portal,
} from "@chakra-ui/react";
import {
  AnimatePresence,
  motion,
  useAnimate,
  // useInView,
  // useScroll,
  // useSpring,
  // useTransform,
  // MotionValue,
  // useAnimate,
  // usePresence,
} from "framer-motion";
import { JSONTypes, WebPubSubClient } from "@azure/web-pubsub-client";
import { AIContext } from "../lib/aiContext";
import {
  getCookieManager,
  initApplicationInsights,
  startTrackEvent,
  stopTrackEvent,
  trackEvent,
  trackMetric,
  trackPageView,
  setUserId,
} from "../lib/aiInit";
import "./Root.css";
import {
  ArrowForwardIcon,
  ChevronDownIcon,
  EmailIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
// import { SiSquare } from "react-icons/si";
import { CgSquare } from "react-icons/cg";
// import Lottie from "lottie-react";
// import * as animationData from "../lib/animations/tea-cup-loading.json";
import Fonts from "./Font";
// import '@fontsource/fauna-one/400.css'
import "@fontsource/open-sans";
import "@fontsource/raleway";
// import { PhoneAnimation } from "./PhoneAnimation";
// import { CupAnimation } from "./CupAnimation";
import { FeatureBuy } from "./FeatureBuy";
import { FeatureScan } from "./FeatureScan";
import { FeatureStamp } from "./FeatureStamp";
import { FeatureRedeem } from "./FeatureRedeem";
import { Logo } from "./Logo";
// import { RiCupFill } from "react-icons/ri";
// import { HiCheckCircle } from "react-icons/hi";
import { FaLinkedin } from "react-icons/fa";
// import { IoSettingsOutline } from "react-icons/io5";
import { BsShop } from "react-icons/bs";
import { TbCards } from "react-icons/tb";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};
const fonts = {
  heading: `'Moranga Bold', sans-serif`,
  body: `'Raleway', sans-serif`,
};

const theme = extendTheme({ fonts, colors });

const HeroHeading = "Never forget your loyalty card!";
const HeroDescription =
  "Punchy is a fast and seamless way to punch, store and redeem rewards when you visit your local coffee shop!";
const HowHeading = "How does it work?";
const HowDescription = "Using Punchy is easy as...";
const CustomersHeading = "Customer loyalty cards";
const CustomersDescription =
  "Go to participating shops and keep your loyalty cards with you at all times.";
const BusinessHeading = "For Business";
const BusinessDescription =
  "Subscribe and get your customers punching in minutes. Square integration is also available to sync your shop locations and catalogue.";
const SquareDescription = "Square";
const PasswordlessSubHeading =
  "Delight customers with a simple & secure sign-in experience";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PasswordlessHeading =
  "Punchy has the latest authentication technology that does not use passwords!";
const C2AHeading = "Get started today!";
const C2ADescription =
  "Punchy is a great partner to help you reward customers for their loyalty.";
const ContactHeading = "Questions or feedback?";
const ContactCallToAction =
  "We are currently in an closed beta, looking for early adopters and feedback.";

export default function App() {
  initApplicationInsights();
  const [merchant, setMerchant] = useState<Merchant>();
  const [location, setLocation] = useState<Location>();
  const [user, setUser] = useState<User>();
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [socketClient, setSocketClient] = useState<WebPubSubClient>();
  // const [isSettingUpSquare, setIsSettingUpSquare] = useState<boolean>(false);
  const [webPubSubClient, setClient] = useState<WebPubSubClient>();
  const [, setIsConnecting] = useState<boolean>(false);
  const [, setConnected] = useState<boolean>(false);
  const navigate = useNavigate();
  const [events, setEvents] = useState<SquareEvent[]>([]);
  const [squareAuthCode, setSquareAuthCode] = useState<string | null>(null);
  const [newRedeem, setNewRedeem] = useState<Redeem | undefined>();
  const [claimAccepted, setClaimAccepted] = useState<Claim | undefined>();
  const [claimRequested, setClaimRequested] = useState<Claim | undefined>();
  const [claimRejected, setClaimRejected] = useState<Claim | undefined>();
  const [redeemRejected, setRedeemRejected] = useState<Redeem | undefined>();
  const [redeemed, setRedeemed] = useState<Redeem | undefined>();
  const [idempotencyArray, setIdempotencyArray] = useState<String[]>([]);
  const isHome = useMatch("/");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_triggerAnimation, setTriggerAnimation] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [prevTriggerAnimation, setPrevTriggerAnimation] = useState<number>(0);
  // const bgValue = useColorModeValue("white", "gray.700");
  const [dynamicCardRef, animate] = useAnimate<HTMLDivElement>();
  // const dynamicCardRef = useRef<HTMLDivElement | null>(null);
  const dynamicCardFooterRef = useRef<HTMLDivElement | null>(null);
  const [cardDynamicHeight, setCardDynamicHeight] = useState<number>(0);
  const [cardHasHeight, setCardHasHeight] = useState<boolean>(false);
  const [cardFooterDynamicHeight, setCardFooterDynamicHeight] =
    useState<number>(0);
  const [cardDisplayToggle, setCardDisplayToggle] = useState<boolean>(false);
  const [hasUserState, setHasUserState] = useState<User | undefined>();

  useLayoutEffect(() => {
    // Function to update the height in the state.
    const updateHeight = () => {
      if (dynamicCardRef.current && dynamicCardFooterRef.current) {
        const cardHeight = dynamicCardRef.current.clientHeight;
        setCardDynamicHeight(cardHeight);
        const cardFooterHeight = dynamicCardFooterRef.current.clientHeight;
        setCardFooterDynamicHeight(cardFooterHeight);

        if (cardDynamicHeight > 0 && !cardHasHeight) {
          animate(dynamicCardRef.current, {
            y: -1 * cardDynamicHeight + cardFooterDynamicHeight,
          });
          setCardHasHeight(true);
        }
        setCardDisplayToggle(false);
      }
    };
    // Initial height calculation when the component mounts.
    updateHeight();

    // Listen for changes to the div's content or size and update the height accordingly.
    window.addEventListener("resize", updateHeight);

    // Clean up the event listener when the component unmounts.
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dynamicCardRef.current,
    dynamicCardFooterRef.current,
    user,
    hasUserState,
  ]);

  // const pricingFeatures = [
  //   "Unlimited customers",
  //   "Custom Logo",
  //   "Square Integration",
  //   "Want more, let us know!",
  // ];

  useEffect(() => {
    // Only redirect if we are at the root, not within an <Outlet />
    if (isHome) {
      localforage
        .getItem("redirect")
        .then((r) => {
          if (r !== null) {
            const redirect = { ...(r as Object) } as GatewayRedirect;
            localforage.removeItem("redirect");
            switch (redirect.type) {
              case "claim":
                window.location.href = `${window.location.protocol}//${window.location.host}/customer/claim/${redirect.id}`;
                break;
              case "redeem":
                window.location.href = `${window.location.protocol}//${window.location.host}/merchant/redeem/${redirect.id}`;
                break;
              case "location":
                window.location.href = `${window.location.protocol}//${window.location.host}/claim/${redirect.id}`;
                break;
              default:
                window.location.href = `${window.location.protocol}//${window.location.host}`;
                break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          trackEvent({
            name: "ErrorPage",
            properties: {
              user: user || "no user",
              userInfo: userInfo || "no userInfo",
              location: "Root",
              error: e,
            },
          });
        });
      // If there is userState and we are at the homescreen/menu
      // can we emulate they are logged in?
      // e.g. they click cards and it will redirect them to log in.
      localforage.getItem("userState").then((u) => {
        if (u && u !== null) {
          setHasUserState(u as User);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHome]);

  const navigation = useNavigation();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  useEffect(() => {
    if (navigation.state === "loading" || (!user && userInfo)) {
      onOpen();
    } else {
      onClose();
    }
  }, [navigation, onClose, onOpen, user, userInfo]);

  const getUserInfo = async () => {
    try {
      const response = await fetch("/.auth/me");
      if (response) {
        const payload = await response.json();
        if (payload && payload.clientPrincipal) {
          const { clientPrincipal } = payload;
          if (clientPrincipal !== null) {
            setUserInfo(clientPrincipal);
            return clientPrincipal;
          }
        } else {
          // Not logged in
          // throw new Error("Client Principal is missing");
        }
      } else {
        throw new Error("No auth response");
      }
    } catch (e) {
      console.error(e);
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Root",
          error: e,
        },
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!userInfo) setUserInfo(await getUserInfo());
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchUser() {
      if (userInfo) {
        setUserInfo(userInfo);
        setIsLoadingUser(true);
        const user = await callFunction("user", "post", {
          user_id: userInfo.userId,
          identity_provider: userInfo.identityProvider,
          user_details: userInfo.userDetails,
        });
        if (user) {
          setUser(user);
          // Set userId for Application Insights
          setUserId(user.id);
          // store userState in localForage
          localforage.setItem("userState", user);
        }
        setIsLoadingUser(false);
      }
    }
    fetchUser();
  }, [userInfo]);

  const appendMessage = (data: ArrayBuffer | JSONTypes) => {
    // Only show 10 items in the Square Events log
    if (events.length > 9) {
      setEvents((prev) => [
        data as SquareEvent,
        ...prev.slice(0, prev.length - 1),
      ]);
    } else {
      setEvents((prev) => [data as SquareEvent, ...prev]);
    }
  };

  const removeMessage = (data: ArrayBuffer | JSONTypes) => {
    setEvents((prev) => [
      ...prev.filter((i) => i.id !== (data as SquareEvent).id),
    ]);
  };

  const checkNoticeIdempotency = useCallback(
    (id: String) => {
      // Check if the ID already exists in the array
      if (idempotencyArray.includes(id)) {
        return false; // ID already exists
      } else {
        // If the array has more than 100 items, remove the oldest item
        if (idempotencyArray.length >= 100) {
          setIdempotencyArray((prevIdArray) => {
            return prevIdArray.slice(1);
          }); // Remove the first item (oldest)
        }
        setIdempotencyArray((prevIdArray) => {
          return [...prevIdArray, id];
        }); // Add the new ID to the end of the array
        return true; // ID added successfully
      }
    },
    [idempotencyArray]
  );

  const connect = async (connectId: string) => {
    setIsConnecting(true);
    try {
      // Get rid of any existing clients
      if (webPubSubClient) {
        webPubSubClient.stop();
        setClient(undefined);
      }

      const { url } = await callFunction("negotiate", "get");

      const client = new WebPubSubClient({
        getClientAccessUrl: url,
      });
      client.on("group-message", (e) => {
        const data = e.message.data;
        const msg = JSON.parse(data as string);
        if (msg.claimed) {
          removeMessage(msg.claimed);
        } else if (msg.orderId) {
          appendMessage(msg);
        } else if (msg.redirect) {
          navigate(msg.redirect);
        } else if (msg.redeem) {
          if (checkNoticeIdempotency(msg.redeem.id)) {
            setNewRedeem(msg.redeem as Redeem);
          }
        } else if (msg.redeemed) {
          if (checkNoticeIdempotency(msg.redeemed.id)) {
            setRedeemed(msg.redeemed as Redeem);
          }
        } else if (msg.redeemRejected) {
          if (checkNoticeIdempotency(msg.redeemRejected.id)) {
            setRedeemRejected(msg.redeemRejected as Redeem);
          }
        } else if (msg.claimAccepted) {
          if (checkNoticeIdempotency(msg.claimAccepted.id)) {
            setClaimAccepted(msg.claimAccepted as Claim);
          }
        } else if (msg.claimRequested) {
          if (checkNoticeIdempotency(msg.claimRequested.id)) {
            setClaimRequested(msg.claimRequested as Claim);
          }
        } else if (msg.claimRejected) {
          if (checkNoticeIdempotency(msg.claimRejected.id)) {
            setClaimRejected(msg.claimRejected as Claim);
          }
        }
      });
      client.on("connected", (e) => {
        console.log(`Connection ${e.connectionId} is connected.`);
        setConnected(true);
      });
      client.on("disconnected", (_e) => {
        console.log(`Connection disconnected`);
        setConnected(false);
        setClient(undefined);
      });
      client.on("stopped", (_) => {
        console.log(`Client has stopped`);
      });
      await client.start();
      if (connectId) await client.joinGroup(connectId);
      setClient(client);
    } catch (e) {
      console.log(e);
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Root",
          error: e,
        },
      });
    } finally {
      setIsConnecting(false);
    }
  };

  const scrollToAnchor = (anchorName: string) => {
    // Update the hash in the URL
    window.location.hash = anchorName;

    // Find the anchor element and scroll to it
    const anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const features = [
    {
      id: 0,
      text: "Purchase",
      description:
        "Punchy can integrate into your transaction process at the time of purchase.",
      child: <FeatureBuy key="cup" size={"100%"} />,
    },
    {
      id: 1,
      text: "Scan",
      description:
        "QR codes are generated and scanned, taking you to the Punchy app.",
      child: <FeatureScan key="scan" size={"100%"} />,
    },
    {
      id: 2,
      text: "Punch",
      description: "The digital card is punched with the number of purchases.",
      child: <FeatureStamp key="stamp" size={"100%"} />,
    },
    {
      id: 3,
      text: "Reward",
      description:
        "Customers can claim free items from the app which are redeemed at the next transaction.",
      child: <FeatureRedeem key="redeem" size={"100%"} />,
    },
  ];

  // const phoneVariants = {
  //   showDown: {},
  //   exitDown: {},
  //   showUp: {
  //     opacity: 1,
  //     transition: {
  //       delayChildren: 0.5,
  //     },
  //   },

  //   exitUp: {},
  // };

  return (
    <DataContext.Provider
      value={{
        isReloading,
        setIsReloading,
        merchant,
        setMerchant,
        location,
        setLocation,
        user,
        setUser,
        userInfo,
        setUserInfo,
        getUserInfo,
        socketClient,
        setSocketClient,
        connect,
        events,
        setEvents,
        squareAuthCode,
        setSquareAuthCode,
        newRedeem,
        setNewRedeem,
        redeemed,
        setRedeemed,
        redeemRejected,
        setRedeemRejected,
        claimAccepted,
        setClaimAccepted,
        claimRequested,
        setClaimRequested,
        claimRejected,
        setClaimRejected,
      }}
    >
      <AIContext.Provider
        value={{
          trackEvent,
          trackPageView,
          trackMetric,
          startTrackEvent,
          stopTrackEvent,
          setUserId,
          getCookieManager,
        }}
      >
        <ChakraProvider theme={theme}>
          <Fonts />
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
            <ModalContent style={{ background: "unset", boxShadow: "unset" }}>
              <Spinner
                margin={"auto"}
                size={"xl"}
                color={"orange"}
                speed="0.65s"
                thickness="4px"
              />
              {/* <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Custom backdrop filters!</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter> */}
            </ModalContent>
          </Modal>
          <AnimatePresence>
            <div className="wrapper" id="rootWrapper">
              <ScrollRestoration
                getKey={(location, matches) => {
                  // default behavior
                  return location.key;
                }}
              />
              <Flex
                className="page-header"
                justifyContent={"space-between"}
                paddingTop={"5"}
                paddingLeft={"10"}
                paddingRight={"10"}
                paddingBottom={"5"}
              >
                <Flex>
                  <Link as={ReactLink} to="/">
                    {/* <Lottie
                    autoplay={true}
                    loop={true}
                    animationData={animationData}
                    style={{ height: "100px", width: "100px" }}
                  /> */}
                    <Logo size={50} />
                  </Link>
                </Flex>
                <Center>
                  <Flex zIndex="10">
                    {!(user || hasUserState) && (
                      <Link as="a" href="/login" color={"gray.600"}>
                        Log In
                      </Link>
                    )}
                    {isLoadingUser ? (
                      <SkeletonCircle size="10" />
                    ) : (
                      <>
                        {(user || hasUserState) && (
                          <>
                            <Menu>
                              <MenuButton>
                                <Center>
                                  <Avatar
                                    name={
                                      user?.user_name || hasUserState?.user_name
                                    }
                                    size="md"
                                    iconLabel={
                                      user?.user_name || hasUserState?.user_name
                                    }
                                  />
                                  <ChevronDownIcon
                                    boxSize={"6"}
                                    color={"gray.500"}
                                    marginLeft={"2"}
                                  />
                                </Center>
                              </MenuButton>
                              <MenuList zIndex={3}>
                                <MenuGroup title="Profile">
                                  <Link
                                    as={ReactLink}
                                    _hover={{ textDecoration: "none" }}
                                    to={`/customer/${
                                      user?.id || hasUserState?.id
                                    }`}
                                    reloadDocument={!user}
                                  >
                                    <MenuItem icon={<TbCards />}>
                                      My Cards
                                    </MenuItem>
                                  </Link>
                                  {(user?.merchant_id ||
                                    hasUserState?.merchant_id) && (
                                    <Link
                                      as={ReactLink}
                                      _hover={{ textDecoration: "none" }}
                                      to={`/merchant/${
                                        user?.merchant_id ||
                                        hasUserState?.merchant_id
                                      }`}
                                      reloadDocument={!user}
                                    >
                                      <MenuItem icon={<BsShop />}>
                                        My Business
                                      </MenuItem>
                                    </Link>
                                  )}
                                  {/* {((user && !user.subscription_id) ||
                                    (hasUserState &&
                                      !hasUserState.subscription_id)) && (
                                    <>
                                      <MenuDivider />
                                      <MenuGroup title="Subscribe">
                                        <Link
                                          as={ReactLink}
                                          _hover={{ textDecoration: "none" }}
                                          to={`/customer?redirect=checkout`}
                                          reloadDocument={!user}
                                        >
                                          <MenuItem
                                            _hover={{
                                              color: "white",
                                              bgGradient:
                                                "linear(to-r, red.500, yellow.500)",
                                            }}
                                            // onClick={() =>
                                            //   navigate(
                                            //     `/customer?redirect=checkout`
                                            //   )
                                            // }
                                          >
                                            Create a Business
                                          </MenuItem>
                                        </Link>
                                      </MenuGroup>
                                    </>
                                  )} */}
                                  {user?.subscription_id &&
                                    !user?.merchant_id && (
                                      <Link
                                        as={ReactLink}
                                        _hover={{ textDecoration: "none" }}
                                        to={`/merchant/new`}
                                      >
                                        <MenuItem
                                          icon={<RepeatIcon />}
                                          backgroundColor={"orange.100"}
                                          _hover={{
                                            color: "white",
                                            bgGradient:
                                              "linear(to-r, red.500, yellow.500)",
                                          }}
                                        >
                                          Setup My Shop
                                        </MenuItem>
                                      </Link>
                                    )}
                                </MenuGroup>
                                <MenuDivider />
                                {/* {user &&
                                  user.id &&
                                  user.subscription_customer_id && (
                                    <Form
                                      method="post"
                                      action={`/customer/${user.id}/checkout`}
                                    >
                                      <input
                                        type="hidden"
                                        name="user_id"
                                        value={user.id}
                                      />
                                      <input
                                        type="hidden"
                                        name="customer_id"
                                        value={user.subscription_customer_id}
                                      />
                                      <input
                                        type="hidden"
                                        name="session_type"
                                        value="portal"
                                      />
                                      <MenuGroup title="Subscription">
                                        <MenuItem
                                          as={"button"}
                                          type={"submit"}
                                          icon={<IoSettingsOutline />}
                                        >
                                          Manage
                                        </MenuItem>
                                      </MenuGroup>
                                      <MenuDivider />
                                    </Form>
                                  )} */}
                                {/* <MenuGroup title="Help">
                                <MenuItem>Docs</MenuItem>
                                <MenuItem>FAQ</MenuItem>
                              </MenuGroup>
                              <MenuDivider /> */}
                                {userInfo?.userRoles.find(
                                  (r) => r === "admin"
                                ) && (
                                  <>
                                    <MenuGroup title="Administration">
                                      <Link
                                        as={ReactLink}
                                        _hover={{ textDecoration: "none" }}
                                        to={`/dashboard`}
                                      >
                                        <MenuItem
                                          _hover={{
                                            color: "white",
                                            bgGradient:
                                              "linear(to-r, red.500, yellow.500)",
                                          }}
                                        >
                                          Dashboard
                                        </MenuItem>
                                      </Link>
                                    </MenuGroup>
                                    <Divider />
                                  </>
                                )}
                                <MenuGroup title="Support">
                                  <MenuItem
                                    as="a"
                                    href={`mailto:contact@kanvent.com`}
                                  >
                                    Contact Us
                                  </MenuItem>
                                </MenuGroup>
                                <MenuDivider />
                                <MenuItem
                                  as="a"
                                  href={`/logout?client_id=CJH9xq9c0jWTOlr8LW70yYqi&post_logout_redirect_uri=${window.location.protocol}//${window.location.host}/.auth/logout/passage/callback`}
                                  onClick={() =>
                                    localforage.removeItem("userState")
                                  }
                                >
                                  Log out
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                </Center>
              </Flex>
              {user && !isHome && (
                <Container
                  className="content"
                  maxW="container.lg"
                  minWidth={"-webkit-fill-available"}
                  centerContent
                  py={{ base: "16", md: "24" }}
                  alignItems={"center"}
                >
                  <Outlet />
                </Container>
              )}
              {(user || hasUserState) && isHome && (
                <Container
                  className="content"
                  maxW="container.lg"
                  minWidth={"-webkit-fill-available"}
                  centerContent
                  py={{ base: "8", sm: "16", md: "24" }}
                  alignItems={"center"}
                >
                  <Stack
                    spacing={{ base: "4", sm: "8", md: "10" }}
                    backgroundColor={"orange.100"}
                    padding={{ base: "5", sm: "10" }}
                    borderRadius={"2xl"}
                    zIndex={1}
                    maxW={{ base: "sm", sm: "xl" }}
                    width={"100%"}
                  >
                    <Stack spacing={{ base: "4", md: "5" }} align="center">
                      <Heading
                        size={{ base: "xl", md: "xl" }}
                        marginTop={{ base: "0", sm: "5" }}
                        textAlign={"center"}
                        className="gradient"
                      >
                        Welcome
                      </Heading>
                      {/* <Text
                      color="fg.muted"
                      maxW="2xl"
                      textAlign="center"
                      fontSize="xl"
                    >
                      Select one of the below options:
                    </Text> */}
                    </Stack>
                    <Card
                      // maxW={{ sm: "lg", md: "xs" }}
                      direction={{ base: "column", sm: "row" }}
                      mx={"auto"}
                      my={"5"}
                      overflow="hidden"
                      size={"sm"}
                      width={"100%"}
                      borderRadius={"lg"}
                    >
                      <Image
                        // src="https://images.unsplash.com/photo-1556745753-b2904692b3cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&h=200&q=80"
                        // src="https://images.pexels.com/photos/9050556/pexels-photo-9050556.jpeg?fit=crop&auto=compress&cs=tinysrgb&w=300&h=200&dpr=1"
                        src="/customer-1.jpeg"
                        objectFit="cover"
                        maxW={{ base: "100%", sm: "200px" }}
                      />
                      <Stack>
                        <CardBody>
                          <Stack
                            mt={{ base: "3", sm: "6" }}
                            spacing="3"
                            // height={"128"}
                          >
                            <Heading size="md">{CustomersHeading}</Heading>
                            <Text>{CustomersDescription}</Text>
                            <Text color="orange.600" fontSize="s"></Text>
                          </Stack>
                        </CardBody>
                        <CardFooter>
                          <Link
                            as={ReactLink}
                            to={`/customer/${user?.id || hasUserState?.id}`}
                            reloadDocument={!user}
                          >
                            <Button
                              rightIcon={<ArrowForwardIcon />}
                              variant="solid"
                              colorScheme="orange"
                              _hover={{
                                color: "white",
                                bgGradient: "linear(to-r, red.500, yellow.500)",
                              }}
                            >
                              Open My Cards
                            </Button>
                          </Link>
                        </CardFooter>
                      </Stack>
                    </Card>
                  </Stack>
                  <AnimatePresence>
                    <motion.div
                      ref={dynamicCardRef}
                      key="startBusinessCard"
                      id="startBusinessCard"
                      initial={{
                        y: -cardDynamicHeight - cardFooterDynamicHeight,
                      }}
                      animate={{
                        y: -1 * cardDynamicHeight + cardFooterDynamicHeight,
                      }}
                      // whileHover={{ y: -10 }}
                      // whileTap={{ y: -10 }}
                      transition={{
                        duration: 0.3,
                        delay: 0.3,
                      }}
                    >
                      <Stack
                        spacing={{ base: "4", sm: "8", md: "10" }}
                        px={{ base: "5", sm: "10" }}
                        maxW={{ base: "sm", sm: "xl" }}
                        width={"100%"}
                      >
                        {user?.merchant_id || hasUserState?.merchant_id ? (
                          <Card
                            mx={"auto"}
                            // my={"5"}
                            overflow="hidden"
                            size={"sm"}
                            width={"95%"}
                            borderRadius={"lg"}
                          >
                            <CardBody pt={"5"}>
                              <Stack
                                spacing="3"
                                flexDirection={{ base: "column", sm: "row" }}
                              >
                                <Image
                                  // src="https://images.unsplash.com/photo-1556745753-b2904692b3cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&h=200&q=80"
                                  // src="https://images.pexels.com/photos/9050556/pexels-photo-9050556.jpeg?fit=crop&auto=compress&cs=tinysrgb&w=300&h=200&dpr=1"
                                  src="/business-2.jpeg"
                                  objectFit="cover"
                                  maxW={{ base: "100%", sm: "200px" }}
                                  minH={"130px"}
                                  borderRadius="lg"
                                  onLoad={() => {
                                    animate(dynamicCardRef.current, {
                                      y: `${
                                        -dynamicCardRef.current.clientHeight +
                                        dynamicCardFooterRef!.current!
                                          .clientHeight
                                      }px`,
                                    });
                                  }}
                                />
                                <Stack
                                  paddingLeft={{ base: "unset", sm: "10" }}
                                  // paddingTop={{ base: "unset", sm: "10" }}
                                  spacing="3"
                                >
                                  {/* <Heading size="md">My Business</Heading> */}
                                  <Text>
                                    Open your business feed and manage settings.
                                  </Text>
                                </Stack>
                              </Stack>
                            </CardBody>
                            <Divider color={"blackAlpha.200"} />
                            <CardFooter
                              ref={dynamicCardFooterRef}
                              style={{
                                cursor: "pointer",
                                alignItems: "center",
                                position: "relative",
                              }}
                              _hover={{ backgroundColor: "orange.50" }}
                              justifyContent={"space-between"}
                              onClick={() => {
                                animate(dynamicCardRef.current, {
                                  y: cardDisplayToggle
                                    ? `${
                                        -cardDynamicHeight +
                                        cardFooterDynamicHeight
                                      }px`
                                    : "-10px",
                                });
                                setCardDisplayToggle((prev) => !prev);
                              }}
                            >
                              <Box
                                width={"100%"}
                                height={"100%"}
                                top={0}
                                bottom={0}
                                left={0}
                                right={0}
                                position={"absolute"}
                                backgroundColor={"#00000000"}
                                zIndex={1}
                                onTouchStart={() => {
                                  animate(dynamicCardRef.current, {
                                    y: cardDisplayToggle
                                      ? `${
                                          -cardDynamicHeight +
                                          cardFooterDynamicHeight
                                        }px`
                                      : "-10px",
                                  });
                                  setCardDisplayToggle((prev) => !prev);
                                }}
                              />
                              <Heading size="md" ml={"2"} color={"orange"}>
                                My Business
                              </Heading>
                              <Link
                                as={ReactLink}
                                to={`/merchant/${
                                  user?.merchant_id || hasUserState?.merchant_id
                                }`}
                                style={{ zIndex: 1500 }}
                                reloadDocument={!user}
                              >
                                <Button
                                  rightIcon={<ArrowForwardIcon />}
                                  variant="outline"
                                  colorScheme="orange"
                                  _hover={{
                                    color: "white",
                                    bgGradient:
                                      "linear(to-r, red.500, yellow.500)",
                                  }}
                                >
                                  Open
                                </Button>
                              </Link>
                            </CardFooter>
                          </Card>
                        ) : (
                          <Card
                            mx={"auto"}
                            // my={"5"}
                            overflow="hidden"
                            size={"sm"}
                            width={"95%"}
                            borderRadius={"lg"}
                          >
                            <CardBody pt={"5"}>
                              <Stack
                                spacing="3"
                                flexDirection={{ base: "column", sm: "row" }}
                              >
                                <Image
                                  src="/business-1.jpeg"
                                  objectFit="cover"
                                  maxW={{ base: "100%", sm: "200px" }}
                                  minH={"130px"}
                                  borderRadius="lg"
                                  onLoad={() => {
                                    animate(dynamicCardRef.current, {
                                      y: `${
                                        -dynamicCardRef.current.clientHeight +
                                        dynamicCardFooterRef!.current!
                                          .clientHeight
                                      }px`,
                                    });
                                  }}
                                />
                                <Stack
                                  paddingLeft={{ base: "unset", sm: "10" }}
                                  spacing="3"
                                >
                                  <Heading size="md">{BusinessHeading}</Heading>
                                  <Text>{BusinessDescription}</Text>
                                </Stack>
                              </Stack>
                            </CardBody>
                            <Divider color={"blackAlpha.200"} />
                            <CardFooter
                              ref={dynamicCardFooterRef}
                              style={{
                                cursor: "pointer",
                                alignItems: "center",
                                position: "relative",
                              }}
                              _hover={{ backgroundColor: "orange.50" }}
                              justifyContent={"space-between"}
                              onClick={() => {
                                animate(dynamicCardRef.current, {
                                  y: cardDisplayToggle
                                    ? `${
                                        -cardDynamicHeight +
                                        cardFooterDynamicHeight
                                      }px`
                                    : "-10px",
                                });
                                setCardDisplayToggle((prev) => !prev);
                              }}
                            >
                              <Box
                                width={"100%"}
                                height={"100%"}
                                top={0}
                                bottom={0}
                                left={0}
                                right={0}
                                position={"absolute"}
                                backgroundColor={"#00000000"}
                                zIndex={1}
                                onTouchStart={() => {
                                  animate(dynamicCardRef.current, {
                                    y: cardDisplayToggle
                                      ? `${
                                          -cardDynamicHeight +
                                          cardFooterDynamicHeight
                                        }px`
                                      : "-10px",
                                  });
                                  setCardDisplayToggle((prev) => !prev);
                                }}
                              />
                              <Link
                                as={"a"}
                                href="mailto:contact@kanvent.com"
                                style={{ zIndex: 1500 }}
                              >
                                <Button
                                  type="submit"
                                  colorScheme="orange"
                                  variant="outline"
                                  rightIcon={<ArrowForwardIcon />}
                                  _hover={{
                                    color: "white",
                                    bgGradient:
                                      "linear(to-r, red.500, yellow.500)",
                                  }}
                                >
                                  Business Sign-up!
                                </Button>
                              </Link>
                              <Flex flexDirection={"row"}>
                                <Icon
                                  as={CgSquare}
                                  my="auto"
                                  mr="1"
                                  w={8}
                                  h={8}
                                  color="gray.400"
                                />
                                <Text color="gray.400" fontSize="s" my="auto">
                                  {SquareDescription}
                                </Text>
                              </Flex>
                            </CardFooter>
                          </Card>
                        )}
                      </Stack>
                    </motion.div>
                  </AnimatePresence>
                </Container>
              )}
              {!user && !hasUserState && isHome && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    bg="bg.surface"
                    className="hero-image fade-effect"
                    backgroundImage={{
                      base: "url('/banner-2.jpeg')",
                      sm: "url('/banner-1.webp')",
                    }}
                  >
                    <Container
                      py={{ base: "16", md: "24" }}
                      position="relative"
                      overflow="visible"
                    >
                      <Stack spacing={{ base: "8", md: "10" }}>
                        <Stack spacing={{ base: "4", md: "5" }} align="center">
                          <Heading
                            size={{ base: "xl", md: "xl" }}
                            textAlign={"center"}
                          >
                            {HeroHeading}
                          </Heading>
                          <Text maxW="2xl" textAlign="center" fontSize="xl">
                            {HeroDescription}
                          </Text>
                        </Stack>
                        <Stack
                          spacing="3"
                          direction={{ base: "column", sm: "row" }}
                          justify="center"
                        >
                          <Button
                            colorScheme="orange"
                            variant={"solid"}
                            _hover={{
                              color: "white",
                              bgGradient: "linear(to-r, red.500, yellow.500)",
                            }}
                            size="lg"
                            onClick={() => {
                              scrollToAnchor("signup");
                              setPrevTriggerAnimation(2);
                              setTriggerAnimation(3);
                            }}
                          >
                            Sign-up my Business!
                          </Button>
                          {!user && (
                            <Link
                              href="/login"
                              color={"gray.600"}
                              textAlign={"center"}
                            >
                              <Button variant={"ghost"} size="lg">
                                Customers have free access
                              </Button>
                            </Link>
                          )}
                        </Stack>
                      </Stack>
                    </Container>
                  </Box>
                  <Container
                    className="featureContent"
                    maxW="container.lg"
                    minWidth={"-webkit-fill-available"}
                    centerContent
                    py={{ base: "16", md: "24" }}
                    alignItems={"center"}
                  >
                    <AnimatePresence>
                      <Stack
                        spacing={{ base: "8", md: "10" }}
                        backgroundColor={"orange.100"}
                        padding={"10"}
                        borderRadius={"2xl"}
                      >
                        <Stack spacing={{ base: "4", md: "5" }} align="center">
                          <Heading
                            size={{ base: "xl", md: "xl" }}
                            marginTop={"5"}
                            textAlign={"center"}
                            className="gradient"
                          >
                            {HowHeading}
                          </Heading>
                          <Text
                            color="fg.muted"
                            maxW="2xl"
                            textAlign="center"
                            fontSize="xl"
                          >
                            {HowDescription}
                          </Text>
                        </Stack>
                        <VStack
                          spacing={{ base: "8", md: "10" }}
                          backgroundColor={"orange.100"}
                          borderRadius={"2xl"}
                        >
                          {features.map((f) => (
                            <motion.div
                              key={f.id}
                              initial={{ opacity: 0, y: -50 }}
                              animate={{ opacity: 1, y: 0 }}
                            >
                              <Card w={{ sm: "sm", md: "md", lg: "lg" }}>
                                <CardHeader>
                                  <Heading
                                    as="h2"
                                    size={{ base: "xl", md: "xl", lg: "xl" }}
                                    className="gradient"
                                    noOfLines={1}
                                  >
                                    {f.text}
                                  </Heading>
                                </CardHeader>
                                <CardBody>
                                  <Flex flexDirection={"row"}>
                                    <Box>
                                      <Text>{f.description}</Text>
                                    </Box>
                                    <Box>
                                      <Center width={"100%"} height={"100%"}>
                                        {f.child}
                                      </Center>
                                    </Box>
                                  </Flex>
                                </CardBody>
                              </Card>
                            </motion.div>
                          ))}
                        </VStack>
                      </Stack>
                    </AnimatePresence>
                  </Container>

                  <Container
                    className="content"
                    maxW="container.lg"
                    minWidth={"-webkit-fill-available"}
                    centerContent
                    py={{ base: "16", md: "24" }}
                    alignItems={"center"}
                  >
                    <Stack spacing={{ base: "4", md: "5" }} align="center">
                      <Heading
                        size={{ base: "xl", sm: "xl" }}
                        marginTop={"5"}
                        textAlign={"center"}
                        color={"orange.500"}
                      >
                        {PasswordlessSubHeading}
                      </Heading>

                      <Flex
                        flexDirection={"column"}
                        alignItems={"center"}
                        textAlign={"center"}
                      >
                        <Text fontSize="xl">
                          {`Register and log in to Punchy in seconds, with Passkeys - no passwords to remember or two factor codes.`}
                        </Text>
                        <Popover>
                          <PopoverTrigger>
                            <Text fontSize="xl" role="button" mx={"1"}>
                              <u>What are Passkeys?</u>
                            </Text>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>
                              <Heading size={"md"} color={"orange.500"}>
                                About Passkeys
                              </Heading>
                            </PopoverHeader>
                            <PopoverBody>
                              <Flex flexDirection={"column"} padding={"5"}>
                                <Text pb={"5"}>
                                  Passkeys are a new technology that allows you
                                  to securely sign in to websites without a
                                  password.
                                </Text>
                                <UnorderedList>
                                  <ListItem>
                                    You use device-native biometrics such as
                                    Face ID, Touch ID, Windows Hello, or the
                                    Android fingerprint reader, but your
                                    biometrics never leave your device.
                                  </ListItem>
                                  <ListItem>
                                    For iPhones and iPads on iOS 16, passkeys
                                    are synced via iCloud so you can quickly log
                                    in from any device.
                                  </ListItem>
                                  <ListItem>
                                    Register and log in to websites in seconds -
                                    no passwords to remember or two factor codes
                                    to find.
                                  </ListItem>
                                </UnorderedList>
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Stack>
                  </Container>

                  <Container
                    className="content"
                    maxW="container.lg"
                    minWidth={"-webkit-fill-available"}
                    centerContent
                    py={{ base: "16", md: "24" }}
                    alignItems={"center"}
                  >
                    <Outlet />
                    {isHome && (
                      <>
                        <Stack
                          spacing={{ base: "8", md: "10" }}
                          backgroundColor={"orange.100"}
                          padding={{ base: "3", sm: "6", md: "10" }}
                          borderRadius={"2xl"}
                        >
                          <Stack
                            spacing={{ base: "4", md: "5" }}
                            align="center"
                          >
                            <Heading
                              size={{ base: "xl", md: "xl" }}
                              marginTop={"5"}
                              textAlign={"center"}
                              className="gradient"
                            >
                              {C2AHeading}
                            </Heading>
                            <Text
                              color="fg.muted"
                              maxW="2xl"
                              textAlign="center"
                              fontSize="xl"
                            >
                              {C2ADescription}
                            </Text>
                          </Stack>
                          <Wrap direction={"row"}>
                            {!user && (
                              <WrapItem>
                                <Card maxW={{ sm: "2xl", md: "xs" }}>
                                  <CardBody>
                                    <Image
                                      // src="https://images.unsplash.com/photo-1556745753-b2904692b3cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&h=200&q=80"
                                      src="/customer-1.jpeg"
                                      borderRadius="lg"
                                      width={"100%"}
                                    />
                                    <Stack mt="6" spacing="3" height={"128"}>
                                      <Heading size="md">
                                        {CustomersHeading}
                                      </Heading>
                                      <Text>{CustomersDescription}</Text>
                                      <Text
                                        color="orange.600"
                                        fontSize="s"
                                      ></Text>
                                    </Stack>
                                  </CardBody>
                                  <CardFooter>
                                    <Link
                                      as={ReactLink}
                                      to={`/customer`}
                                      reloadDocument
                                    >
                                      <Button
                                        rightIcon={<ArrowForwardIcon />}
                                        colorScheme="orange"
                                        variant="outline"
                                        _hover={{
                                          color: "white",
                                          bgGradient:
                                            "linear(to-r, red.500, yellow.500)",
                                        }}
                                      >
                                        Get Started!
                                      </Button>
                                    </Link>
                                  </CardFooter>
                                </Card>
                              </WrapItem>
                            )}
                            <WrapItem margin={"auto"}>
                              <Card maxW={{ sm: "2xl", md: "xs" }}>
                                <CardBody>
                                  <Image
                                    // src="https://images.pexels.com/photos/1137745/pexels-photo-1137745.jpeg?auto=compress&cs=tinysrgb&w=300&h=200&dpr=1"
                                    src="/business-1.jpeg"
                                    borderRadius="lg"
                                    width={"100%"}
                                  />
                                  <Stack mt="6" spacing="3" height={"128"}>
                                    <Heading size="md">
                                      {BusinessHeading}
                                    </Heading>
                                    <Text>{BusinessDescription}</Text>
                                  </Stack>
                                </CardBody>
                                <CardFooter justifyContent={"space-between"}>
                                  <Link
                                    as={ReactLink}
                                    to={`/customer?redirect=checkout`}
                                    reloadDocument
                                  >
                                    <Button
                                      type="submit"
                                      colorScheme="orange"
                                      variant="outline"
                                      rightIcon={<ArrowForwardIcon />}
                                      _hover={{
                                        color: "white",
                                        bgGradient:
                                          "linear(to-r, red.500, yellow.500)",
                                      }}
                                    >
                                      Sign-up Today!
                                    </Button>
                                  </Link>
                                  <Flex flexDirection={"row"}>
                                    <Icon
                                      as={CgSquare}
                                      my="auto"
                                      mr="1"
                                      w={8}
                                      h={8}
                                      color="gray.400"
                                    />
                                    <Text
                                      color="gray.400"
                                      fontSize="s"
                                      my="auto"
                                    >
                                      {SquareDescription}
                                    </Text>
                                  </Flex>
                                  {/* )} */}
                                </CardFooter>
                              </Card>
                            </WrapItem>
                            {/* )} */}
                          </Wrap>
                        </Stack>
                      </>
                    )}
                  </Container>

                  {/* ## PRICING INFORMATION ### */}
                  {/* <Container
                  className="content"
                  maxW="container.lg"
                  minWidth={"-webkit-fill-available"}
                  height="80vh"
                  centerContent
                >
                  <Box
                    id="pricing"
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    px={{ base: "4", md: "8" }}
                    width="full"
                    height="full"
                  >
                    <Heading
                      size={{ base: "xl", md: "xl" }}
                      marginTop={"5"}
                      textAlign={"center"}
                      className="gradient"
                      py="20"
                    >
                      Pricing
                    </Heading>
                    <Box
                      bg={bgValue}
                      position="relative"
                      px="6"
                      pb="6"
                      pt="16"
                      overflow="hidden"
                      shadow="2xl"
                      maxW="sm"
                      minW="2xs"
                      minH="lg"
                      width="100%"
                      rounded={{ sm: "xl" }}
                      {...rest}
                    >
                      <Flex
                        bg={"orange.300"}
                        position="absolute"
                        right={-20}
                        top={6}
                        width="240px"
                        transform="rotate(45deg)"
                        py={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          fontSize="xs"
                          textTransform="uppercase"
                          fontWeight="bold"
                          letterSpacing="wider"
                          color={"white"}
                        >
                          Per Location
                        </Text>
                      </Flex>
                      <VStack spacing={6}>
                        <Icon
                          aria-hidden
                          as={RiCupFill}
                          fontSize="4xl"
                          color={"orange.500"}
                        />
                        <Heading size="md">My Shop</Heading>
                      </VStack>
                      <Flex
                        align="flex-end"
                        justify="center"
                        color={"orange.500"}
                        my="8"
                      >
                        <Heading
                          className="gradient"
                          size="3xl"
                          fontWeight="semibold"
                          lineHeight="0.9em"
                        >
                          $30
                        </Heading>
                        <Text
                          className="gradient"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          / m
                        </Text>
                      </Flex>
                      <List spacing="4" mb="8" maxW="28ch" mx="auto">
                        {pricingFeatures.map((feature, index) => (
                          <ListItem fontWeight="medium" key={index}>
                            <ListIcon
                              fontSize="xl"
                              as={HiCheckCircle}
                              marginEnd={2}
                              color={"orange.500"}
                            />
                            {feature}
                          </ListItem>
                        ))}
                      </List>
                      <Link
                        as={ReactLink}
                        to={`/customer?redirect=checkout`}
                        reloadDocument
                      >
                        <Button
                          type="button"
                          rightIcon={<ArrowForwardIcon />}
                          colorScheme="orange"
                          variant="outline"
                          _hover={{
                            color: "white",
                            bgGradient: "linear(to-r, red.500, yellow.500)",
                          }}
                          size="lg"
                          w="full"
                        >
                          Buy now
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Container> */}

                  <Container
                    className="content"
                    maxW="container.lg"
                    minWidth={"-webkit-fill-available"}
                    height="500px"
                    centerContent
                  >
                    <Box
                      id="signup"
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      px={{ base: "4", md: "8" }}
                      width="full"
                      height="full"
                    >
                      <Heading
                        size={"sm"}
                        color={"orange.500"}
                        textAlign={"center"}
                      >
                        {ContactHeading}
                      </Heading>
                      <Heading as={"h2"} mt={"5"} textAlign={"center"}>
                        {ContactCallToAction}
                      </Heading>
                      <Button
                        type="button"
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="orange"
                        variant="outline"
                        _hover={{
                          color: "white",
                          bgGradient: "linear(to-r, red.500, yellow.500)",
                        }}
                        size="lg"
                        mt={"10"}
                        onClick={(e) => {
                          window.location.href = "mailto:contact@kanvent.com";
                          e.preventDefault();
                        }}
                      >
                        I want to get involved!
                      </Button>
                    </Box>
                  </Container>
                </>
              )}
              {!user && !isHome && (
                <Container
                  className="content"
                  maxW="container.lg"
                  minWidth={"-webkit-fill-available"}
                  centerContent
                  py={{ base: "16", md: "24" }}
                  alignItems={"center"}
                >
                  <Outlet />
                </Container>
              )}
              <footer className="page-footer">
                <Center color={"gray.400"}>
                  © Kanvent {new Date().getFullYear()}
                  <ButtonGroup variant="tertiary">
                    <IconButton
                      as="a"
                      href="https://www.linkedin.com/company/kanvent/"
                      aria-label="LinkedIn"
                      icon={<FaLinkedin />}
                    />
                    <IconButton
                      as="a"
                      href="mailto:contact@kanvent.com"
                      aria-label="Email"
                      icon={<EmailIcon />}
                    />
                  </ButtonGroup>
                </Center>
              </footer>
            </div>
          </AnimatePresence>
        </ChakraProvider>
      </AIContext.Provider>
    </DataContext.Provider>
  );
}
