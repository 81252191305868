import { useReducer, useEffect } from "react";

export function useData(loadData: (forced: boolean) => {}) {
  const initialState = {
    data: undefined,
    error: undefined,
    loading: true,
    forced: false,
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "FETCH_SUCCESS":
        return {
          ...state,
          data: action.payload,
          error: undefined,
          loading: false,
          forced: false,
        };
      case "FETCH_ERROR":
        return {
          ...state,
          error: action.payload,
          loading: false,
          forced: false,
        };
      case "RELOAD":
        return {
          ...state,
          loading: true,
          forced: false,
        };
      case "FORCED_RELOAD":
        return {
          ...state,
          loading: true,
          forced: true,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchData(forced: boolean) {
      try {
        const data = await loadData(forced);
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (error) {
        dispatch({ type: "FETCH_ERROR", payload: error });
      }
    }
    if (state.loading) {
      fetchData(state.forced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, loadData]);

  function reload(forced:boolean) {
    if(forced){
      dispatch({ type: "FORCED_RELOAD" });
    } else {
      dispatch({ type: "RELOAD" });
    }
  }

  return [state.data, state.error, state.loading, reload];
}
