import { useEffect, useState } from "react";
import {
  // Box,
  Button,
  Center,
  Divider,
  Flex,
  // Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  // SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { callFunction } from "../controllers/apiClient";
import { v4 as uuid } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";
import { Card, Location, Redeem } from "../lib/constants";
// import { ArrowForwardIcon } from "@chakra-ui/icons";
import { BiQrScan } from "react-icons/bi";

export async function loader({ params }: any) {
  const cardId = params.cardId;
  if (cardId) {
    try {
      const cd = await callFunction("redeem", "get", undefined, {
        card_id: cardId,
      });
      if (!cd) throw new Error("Could not retrieve card");
      return { card: cd };
    } catch (err) {
      console.log("Error:", err);
      throw new Error("Could not retrieve card");
    }
  }
}

export const CustomerRedeemClaim = () => {
  const id = uuid();
  const toast = useToast();
  const data = useLoaderData() as any;
  const navigate = useNavigate();
  const [card, setCard] = useState<Card>();
  const { cardId, userId } = useParams();
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () => {
      navigate(`../`);
    },
  });
  const [isRedeeming, setIsRedeeming] = useState<boolean>(false);
  const [isRedeemingAtLocation, setIsRedeemingAtLocation] =
    useState<boolean>(false);
  // const [isSquareMerchant, setIsSquareMerchant] = useState<boolean>(false);
  // const [canRedeemAtLastLocation, setCanRedeemAtLastLocation] =
  useState<boolean>(true);
  // const [canRedeemAtMultipleLocations, setCanRedeemAtMultipleLocations] =
  useState<boolean>(false);
  const [redeemLocation, setRedeemLocation] = useState<Location | undefined>();
  const [showQR, setShowQR] = useState<boolean>(false);
  const [showQROnly, setShowQROnly] = useState<boolean>(false);
  const [QRSwitchText, setQRSwitchText] = useState<string>("Show QR code");
  const [redeem, setRedeem] = useState<Redeem>();

  useEffect(() => {
    if (data && data.card) {
      const redeemCard = data.card as Card;
      // setIsSquareMerchant(!!redeemCard.merchant?.merchant_id);
      setCard(redeemCard);
      let test = true;
      // redeemCard.claims[redeemCard.claims.length-1].location_id
      if (redeemCard.merchant) {
        // Check that the last location claimed against does _not_ exist
        // We assume yes (see above)
        if (
          !redeemCard.merchant?.subscription_locations?.some((obj) => {
            return Object.values(obj).some((value) => {
              if (
                typeof value === "string" &&
                value.includes(
                  redeemCard.claims[redeemCard.claims.length - 1].location_id ||
                    ""
                )
              ) {
                return true;
              }
              return false;
            });
          })
        ) {
          // The old location is no longer there
          test = false;
          // setCanRedeemAtLastLocation(false);
        } else {
          test = true;
          // setCanRedeemAtLastLocation(true);
          setRedeemLocation(
            redeemCard.merchant.subscription_locations.find(
              (l) =>
                l.id ===
                redeemCard.claims[redeemCard.claims.length - 1].location_id
            )
          );
        }
        // Check for multiple locations.
        if (redeemCard.merchant?.subscription_locations!.length > 1) {
          // There are other locations, but more than one to redeem at
          // And the last location isn't available
          if (!test) {
            // Only Support Showing QR
            setShowQROnly(true);
            setQRSwitchText(`Ok, go ahead! Show QR Code.`);
          } else {
            // Last one is available, but there are others to choose from
            setQRSwitchText(`Show a QR code instead.`);
          }
          // setCanRedeemAtMultipleLocations(true);
        } else if (redeemCard.merchant?.subscription_locations!.length === 1) {
          // There is only one place you can redeem
          setShowQROnly(false);
          // setCanRedeemAtMultipleLocations(false);
          if (test) {
            setRedeemLocation(redeemCard.merchant.subscription_locations![0]);
          }
        } else {
          // No active locations at all
          setShowQROnly(true);
          setQRSwitchText(`Ok, go ahead! Show QR Code.`);
          // setCanRedeemAtMultipleLocations(false);
        }
      }
    }
  }, [data]);

  const handleRedeemClaim = async (hasLocation: boolean) => {
    try {
      if (cardId && userId) {
        if (hasLocation) {
          setIsRedeeming(true);
        } else {
          setIsRedeemingAtLocation(true);
        }
        const redeemResponse = await callFunction("redeem", "post", {
          id,
          merchantId: card!.merchant_id,
          cardId: card!.id,
          locationId: hasLocation ? redeemLocation!.id : undefined,
        });

        if (redeemResponse) {
          setRedeem(redeemResponse);
          if (hasLocation) {
            toast({
              title: "Request sent!",
              description: "The shop has been notified",
              position: "top",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            navigate("../");
          } else {
            setShowQR(true);
          }
        }
      } else {
        setRedeem(undefined);
        throw new Error("No card or user data");
      }
    } catch (err) {
    } finally {
      setIsRedeeming(false);
      setIsRedeemingAtLocation(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={isRedeeming ? false : true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {/* <Heading as="h3" size="md">
            {""}
          </Heading> */}
        </ModalHeader>
        <ModalBody
          padding={6}
          position={"relative"}
          height={"300px"}
          flex={"unset"}
        >
          <AnimatePresence>
            {card && showQR && (
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.3,
                }}
              >
                <Skeleton isLoaded={!!redeem}>
                  <Flex
                    id={card.id}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <QRCodeSVG
                      size={250}
                      style={{ margin: "auto" }}
                      value={`${window.location.protocol}//${
                        window.location.host
                      }/gateway/redeem/${redeem!.id}`}
                    />
                    {process.env.NODE_ENV !== "production" && (
                      <p>
                        {`${window.location.protocol}//${
                          window.location.host
                        }/gateway/redeem/${redeem!.id}`}
                      </p>
                    )}
                  </Flex>
                </Skeleton>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {card && !showQR && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{
                  duration: 0.3,
                }}
              >
                <Center flexDirection={"column"}>
                  <Text textAlign={"center"}>{"You are redeeming here:"}</Text>
                  <Text fontWeight={"semibold"}>{redeemLocation?.name}</Text>
                  {!showQROnly && (
                    <>
                      <Button
                        onClick={() => {
                          if (!!!redeem) handleRedeemClaim(true);
                        }}
                        variant={"solid"}
                        my="5"
                        color="white"
                        bgGradient="linear(to-r, green.500, yellow.500)"
                        _hover={{
                          color: "white",
                          bgGradient: "linear(to-r, yellow.500, red.500,)",
                        }}
                        isLoading={isRedeemingAtLocation}
                        isDisabled={isRedeeming}
                      >
                        Yes! Redeem my FREE item!
                      </Button>
                      <Divider orientation={"horizontal"} my={5} />
                    </>
                  )}
                  <Button
                    onClick={() => {
                      if (!!!redeem) handleRedeemClaim(false);
                    }}
                    variant={"solid"}
                    isDisabled={isRedeemingAtLocation}
                    isLoading={isRedeeming}
                    leftIcon={<BiQrScan />}
                  >
                    {QRSwitchText}
                  </Button>
                </Center>
              </motion.div>
            )}
          </AnimatePresence>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={isRedeeming || isRedeemingAtLocation}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
