import { useContext, useEffect, useState, useRef } from "react";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import { AIContext } from "../lib/aiContext";
import {
  Avatar,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import AvatarEditor from "react-avatar-editor";
import {
  EditIcon,
  QuestionOutlineIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { motion } from "framer-motion";

export const SettingsMerchant = () => {
  const { trackEvent } = useContext(AIContext);
  const toast = useToast();
  const { merchant, setMerchant, user, userInfo } = useContext(DataContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState(merchant?.business_name);
  const [redeemIndex, setRedeemIndex] = useState(merchant?.redeem_index);
  const avatarEditorRef = useRef<AvatarEditor | null>(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [showAvatarEditor, setShowAvatarEditor] = useState<boolean>(false);
  const [avatarImage, setAvatarImage] = useState<
    HTMLImageElement | undefined
  >();
  const [scale, setScale] = useState<number>(1);

  const formRef = useRef<HTMLFormElement | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null!);

  const handleClick = (event: any) => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  useEffect(() => {
    if (merchant) {
      setBusinessName(merchant.business_name);
      setRedeemIndex(merchant.redeem_index);
      // reset flag if we are explicitly setting them
      setCanSave(false);
    }
  }, [merchant]);

  useEffect(() => {
    if (merchant && businessName && redeemIndex) {
      if (
        businessName !== merchant.business_name ||
        redeemIndex !== merchant.redeem_index
      ) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessName, redeemIndex]);

  const handleAvatarEditReset = () => {
    setAvatarImage(undefined);
    setShowAvatarEditor(false);
    if (formRef?.current) formRef.current.reset();
  };

  const handleChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setShowAvatarEditor(true);
      try {
        const image = new Image();
        image.onload = async function () {
          const width = image.width;
          const height = image.height;
          if (width < 256 || height < 256) {
            toast({
              title: "Image dimensions are not valid.",
              description: "Image size must be at least 256 x 256 pixels",
              position: "top",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            setAvatarImage(image);
          }
        };
        image.src = URL.createObjectURL(file);
      } catch (e) {
        console.log(e);
        handleAvatarEditReset();
        toast({
          title: "Error",
          description: `${e}`,
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        trackEvent({
          name: "ErrorPage",
          properties: {
            user: user || "no user",
            userInfo: userInfo || "no userInfo",
            location: "SettingsMerchant",
            error: e,
          },
        });
      }
    }
  };

  const handleSaveImage = () => {
    setIsUploading(true);
    try {
      if (avatarEditorRef?.current) {
        // Create a new FormData instance
        avatarEditorRef.current
          .getImageScaledToCanvas()
          .toBlob(async (blob) => {
            if (blob) {
              const formData = new FormData();
              formData.append("file", blob);
              formData.append("merchant_id", merchant?.id || "");

              // Make a POST request to the Azure Function API
              const response = await callFunction(
                "merchant/logo",
                "post",
                formData,
                undefined,
                {
                  "Content-Type": "multipart/form-data",
                }
              );
              if (response) {
                if (response.logo_url) {
                  setMerchant(response);
                  toast({
                    title: "Success",
                    description: "Settings Updated.",
                    position: "top",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              } else {
                throw new Error("Could not get a response from server");
              }
            } else {
              throw new Error("Could not convert to blob");
            }
          });
      } else {
        throw new Error("Avatar editor not initialized");
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: `${e}`,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "SettingsMerchant",
          error: e,
        },
      });
    } finally {
      handleAvatarEditReset();
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      if (!businessName || !redeemIndex || !merchant)
        throw new Error("Nothing to update!");
      setIsSaving(true);
      // build api to update merchant details
      const res = await callFunction("merchant", "put", {
        type: "general",
        business_name: businessName,
        redeem_index: redeemIndex,
        merchant_id: merchant?.id,
      });
      if (res) {
        if (res.id) {
          setMerchant(res);
          toast({
            title: "Success",
            description: "Settings Updated.",
            position: "top",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        throw new Error("Could not update merchant details");
      }
    } catch (e) {
      console.log(e);
      const msg = e as any;
      toast({
        title: "Error",
        description: msg,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "SettingsMerchant",
          error: e,
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (!merchant) {
    return <Skeleton height="40px" />;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: 10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{
          duration: 0.5,
        }}
      >
        <Flex direction="column">
          <Flex
            flexDirection={{ base: "column", sm: "row" }}
            justifyContent={"space-between"}
            minHeight={"40px"}
          >
            <Center
              minW={{ base: "100%", sm: "350px" }}
              width={{ base: "100%", sm: "350px" }}
              margin={"auto"}
            >
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <VStack spacing={4} align="flex-start">
                  <Center
                    margin="auto"
                    px={{ base: "2", sm: "5" }}
                    py={{ base: "2", sm: "5" }}
                    border={showAvatarEditor ? `solid 2px` : ``}
                    borderColor={showAvatarEditor ? `blackAlpha.100` : ``}
                    borderRadius={"10"}
                  >
                    <Flex flexDir={"column"}>
                      <>
                        {showAvatarEditor && avatarImage !== undefined ? (
                          <Flex flexDirection={"column"}>
                            <AvatarEditor
                              ref={avatarEditorRef}
                              image={avatarImage.src}
                              width={200}
                              height={200}
                              borderRadius={100}
                              color={[255, 255, 255, 0.6]} // RGBA
                              scale={scale}
                              rotate={0}
                            />
                            <Text my={"2"} textAlign={"center"}>
                              Click'n'drag image.
                              <br />
                              Zoom in/out with the slider
                            </Text>
                            <Slider
                              aria-label="avatar-editor-scale-slider"
                              colorScheme="orange"
                              defaultValue={1}
                              step={0.1}
                              min={1}
                              max={3}
                              onChange={(val) => setScale(val)}
                            >
                              <SliderTrack>
                                <SliderFilledTrack />
                              </SliderTrack>
                              <SliderThumb />
                            </Slider>
                          </Flex>
                        ) : (
                          <Tooltip
                            placement="top"
                            label={merchant.business_name}
                          >
                            <Avatar
                              size="2xl"
                              name={merchant.business_name}
                              src={merchant.logo_url || ""}
                              margin="auto"
                              color="white"
                              backgroundColor={"orange.500"}
                            />
                          </Tooltip>
                        )}
                        <Flex
                          flexDirection={"row"}
                          justifyContent={"space-evenly"}
                        >
                          {showAvatarEditor ? (
                            <>
                              <Button
                                leftIcon={<CloseIcon />}
                                variant={"ghost"}
                                mt={"5"}
                                onClick={handleAvatarEditReset}
                                isDisabled={isUploading}
                                type="button"
                              >
                                Cancel
                              </Button>
                              <Button
                                leftIcon={<CheckIcon />}
                                variant={"ghost"}
                                mt={"5"}
                                onClick={async () => await handleSaveImage()}
                                isLoading={isUploading}
                                type="button"
                              >
                                Save
                              </Button>
                            </>
                          ) : (
                            <Tooltip
                              placement="top"
                              label="Please use an image size at least 128px x 128px."
                            >
                              <Button
                                leftIcon={<EditIcon />}
                                variant={"ghost"}
                                mt={"5"}
                                mx={"auto"}
                                onClick={handleClick}
                                isDisabled={isUploading}
                                type="button"
                              >
                                {merchant.logo_url ? "Edit" : "Add"}
                              </Button>
                            </Tooltip>
                          )}
                        </Flex>

                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      </>
                    </Flex>
                  </Center>
                  <FormControl>
                    <FormLabel htmlFor="name">
                      <Flex>
                        <>Business Name</>
                        <Tooltip
                          placement="top"
                          label={`Your business name visible on customer loyalty cards.`}
                        >
                          <QuestionOutlineIcon
                            ml="3"
                            boxSize="5"
                            color={"gray.500"}
                          />
                        </Tooltip>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="businessName"
                      type="input"
                      variant="filled"
                      name="businessName"
                      defaultValue={merchant?.business_name}
                      required
                      minLength={3}
                      maxLength={30}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="redeemQty">
                      <Flex>
                        <>Number of punches on card?</>
                        <Tooltip
                          placement="top"
                          label={`The last punch is the free one (e.g. buy 9, get 1 free = 10 punches).`}
                        >
                          <QuestionOutlineIcon
                            ml="3"
                            boxSize="5"
                            color={"gray.500"}
                          />
                        </Tooltip>
                      </Flex>
                    </FormLabel>
                    <NumberInput
                      defaultValue={redeemIndex}
                      max={20}
                      min={2}
                      id="redeemIndex"
                      name="redeemIndex"
                      variant="filled"
                      onChange={(_s, n) => setRedeemIndex(n)}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                  {canSave && (
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{
                        duration: 0.2,
                      }}
                      style={{ width: "100%" }}
                    >
                      <Button
                        isLoading={isSaving}
                        type="submit"
                        colorScheme="orange"
                        width="full"
                      >
                        Save
                      </Button>
                    </motion.div>
                  )}
                </VStack>
              </form>
            </Center>
          </Flex>
        </Flex>
      </motion.div>
    </>
  );
};
