import { useContext, useEffect, useState } from "react";
import {
  useLoaderData,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DataContext } from "../lib/dataContext";
import { callFunction } from "../controllers/apiClient";
import { Claim } from "../lib/constants";
import { CustomerCreateClaim } from "./CustomerCreateClaim";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  CardHeader,
  Heading,
  CardFooter,
  Button,
  Card as ChakraCard,
  Text,
  Center,
  Avatar,
  CardBody,
  Divider,
  useToast,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import pluralize from "pluralize";
import localforage from "localforage";

export async function loader({ params }: any) {
  try {
    const locationId = params.locationId;
    if (locationId) {
      const merchantResponse = await callFunction(
        "getMerchant",
        "get",
        undefined,
        {
          merchant_location_id: locationId,
        }
      );
      return { merchantResponse };
    } else return;
  } catch (err) {
    throw new Error("Could not fetch Merchant data");
  }
}

const CustomerClaimHeading = "Punch your card!";
const ConfirmLocationDescription = "Are you at this location?";
const PunchingDescription = "Waiting for the shop to punch your card...";

export const LocationClaim = () => {
  const {
    connect,
    claimAccepted,
    setClaimAccepted,
    claimRejected,
    setClaimRejected,
    user,
    userInfo,
  } = useContext(DataContext);
  const [claim, setClaim] = useState<Claim | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { merchantResponse } = useLoaderData() as any;
  const { locationId } = useParams();
  const navigate = useNavigate();
  const isAtClaim = useMatch("/claim/:locationId");
  const isClaimRoot = useMatch("/claim/");
  const toast = useToast();

  useEffect(() => {
    localforage.removeItem("redirect");
  }, []);

  useEffect(() => {
    if (user && userInfo && connect) {
      connect(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userInfo]);

  useEffect(() => {
    if (isClaimRoot && userInfo && user && user.id) {
      navigate(`/customer/${user.id}`);
    }
  }, [isClaimRoot, user, userInfo, navigate]);

  useEffect(() => {
    if (claimAccepted && claim && claimAccepted.id === claim.id && user) {
      setClaimAccepted(undefined);
      setClaim(undefined);
      navigate(`/customer/${user.id}`);
      toast({
        title: "Your card has been punched!",
        description: `It has been punched ${claim?.quantity} ${pluralize(
          "time",
          claim?.quantity
        )}`,
        position: "top",
        status: "success",
        duration: 1500,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimAccepted]);

  useEffect(() => {
    if (claimRejected && claim && claimRejected.id === claim.id && user) {
      setClaimRejected(undefined);
      setClaim(undefined);
      navigate(`/customer/${user.id}`);
      toast({
        title: "Uh oh!",
        description: `The merchant has declined to punch your card.`,
        position: "top",
        status: "warning",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimRejected]);

  return (
    <Flex
      id="LocationClaimContainer"
      width={[
        "90%", // 0-30em      // 480px
        "90%", // 30em-48em   // 768px
        "75%", // 48em-62em   // 992px
        "75%", // 62em-80em   // 1280px
        "75%", // 80em-96em+  // 1536px
      ]}
      maxW={"900"}
    >
      {isAtClaim && (
        <Center flexDirection={"column"} height={"100%"} width={"100%"}>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Button
              onClick={() => navigate(`/customer/${user!.id}`)}
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              width={"fit-content"}
              marginBottom={"5"}
            >
              Back
            </Button>
          </Flex>
          <Heading marginBottom={"5"}>{CustomerClaimHeading}</Heading>
          <Divider orientation="horizontal" mb={"5"} />
          <div style={{ width: "100%" }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <Flex flexDirection={"column"}>
                <ChakraCard
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  my={"5"}
                  mx={"auto"}
                  size={"sm"}
                  minW={"300px"}
                  width={"100%"}
                  maxW={"640px"}
                  bgGradient="linear(to-r, white, orange.50)"
                  borderBottom={"8px aqua"}
                >
                  <Stack width={"100%"}>
                    <CardHeader display={"flex"} flexDirection={"row"}>
                      <Avatar
                        name={merchantResponse?.business_name}
                        color="white"
                        src={merchantResponse?.logo_url || ""}
                        size="lg"
                      />
                      <Heading size="md" my="auto" ml="5">
                        {merchantResponse?.business_name}
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <AnimatePresence>
                        <motion.div>
                          <Center flexDirection={"column"}>
                            {claim ? (
                              <motion.div
                                id="customerClaimPending"
                                initial={{ opacity: 0, x: 0 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                  duration: 0.3,
                                  delay: 0.3,
                                }}
                              >
                                <Center>
                                  <Flex id={claim.id} flexDirection={"column"}>
                                    <Spinner
                                      margin={"auto"}
                                      size={"xl"}
                                      color={"orange"}
                                      speed="0.65s"
                                      thickness="4px"
                                    />
                                    <Text
                                      mt={"2"}
                                      fontSize={"lg"}
                                      textAlign={"center"}
                                    >
                                      {PunchingDescription}
                                    </Text>
                                  </Flex>
                                </Center>
                              </motion.div>
                            ) : (
                              <motion.div
                                id="customerClaim"
                                initial={{ opacity: 0, x: 0 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                  duration: 0.3,
                                  delay: 0.3,
                                }}
                              >
                                <Text textAlign={"center"}>
                                  {ConfirmLocationDescription}
                                </Text>
                                <Text fontSize={"xl"} textAlign={"center"}>
                                  <b>{merchantResponse?.location?.name}</b>
                                </Text>
                              </motion.div>
                            )}
                          </Center>
                        </motion.div>
                      </AnimatePresence>
                    </CardBody>
                    <CardFooter>
                      <Flex justifyContent={"space-evenly"} width={"100%"}>
                        {locationId && user && !claim && (
                          <Button
                            variant={"solid"}
                            color="white"
                            width={"60%"}
                            bgGradient="linear(to-r, green.500, yellow.500)"
                            _hover={{
                              color: "white",
                              bgGradient: "linear(to-r, yellow.500, red.500,)",
                            }}
                            onClick={onOpen}
                          >
                            Yes I am!
                          </Button>
                        )}
                      </Flex>
                    </CardFooter>
                  </Stack>
                </ChakraCard>
              </Flex>
            </motion.div>
          </div>
        </Center>
      )}
      <CustomerCreateClaim
        isOpen={isOpen}
        onClose={onClose}
        merchantId={merchantResponse.id}
        locationId={locationId!}
        user={user!}
        claim={claim}
        setClaim={setClaim}
      />
    </Flex>
  );
};
