type Props = {
  size: string;
};

export const FeatureScan: React.FC<Props> = ({ size }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="200" height="200" fill="white" />
      <path
        d="M103.25 85.6602L125.946 138.673H190.557L164.633 85.6602H103.25Z"
        fill="#20242B"
      />
      <path
        d="M89.6519 147.846L125.547 138.673H190.557V147.846L165.891 156.62L89.6519 156.62V147.846Z"
        fill="#1E2227"
      />
      <path
        d="M69.1706 133.888H162.7C164.969 133.888 166.981 132.438 167.713 130.282L190.812 64.8223C191.398 63.0886 190.126 61.2998 188.306 61.2998H94.776C92.5073 61.2998 90.4947 62.7492 89.7629 64.9049L66.6641 130.365C66.0786 132.099 67.3502 133.888 69.1706 133.888Z"
        fill="black"
      />
      <path
        d="M73.6987 127.506H162.295L183.777 66.8833H95.181L73.6987 127.506Z"
        fill="#FFF6EE"
      />
      <path
        d="M117.36 81.209L113.886 90.7525H124.042L127.516 81.209H117.36ZM122.133 88.026H117.781L119.269 83.9359H123.622L122.133 88.026Z"
        fill="black"
      />
      <path
        d="M108.923 104.385L105.45 113.929H115.606L119.079 104.385H108.923ZM113.697 111.203H109.344L110.833 107.113H115.185L113.697 111.203Z"
        fill="black"
      />
      <path
        d="M142.024 81.209L138.55 90.7525H148.706L152.18 81.209H142.024ZM146.797 88.026H142.444L143.933 83.9359H148.285L146.797 88.026Z"
        fill="black"
      />
      <path
        d="M144.813 93.48L141.836 101.66H134.582L133.589 104.387H143.745L147.715 93.48H144.813Z"
        fill="black"
      />
      <path
        d="M132.596 107.113L130.115 113.929H133.016L134.505 109.839H138.857L137.369 113.929H140.271L142.752 107.113H132.596Z"
        fill="black"
      />
      <path
        d="M130.417 81.209L129.424 83.9359H135.228L132.747 90.7525H135.649L139.122 81.209H130.417Z"
        fill="black"
      />
      <path
        d="M131.755 93.48L129.77 98.9338H123.967L120.989 107.114H126.793L124.312 113.931H127.213L130.687 104.387H124.884L125.876 101.66H131.68L133.664 96.2069H136.566L135.574 98.9338H138.475L140.46 93.48H131.755Z"
        fill="black"
      />
      <path
        d="M122.899 109.839H119.997L118.508 113.929H121.41L122.899 109.839Z"
        fill="black"
      />
      <path
        d="M121.065 98.9331H116.712L115.72 101.66H120.072L121.065 98.9331Z"
        fill="black"
      />
      <path
        d="M128.433 86.6621L125.952 93.4791H112.894L109.916 101.659H112.818L114.803 96.206H127.861L131.335 86.6621H128.433Z"
        fill="black"
      />
      <path
        d="M23.2705 40H75.2105C79.2313 40 82.481 43.2497 82.481 47.2705V165.912C82.481 170.924 78.4051 175 73.3929 175H25.0881C20.0759 175 16 170.924 16 165.912V47.2705C16 43.2497 19.2497 40 23.2705 40Z"
        fill="#202020"
      />
      <path
        d="M24.8682 41.3774H74.0817C78.1025 41.3774 81.3522 44.6271 81.3522 48.6479V164.205C81.3522 169.217 77.2764 173.293 72.2641 173.293H26.6858C21.6735 173.293 17.5977 169.217 17.5977 164.205V48.6479C17.5977 44.6547 20.8473 41.3774 24.8682 41.3774Z"
        fill="#1E2227"
      />
      <path
        d="M24.868 41.3768V41.9276H74.0816C77.7994 41.9276 80.8288 44.9569 80.8288 48.6748V164.204C80.8288 168.941 77.0008 172.769 72.2639 172.769H26.6856C21.9488 172.769 18.1207 168.941 18.1207 164.204V48.6473C18.1207 44.9294 21.1501 41.9276 24.868 41.9276V40.8535C20.5442 40.8535 17.0742 44.3511 17.0742 48.6473V164.204C17.0742 169.519 21.3704 173.816 26.6856 173.816H72.2639C77.5791 173.816 81.8753 169.519 81.8753 164.204V48.6473C81.8753 44.3235 78.3778 40.8535 74.0816 40.8535H24.868V41.3768Z"
        fill="#202020"
      />
      <path
        d="M73.2822 43.7451H52.5173C51.9389 43.7451 51.4983 44.2133 51.4983 44.7641V46.1686C51.4983 47.4079 50.5069 48.3993 49.543 48.3993H49.2951C48.0558 48.3993 47.0644 47.4079 47.0644 46.1686V44.7641C47.0644 44.1858 46.5962 43.7451 46.0454 43.7451H25.666C22.4163 43.7451 19.7725 46.3889 19.7725 49.6386V164.204C19.7725 168.005 22.8569 171.089 26.6574 171.089H72.2908C76.0913 171.089 79.1758 168.005 79.1758 164.204V49.6386C79.1758 46.3889 76.5319 43.7451 73.2822 43.7451Z"
        fill="#FB831B"
      />
      <path
        d="M73.2823 43.7451H52.5173C51.9389 43.7451 51.4983 44.2133 51.4983 44.7641V46.1686C51.4983 47.4079 50.5069 48.3993 49.2676 48.3993H49.2951C48.0558 48.3993 47.0644 47.4079 47.0644 46.1686V44.7641C47.0644 44.1858 46.5962 43.7451 46.0454 43.7451H25.666C22.4163 43.7451 19.7725 46.3889 19.7725 49.6386V61.0676H79.1758V49.6386C79.1758 46.3889 76.5319 43.7451 73.2823 43.7451Z"
        fill="#FB831B"
      />
      <path
        d="M50.342 164.865C53.2775 164.865 55.6572 162.486 55.6572 159.55C55.6572 156.615 53.2775 154.235 50.342 154.235C47.4065 154.235 45.0269 156.615 45.0269 159.55C45.0269 162.486 47.4065 164.865 50.342 164.865Z"
        fill="#1E2227"
      />
      <path
        d="M79.231 147.955H19.7451V56.7988H79.231V147.955Z"
        fill="#FFF7E7"
      />
      <path
        d="M56.5937 159.551H56.456C56.456 162.911 53.7296 165.664 50.3422 165.664C46.9548 165.664 44.2283 162.938 44.2283 159.551C44.2283 156.163 46.9548 153.437 50.3422 153.437C51.967 153.437 53.5092 154.07 54.6659 155.227C55.8226 156.384 56.456 157.926 56.456 159.551H56.7038C56.7038 156.026 53.8672 153.189 50.3422 153.189C46.8171 153.189 43.9805 156.026 43.9805 159.551C43.9805 163.076 46.8171 165.912 50.3422 165.912C53.8672 165.912 56.7038 163.076 56.7038 159.551H56.5937Z"
        fill="#1E2227"
      />
      <path
        d="M24.4277 121.793L24.4277 129.188C24.4277 129.463 24.6481 129.684 24.9235 129.684L32.1527 129.684C32.4281 129.684 32.6484 129.491 32.6484 129.215C32.6484 128.94 32.4556 128.72 32.1802 128.72L32.1527 128.72L25.3916 128.72L25.3916 121.793C25.3916 121.518 25.1713 121.325 24.8959 121.325C24.6205 121.325 24.4277 121.546 24.4277 121.793Z"
        fill="#202020"
      />
      <path
        d="M32.3306 79.1064L24.9362 79.1064C24.6608 79.1064 24.4404 79.3268 24.4404 79.6022L24.4404 86.8314C24.4404 87.1068 24.6332 87.3271 24.9086 87.3271C25.184 87.3271 25.4043 87.1343 25.4043 86.8589V86.8314L25.4043 80.0703L32.3306 80.0703C32.606 80.0703 32.7987 79.85 32.7987 79.5746C32.7987 79.2992 32.5784 79.1064 32.3306 79.1064Z"
        fill="#202020"
      />
      <path
        d="M66.4946 129.738L73.889 129.738C74.1644 129.738 74.3848 129.518 74.3848 129.243L74.3848 122.013C74.3848 121.738 74.192 121.518 73.9166 121.518C73.6412 121.518 73.4209 121.71 73.4209 121.986L73.4209 122.013L73.4209 128.774L66.4946 128.774C66.2192 128.774 66.0265 128.995 66.0265 129.27C66.0265 129.546 66.2468 129.738 66.4946 129.738Z"
        fill="#202020"
      />
      <path
        d="M74.2466 86.9966L74.2466 79.6022C74.2466 79.3268 74.0263 79.1065 73.7509 79.1065L66.5217 79.1064C66.2463 79.1064 66.0259 79.2992 66.0259 79.5746C66.0259 79.85 66.2187 80.0703 66.4941 80.0703L66.5217 80.0703L73.2827 80.0703L73.2827 86.9966C73.2827 87.272 73.503 87.4647 73.7784 87.4647C74.0538 87.4647 74.2466 87.2444 74.2466 86.9966Z"
        fill="#202020"
      />
      <path
        d="M30.3213 85.2407V96.4785H41.559V85.2407H30.3213ZM38.3485 93.268H33.5323V88.4517H38.3485V93.268Z"
        fill="black"
      />
      <path
        d="M30.3213 112.533V123.771H41.559V112.533H30.3213ZM38.3485 120.56H33.5323V115.744H38.3485V120.56Z"
        fill="black"
      />
      <path
        d="M57.6133 85.2407V96.4785H68.851V85.2407H57.6133ZM65.64 93.268H60.8238V88.4517H65.64V93.268Z"
        fill="black"
      />
      <path
        d="M65.64 99.6899V109.322H57.6133V112.533H68.851V99.6899H65.64Z"
        fill="black"
      />
      <path
        d="M57.6133 115.744V123.771H60.8238V118.955H65.64V123.771H68.851V115.744H57.6133Z"
        fill="black"
      />
      <path
        d="M44.7705 85.2407V88.4517H51.192V96.4785H54.403V85.2407H44.7705Z"
        fill="black"
      />
      <path
        d="M51.192 99.6899V106.112H44.7705V115.744H51.192V123.771H54.403V112.533H47.9815V109.322H54.403V102.901H57.614V106.112H60.8245V99.6899H51.192Z"
        fill="black"
      />
      <path
        d="M47.9815 118.955H44.7705V123.771H47.9815V118.955Z"
        fill="black"
      />
      <path
        d="M41.5594 106.112H36.7432V109.323H41.5594V106.112Z"
        fill="black"
      />
      <path
        d="M44.77 91.6626V99.6898H30.3213L30.3213 109.322H33.5323V102.901H47.981V91.6626H44.77Z"
        fill="black"
      />
    </svg>
  );
};
