type Props = {
  size: string;
};

export const FeatureStamp: React.FC<Props> = ({ size }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill="white" />
      <path
        d="M53.3988 35.1201H106.255C110.347 35.1201 113.654 38.4271 113.654 42.5189V163.253C113.654 168.354 109.506 172.502 104.405 172.502H55.2485C50.1478 172.502 46 168.354 46 163.253V42.5189C46 38.4271 49.307 35.1201 53.3988 35.1201Z"
        fill="#202020"
      />
      <path
        d="M55.0245 36.5215H105.106C109.198 36.5215 112.505 39.8285 112.505 43.9203V161.516C112.505 166.617 108.357 170.764 103.257 170.764H56.8742C51.7735 170.764 47.6257 166.617 47.6257 161.516V43.9203C47.6257 39.8565 50.9328 36.5215 55.0245 36.5215Z"
        fill="#1E2227"
      />
      <path
        d="M55.0243 36.5213V37.0818H105.106C108.89 37.0818 111.972 40.1646 111.972 43.9481V161.516C111.972 166.336 108.077 170.232 103.256 170.232H56.874C52.0536 170.232 48.158 166.336 48.158 161.516V43.92C48.158 40.1366 51.2408 37.0818 55.0243 37.0818V35.9888C50.6243 35.9888 47.093 39.548 47.093 43.92V161.516C47.093 166.925 51.465 171.297 56.874 171.297H103.256C108.665 171.297 113.037 166.925 113.037 161.516V43.92C113.037 39.52 109.478 35.9888 105.106 35.9888H55.0243V36.5213Z"
        fill="#202020"
      />
      <path
        d="M104.293 38.9316H83.1619C82.5733 38.9316 82.1249 39.4081 82.1249 39.9686V41.3979C82.1249 42.6591 81.116 43.668 80.1351 43.668H79.8829C78.6217 43.668 77.6128 42.6591 77.6128 41.3979V39.9686C77.6128 39.3801 77.1363 38.9316 76.5758 38.9316H55.8368C52.5298 38.9316 49.8394 41.6221 49.8394 44.9291V161.516C49.8394 165.383 52.9782 168.522 56.8458 168.522H103.284C107.152 168.522 110.291 165.383 110.291 161.516V44.9291C110.291 41.6221 107.6 38.9316 104.293 38.9316Z"
        fill="#FB831B"
      />
      <path
        d="M104.293 38.9316H83.1619C82.5733 38.9316 82.1249 39.4081 82.1249 39.9686V41.3979C82.1249 42.6591 81.116 43.668 79.8548 43.668H79.8829C78.6217 43.668 77.6128 42.6591 77.6128 41.3979V39.9686C77.6128 39.3801 77.1363 38.9316 76.5758 38.9316H55.8368C52.5298 38.9316 49.8394 41.6221 49.8394 44.9291V56.5598H110.291V44.9291C110.291 41.6221 107.6 38.9316 104.293 38.9316Z"
        fill="#FB831B"
      />
      <path
        d="M110.401 156.144H49.7988V52.3057H110.401V156.144Z"
        fill="#FFF7E7"
      />
      <rect
        x="99.9941"
        y="63.7979"
        width="77.0543"
        height="40.3311"
        rx="5"
        transform="rotate(90 99.9941 63.7979)"
        fill="#D9D9D9"
        stroke="#202020"
        strokeWidth="2"
      />
      <circle
        cx="88.9268"
        cy="77.0363"
        r="3.83127"
        transform="rotate(90 88.9268 77.0363)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="88.8206"
        cy="127.327"
        r="3.83127"
        transform="rotate(90 88.8206 127.327)"
        stroke="#1E2227"
        strokeWidth="2"
      />
      <circle
        cx="71.0174"
        cy="102.181"
        r="3.83127"
        transform="rotate(90 71.0174 102.181)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="71.0174"
        cy="89.5183"
        r="3.83127"
        transform="rotate(90 71.0174 89.5183)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="71.0174"
        cy="77.0363"
        r="3.83127"
        transform="rotate(90 71.0174 77.0363)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="70.9109"
        cy="127.508"
        r="3.83127"
        transform="rotate(90 70.9109 127.508)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="88.9265"
        cy="102.181"
        r="3.83127"
        transform="rotate(90 88.9265 102.181)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="71.0174"
        cy="114.844"
        r="3.83127"
        transform="rotate(90 71.0174 114.844)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="88.9265"
        cy="114.844"
        r="3.83127"
        transform="rotate(90 88.9265 114.844)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="88.9265"
        cy="89.5183"
        r="3.83127"
        transform="rotate(90 88.9265 89.5183)"
        fill="#FBAF1B"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M153.986 39.6962C158.531 42.0578 161.814 45.7984 163.437 49.9438C165.059 54.0893 165.021 58.6397 162.957 62.6124C161.605 65.2123 158.682 69.2672 155.264 74.0421C150.501 80.7071 146.268 89.576 142.592 96.6478L141.76 98.2493L139.928 97.2973L114.412 84.0363L112.604 83.0966L113.436 81.495C117.111 74.4232 122.404 66.1049 125.121 58.3766C127.064 52.835 128.713 48.0917 130.054 45.5126C132.118 41.5399 135.82 38.8936 140.145 37.8392C144.494 36.7972 149.442 37.3346 153.986 39.6962Z"
        fill="#FB831B"
      />
      <path
        d="M153.986 39.6962C158.531 42.0578 161.814 45.7984 163.437 49.9438C165.059 54.0893 165.021 58.6397 162.957 62.6124C161.605 65.2123 158.682 69.2672 155.264 74.0421C150.501 80.7071 146.268 89.576 142.592 96.6478L141.76 98.2493L139.928 97.2973L114.412 84.0363L112.604 83.0966L113.436 81.495C117.111 74.4232 122.404 66.1049 125.121 58.3766C127.064 52.835 128.713 48.0917 130.054 45.5126C132.118 41.5399 135.82 38.8936 140.145 37.8392C144.494 36.7972 149.442 37.3346 153.986 39.6962ZM159.665 50.8633C158.406 47.6464 155.843 44.7292 152.322 42.8993C148.801 41.0694 144.94 40.6482 141.584 41.4664C138.228 42.2846 135.341 44.3507 133.741 47.429C132.487 49.8418 130.868 54.4687 128.955 59.8939C126.418 67.1607 121.354 74.8056 117.897 81.806L139.798 93.1876C143.564 86.348 147.377 78.0536 151.842 71.7884C155.183 67.105 158.039 63.121 159.293 60.7083C160.893 57.6299 160.924 54.0802 159.665 50.8633Z"
        fill="#202020"
      />
      <path
        d="M83.7114 109.677C83.6534 110.014 130.382 134.054 136.145 136.927C141.909 139.8 144.33 136.042 144.33 136.042C144.33 136.042 145.523 133.995 151.924 121.904C157.095 110.152 148.933 100.634 145.66 98.8249C141.932 96.765 124.02 87.0892 111.572 80.8644C97.1983 74.7405 88.4375 87.3188 88.4375 87.3188C88.4375 87.3188 86.8646 90.3452 84.5792 94.736C79.5249 104.447 84.4398 95.3685 81.7214 100.444C77.7738 107.815 83.7695 109.34 83.7114 109.677Z"
        fill="#FBAF1B"
        stroke="#202020"
        strokeWidth="4"
      />
    </svg>
  );
};
