import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Button,
  Center,
  Divider,
  Heading,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { DataContext } from "../lib/dataContext";
import { useContext, useEffect, useState } from "react";
import { SettingsCatalog } from "./SettingsCatalog";
import { SettingsLocation } from "./SettingsLocation";
import { SettingsMerchant } from "./SettingsMerchant";
import { motion } from "framer-motion";
import { FaLocationArrow } from "react-icons/fa";

export const Settings = () => {
  const navigate = useNavigate();
  const { merchant } = useContext(DataContext);
  let [searchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const index = searchParams.get("index");
    if (index) {
      if (index === "location") setTabIndex(1);
      if (index === "catalog" && merchant && merchant.merchant_id)
        setTabIndex(2);
      navigate("./");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, tabIndex]);

  return (
    <Flex id="SettingsContainer" flexDirection={"column"}>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        maxW={"600"}
        minW={"400"}
      >
        <Button
          onClick={() => navigate("../")}
          leftIcon={<ArrowBackIcon />}
          variant="ghost"
          width={"fit-content"}
          marginBottom={"5"}
        >
          Back
        </Button>
      </Flex>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
        }}
      >
        <Center marginBottom={"5"}>
          <Heading>Settings</Heading>
        </Center>
        <Divider orientation="horizontal" />
      </motion.div>
      <Tabs
        position="relative"
        variant={{base:"line", sm:"line"}}
        colorScheme={"orange"}
        paddingTop={"10"}
        isLazy
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList height={"50px"}>
          <Tab aria-label="General Settings">
            <InfoOutlineIcon mr={{ base: "0", md: "2" }} />
            <Text hideBelow={"md"}>General</Text>
          </Tab>
          <Tab aria-label="Locations">
            <Icon as={FaLocationArrow} mr={{ base: "0", md: "2" }} />
            <Text hideBelow={"md"}>Locations</Text>
          </Tab>
          <Tooltip
            label={
              !!!merchant?.merchant_id ? "Requires Square Integration" : ""
            }
            placement={"top"}
          >
            <Tab isDisabled={!!!merchant?.merchant_id}>
              <InfoOutlineIcon mr={{ base: "0", md: "2" }} />
              <Text hideBelow={"md"}>Catalog</Text>
            </Tab>
          </Tooltip>
        </TabList>
        {/* <TabIndicator
          mt="2.5px"
          height="2px"
          bg="orange.500"
          borderRadius="1px"
        /> */}
        <TabPanels pt={"5"}>
          <TabPanel padding={{ base: "0", sm: "4" }}>
            <SettingsMerchant />
          </TabPanel>
          <TabPanel padding={{ base: "0", sm: "4" }}>
            <SettingsLocation />
          </TabPanel>
          <TabPanel padding={{ base: "0", sm: "4" }}>
            <SettingsCatalog />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Outlet />
    </Flex>
  );
};
