import { useContext, useEffect, useState } from "react";
import { useLoaderData, useMatch, useNavigate } from "react-router-dom";
import { DataContext } from "../lib/dataContext";
import { AIContext } from "../lib/aiContext";
import { callFunction } from "../controllers/apiClient";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  CardHeader,
  Heading,
  CardFooter,
  Button,
  Select,
  Card as ChakraCard,
  Text,
  Center,
  Avatar,
  CardBody,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { Merchant, Redeem } from "../lib/constants";
import ErrorPage from "./ErrorPage";
import localforage from "localforage";

export async function loader({ params }: any) {
  try {
    const redeemId = params.redeemId;
    const merchantResponse = await callFunction(
      "merchant",
      "get",
      undefined,
      undefined
    );
    if (merchantResponse) {
      const redeemResponse = await callFunction("redeem", "get", undefined, {
        redeem_id: redeemId,
        merchant_id: merchantResponse.id,
      });
      return { merchantResponse, redeemResponse };
    } else {
      throw new Error("Could not fetch Merchant data");
    }
  } catch (err) {
    throw new Error("Could not fetch Merchant data");
  }
}

const RedeemHeading = "Customer Redemption";
const RedeemDescription = "Are they at this location?";

export const MerchantRedeemAccept = () => {
  const { trackEvent } = useContext(AIContext);
  const { user, userInfo } = useContext(DataContext);
  const [isRedeeming, setIsRedeeming] = useState<boolean>(false);
  const { merchantResponse, redeemResponse } = useLoaderData() as any;
  const merchant = merchantResponse as Merchant;
  const redeem = redeemResponse as Redeem;
  const [locationId, setLocationId] = useState<string>();
  const navigate = useNavigate();
  const isAtRedeem = useMatch("/merchant/redeem/:redeemId");
  const isRedeemRoot = useMatch("/merchant/redeem/");
  const toast = useToast();

  useEffect(() => {
    if (isRedeemRoot && userInfo && user && user.id) {
      navigate(`/merchant/`);
    }
  }, [isRedeemRoot, user, userInfo, navigate]);

  useEffect(() => {
    localforage.removeItem("redirect");
  }, []);

  useEffect(() => {
    if (
      merchant &&
      merchant.subscription_locations &&
      merchant.subscription_locations.filter((l) => l.active).length > 0
    ) {
      setLocationId(
        merchant.subscription_locations.filter((l) => l.active)[0].id
      );
    }
  }, [merchant, redeem]);

  if (
    (merchant &&
      merchant.subscription_locations &&
      merchant.subscription_locations.filter((l) => l.active).length < 1) ||
    !redeem
  )
    return (
      <ErrorPage
        errorMessage="No active subscription locations"
        errorLocation="MerchantRedeemAccept"
      />
    );

  const handleRedeem = async () => {
    try {
      setIsRedeeming(true);
      if (redeem && locationId) {
        const res = await callFunction("redeem", "put", {
          redeem_id: redeem.id,
          location_id: locationId,
          merchant_id: merchant.id,
          card_id: redeem.card_id,
        });
        if (res && res.redeemed_at) {
          toast({
            title: "Success",
            description: "You have accepted the free item.",
            position: "top",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          navigate(`/merchant/${merchant.id}/location/${locationId}`);
        } else {
          throw new Error("Could not redeem.");
        }
      } else {
        throw new Error("Could not find the redemption.");
      }
    } catch (e) {
      console.log(e);
      const msg = e as string;
      toast({
        title: "Error",
        description: msg.toString(),
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "MerchantRedeemAccept",
          error: e,
        },
      });
    } finally {
      setIsRedeeming(false);
    }
  };
  return (
    <Flex
      id="RedeemContainer"
      width={[
        "90%", // 0-30em      // 480px
        "90%", // 30em-48em   // 768px
        "75%", // 48em-62em   // 992px
        "75%", // 62em-80em   // 1280px
        "75%", // 80em-96em+  // 1536px
      ]}
      maxW={"900"}
    >
      {isAtRedeem && (
        <Center flexDirection={"column"} height={"100%"} width={"100%"}>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Button
              onClick={() => navigate("../")}
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              width={"fit-content"}
              marginBottom={"5"}
            >
              Back
            </Button>
          </Flex>
          <Heading marginBottom={"5"} textAlign={"center"}>
            {RedeemHeading}
          </Heading>
          <Divider orientation="horizontal" mb={"5"} />
          <div style={{ width: "100%" }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <Flex flexDirection={"column"}>
                <ChakraCard
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  my={"5"}
                  mx={"auto"}
                  size={"sm"}
                  minW={"300px"}
                  width={"100%"}
                  maxW={"640px"}
                  bgGradient="linear(to-r, white, orange.50)"
                  borderBottom={"8px aqua"}
                >
                  <Stack width={"100%"}>
                    <CardHeader display={"flex"} flexDirection={"row"}>
                      <Avatar
                        name={redeem.user.user_name}
                        color="white"
                        size="lg"
                      />
                      <Heading size="md" my="auto" ml="5">
                        {redeem.user.user_name}
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <AnimatePresence>
                        <motion.div>
                          <Center flexDirection={"column"}>
                            <motion.div
                              id="customerClaim"
                              initial={{ opacity: 0, x: 0 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0 }}
                              transition={{
                                duration: 0.3,
                                delay: 0.3,
                              }}
                            >
                              <Text textAlign={"center"}>
                                {RedeemDescription}
                              </Text>
                              {merchant &&
                                merchant.subscription_locations &&
                                merchant.subscription_locations.filter(
                                  (l) => l.active
                                ).length > 0 && (
                                  <>
                                    {merchant.subscription_locations.filter(
                                      (l) => l.active
                                    ).length === 1 && (
                                      <Text
                                        fontSize={"xl"}
                                        textAlign={"center"}
                                      >
                                        <b>
                                          {
                                            merchant.subscription_locations.filter(
                                              (l) => l.active
                                            )[0].name
                                          }
                                        </b>
                                      </Text>
                                    )}
                                    {merchant.subscription_locations.filter(
                                      (l) => l.active
                                    ).length > 1 && (
                                      <Select
                                        size="lg"
                                        variant="flushed"
                                        onChange={(e) =>
                                          setLocationId(e.target.value)
                                        }
                                      >
                                        {merchant.subscription_locations
                                          .filter((l) => l.active)
                                          .map((l) => (
                                            <option key={l.id} value={l.id}>
                                              {l.name}
                                            </option>
                                          ))}
                                      </Select>
                                    )}
                                  </>
                                )}
                            </motion.div>
                          </Center>
                        </motion.div>
                      </AnimatePresence>
                    </CardBody>
                    <CardFooter>
                      <Flex justifyContent={"space-evenly"} width={"100%"}>
                        <Button
                          variant={"solid"}
                          color="white"
                          width={"60%"}
                          bgGradient="linear(to-r, green.500, yellow.500)"
                          _hover={{
                            color: "white",
                            bgGradient: "linear(to-r, yellow.500, red.500,)",
                          }}
                          isLoading={isRedeeming}
                          onClick={handleRedeem}
                        >
                          Yes they are!
                        </Button>
                      </Flex>
                    </CardFooter>
                  </Stack>
                </ChakraCard>
              </Flex>
            </motion.div>
          </div>
        </Center>
      )}
    </Flex>
  );
};
