import { useContext, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { DataContext } from "../lib/dataContext";
import { Location } from "../lib/constants";
import { callFunction } from "../controllers/apiClient";
import { v4 as uuidv4 } from "uuid";


const formHelper = "Please enter something between 3 and 30 characters.";

export const SettingsLocationAdd = (props: {
  reload: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const { merchant, setMerchant } = useContext(DataContext);
  const [locationName, setLocationName] = useState("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [notValid, setNotValid] = useState<string>("");
  const toast = useToast();

  if (!merchant) {
    return <Skeleton height="40px" />;
  }

  const checkValid = (val: string) => {
    if (val === "" || val === undefined || val.length < 3 || val.length > 30) {
      return false;
    } else {
      return true;
    }
  };
  const handleChange = (event: any) => {
    const val = event.target.value;
    if (!checkValid(val)) {
      setNotValid(formHelper);
    } else {
      setNotValid("");
    }
    setLocationName(event.target.value);
  };

  const handleAddLocation = async () => {
    try {
      if (!checkValid(locationName)) {
        setNotValid(formHelper);
        return;
      }
      if (merchant.subscription_locations) {
        setIsSaving(true);
        const sl = [...merchant.subscription_locations];
        const nl: Location = {
          id: uuidv4(),
          name: locationName,
          active: false,
          manual: true,
        };
        sl.push(nl);
        const m = await callFunction("merchant", "put", {
          type: "location",
          merchant_id: merchant.id,
          catalog_items: merchant.catalog_items,
          subscription_locations: sl.sort((a, b) => a.id.localeCompare(b.id)),
        });
        if (m) {
          setMerchant(m);
          toast({
            title: "Success",
            description: "Settings Updated.",
            position: "top",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        setNotValid("");
        props.reload();
        props.onClose();
      }
    } catch (e) {
      setNotValid("");
      console.log("Error", e);
      toast({
        title: "Error",
        description: `${e}`,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Location</ModalHeader>

        <ModalCloseButton onClick={props.onClose} />
        <ModalBody pb={6}>
          <FormControl isInvalid={notValid !== ""}>
            <FormLabel>Location Name</FormLabel>
            <Input
              variant="outline"
              colorScheme={"orange"}
              focusBorderColor={"orange.500"}
              size="lg"
              onChange={handleChange}
            />
            {notValid !== "" ? (
              <FormErrorMessage>{notValid}</FormErrorMessage>
            ) : (
              <FormHelperText>{formHelper}</FormHelperText>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isSaving}
            disabled={!merchant || notValid !== ""}
            onClick={handleAddLocation}
            type="button"
            colorScheme="orange"
            mr={3}
          >
            Ok
          </Button>
          <Button onClick={props.onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
