import { useContext, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import { AIContext } from "../lib/aiContext";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  SimpleGrid,
  Skeleton,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import localforage from "localforage";
import { FaSave } from "react-icons/fa";
import { TbRubberStamp, TbRubberStampOff } from "react-icons/tb";
import { CatalogObject } from "../lib/constants";
import { useData } from "../lib/hooks";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import ErrorPage from "./ErrorPage";
import { SquareAPIUrl as url } from "../lib/constants";

export const SettingsCatalog = () => {
  const { trackEvent } = useContext(AIContext);
  const { merchant, setMerchant, user, userInfo } = useContext(DataContext);
  const [loyaltyObjects, setLoyaltyObjects] = useState<
    CatalogObject[] | undefined
  >(merchant?.catalog_items ? [...merchant?.catalog_items] : []);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSettingUpSquare, setIsSettingUpSquare] = useState<boolean>(false);
  const [noMerchant, setNoMerchant] = useState<boolean>(merchant === undefined);
  const toast = useToast();

  const [data, error, loading, reload] = useData(async () => {
    try {
      if (merchant) {
        const lastRefresh = (await localforage.getItem(
          "lastRefresh_catalog"
        )) as string;
        let catalogObjects = (await localforage.getItem(
          `${merchant.id}_catalog`
        )) as CatalogObject[];
        if (
          !catalogObjects ||
          !lastRefresh ||
          dayjs(lastRefresh) < dayjs().add(-5, "minute")
        ) {
          const objects: CatalogObject[] = await callFunction(
            "square/catalog",
            "get"
          );
          if (objects) {
            // setCatalogObjects(objects as CatalogObject[]);
            localforage.setItem(`${merchant.id}_catalog`, objects);
            localforage.setItem("lastRefresh_catalog", new Date());
            return { catalogObjects: objects };
          }
        } else if (catalogObjects) {
          return { catalogObjects };
        }
      }
    } catch (err) {
      const msg = err as any;
      console.log(msg);
      throw new Error(msg);
    }
  });

  useEffect(() => {
    if (merchant !== undefined && noMerchant && merchant?.catalog_items) {
      setNoMerchant(false);
      setLoyaltyObjects([...merchant.catalog_items]);
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant]);

  if (error) {
    console.log("error", error);
    if (error.toString().indexOf("This request could not be authorized") > -1) {
      return (
        <Flex
          flexDirection={"column"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <ErrorPage
            errorMessage="Square authorization failed, need to re-auth"
            errorLocation="Settings"
          />
          <Button
            loadingText="Re-connecting to Square..."
            isLoading={isSettingUpSquare}
            colorScheme="orange"
            variant="outline"
            onClick={() => {
              setIsSettingUpSquare(true);
              localforage.removeItem("lastRefresh_locations");
              localforage.removeItem("lastRefresh_catalog");
              window.open(url, "_self")?.focus();
              //navigate("../../../");
            }}
            mt={"5"}
          >
            Please Re-authorize Square
          </Button>
        </Flex>
      );
    } else {
      return <ErrorPage errorMessage={`${error}`} errorLocation="Settings" />;
    }
  }

  if (!merchant) {
    return <Skeleton height="40px" />;
  }

  const handleToggleAddToCatalog = async (id: string) => {
    if (data.catalogObjects && loyaltyObjects) {
      const i = loyaltyObjects?.findIndex((o) => o.id === id);
      const j = data.catalogObjects?.findIndex(
        (o: CatalogObject) => o.id === id
      );
      let newLoyaltyObject;
      if (i > -1) {
        newLoyaltyObject = [...loyaltyObjects];
        newLoyaltyObject.splice(i, 1);
      } else {
        newLoyaltyObject = [...loyaltyObjects];
        newLoyaltyObject.push(data.catalogObjects[j]);
      }
      setLoyaltyObjects(
        newLoyaltyObject.sort((a, b) => a.id.localeCompare(b.id))
      );
    }
  };

  const handleSaveCatalogObjects = async () => {
    try {
      setIsSaving(true);
      const m = await callFunction("merchant", "put", {
        type: "catalog",
        merchant_id: merchant.id,
        catalog_items: loyaltyObjects,
        subscription_locations: merchant.subscription_locations,
      });
      if (m) {
        setMerchant(m);
        toast({
          title: "Success",
          description: "Settings Updated.",
          position: "top",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await localforage.setItem(m.id, m);
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: `${e}`,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "Location",
          error: e,
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: 10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{
          duration: 0.5,
        }}
      >
        <Flex direction="column">
          <Skeleton isLoaded={!loading} rounded="md">
            <Flex
              flexDirection={{ base: "column", sm: "row" }}
              justifyContent={"space-between"}
              minHeight={"40px"}
            >
              <Center>
                <Text fontSize="xl" color="gray.500">
                  {loyaltyObjects
                    ? `${loyaltyObjects.length} item(s) selected`
                    : `Please select redeemable items from your catalog.`}
                </Text>
                <Tooltip
                  placement="top"
                  label={`Choose which items can accrue punches.`}
                >
                  <QuestionOutlineIcon ml="3" boxSize="5" color={"gray.500"} />
                </Tooltip>
              </Center>

              {!isEqual(merchant.catalog_items, loyaltyObjects) && (
                <motion.div
                  initial={{
                    opacity: 0,
                    x: 20,
                    position: "absolute",
                    top: "40px",
                    right: "1em",
                  }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{
                    duration: 0.2,
                  }}
                >
                  <Flex
                    display={"flex"}
                    justifyContent={{ base: "center", sm: "start" }}
                    marginTop={{ base: "0px", sm: "0px" }}
                  >
                    <Button
                      key={"btnSaveCatalog"}
                      isLoading={isSaving}
                      loadingText="Saving..."
                      leftIcon={<FaSave />}
                      colorScheme="orange"
                      variant="outline"
                      onClick={handleSaveCatalogObjects}
                      hideBelow="sm"
                    >
                      Save
                    </Button>
                    <Button
                      key={"btnSmlSaveCatalog"}
                      isLoading={isSaving}
                      colorScheme="orange"
                      variant="outline"
                      onClick={handleSaveCatalogObjects}
                      hideFrom="sm"
                    >
                      <FaSave />
                    </Button>
                  </Flex>
                </motion.div>
              )}
            </Flex>
          </Skeleton>

          {!loading ? (
            <SimpleGrid minChildWidth="180px" spacing="5px" mt={"5"}>
              {data &&
                data.catalogObjects &&
                data.catalogObjects.map((o: CatalogObject) => {
                  const flag =
                    loyaltyObjects &&
                    loyaltyObjects.findIndex((i) => o.id === i.id) > -1;
                  return (
                    <Box key={o.id as React.Key} id={o.id} padding="2">
                      <Center
                        h={{ base: "4em", sm: "4em", md: "5em" }}
                        bg={flag ? "orange.500" : ""}
                        boxShadow={flag ? "base" : ""}
                        outline={flag ? "none" : "1px solid"}
                        outlineColor={flag ? "none" : "gray.200"}
                        rounded="md"
                        onClick={() => handleToggleAddToCatalog(o.id)}
                        cursor={"pointer"}
                        pos={"relative"}
                      >
                        <Text
                          color={flag ? "white" : "gray.500"}
                          textAlign={"center"}
                        >
                          {o.name}{" "}
                        </Text>
                        {flag ? (
                          <Icon
                            as={TbRubberStamp}
                            color="white"
                            pos={"absolute"}
                            top={"5px"}
                            left={"5px"}
                          />
                        ) : (
                          <Icon
                            as={TbRubberStampOff}
                            color="gray.500"
                            pos={"absolute"}
                            top={"5px"}
                            left={"5px"}
                          />
                        )}
                      </Center>
                    </Box>
                  );
                })}
            </SimpleGrid>
          ) : (
            <SimpleGrid minChildWidth="180px" spacing="5px" mt={"5"}>
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <Box key={`${i}`} id={`${i}`} padding="2">
                  <Skeleton
                    h={{ base: "4em", sm: "4em", md: "5em" }}
                    rounded="md"
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Flex>
      </motion.div>
    </>
  );
};
