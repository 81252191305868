// import { useRef, useState } from "react";
import {
  redirect,
  useParams,
  // useSubmit,
  // Form,
  // useSearchParams,
  // useNavigate,
} from "react-router-dom";
import { callFunction } from "../controllers/apiClient";
import { useContext } from "react";
// import { User } from "../lib/constants";
import {
  Box,
  Button,
  SkeletonText,
  Heading,
  Container,
  // useColorModeValue,
  // SkeletonCircle,
  // Text,
  // Flex,
  // Card,
  // Icon,
  // List,
  // ListIcon,
  // ListItem,
  // VStack,
  // SimpleGrid,
} from "@chakra-ui/react";
import { DataContext } from "../lib/dataContext";
import { ArrowForwardIcon } from "@chakra-ui/icons";
// import { RiCupFill } from "react-icons/ri";
// import { HiCheckCircle } from "react-icons/hi";
// import { rest } from "lodash";

const ContactHeading = "Contact us to sign-up";
const ContactCallToAction =
  "We are currently in an closed beta, looking for early adopters and feedback.";

export async function action({ request, params }: any) {
  const formData = await request.formData();
  const type = formData.get("session_type");
  if (type === "checkout") {
    const user_id = formData.get("user_id");
    const price_id = formData.get("price_id");
    const result = await callFunction("stripe/portal", "post", {
      user_id,
      price_id,
      type: "checkout",
      cancel_url: `customer/${user_id}`,
    });
    if (result.url) {
      return redirect(result.url);
    } else {
      return redirect("/");
    }
  } else if (type === "portal") {
    const customer_id = formData.get("customer_id");
    const user_id = formData.get("user_id");
    const result = await callFunction("stripe/portal", "post", {
      customer_id,
      type: "portal",
      return_url: `customer/${user_id}`,
    });
    if (result.url) {
      return redirect(result.url);
    } else {
      return redirect("/");
    }
  } else {
    throw new Error("No cardId provided to claim against");
  }
}

// const features = [
//   "Unlimited customers",
//   "Custom Logo",
//   "Square Integration",
//   "More coming soon...",
// ];

export const Checkout = () => {
  const { user } = useContext(DataContext);
  const { userId } = useParams();
  // const bgValue = useColorModeValue("white", "gray.700");
  // const [isLoading, setIsLoading] = useState(false);
  // const submit = useSubmit();
  // let formRef = useRef<HTMLFormElement>(null!);
  if (
    userId &&
    user &&
    (userId === "new" || userId === user.id) &&
    !user.subscription_id
  ) {
    return (
      <>
        {user && !user.subscription_id && (
          <Container
          className="content"
          maxW="container.lg"
          minWidth={"-webkit-fill-available"}
          height="500px"
          centerContent
        >
          <Box
            id="pricing"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            px={{ base: "4", md: "8" }}
            width="full"
            height="full"
          >
            <Heading
              size={"sm"}
              color={"orange.500"}
              textAlign={"center"}
            >
              {ContactHeading}
            </Heading>
            <Heading as={"h2"} mt={"5"} textAlign={"center"}>
              {ContactCallToAction}
            </Heading>
            <Button
              type="button"
              rightIcon={<ArrowForwardIcon />}
              colorScheme="orange"
              variant="outline"
              _hover={{
                color: "white",
                bgGradient: "linear(to-r, red.500, yellow.500)",
              }}
              size="lg"
              mt={"10"}
              onClick={(e) => {
                window.location.href = "mailto:contact@kanvent.com";
                e.preventDefault();
              }}
            >
              I want to get involved!
            </Button>
          </Box>
        </Container>
          // <Box as="section" py="14" px={{ base: "4", md: "8" }} width="full">
          //   <Box
          //     bg={bgValue}
          //     position="relative"
          //     px="6"
          //     pb="6"
          //     pt="16"
          //     overflow="hidden"
          //     shadow="lg"
          //     maxW="sm"
          //     minW="2xs"
          //     margin="auto"
          //     width="100%"
          //     rounded={{ sm: "xl" }}
          //     {...rest}
          //   >
          //     <Flex
          //       bg={"orange.300"}
          //       position="absolute"
          //       right={-20}
          //       top={6}
          //       width="240px"
          //       transform="rotate(45deg)"
          //       py={2}
          //       justifyContent="center"
          //       alignItems="center"
          //     >
          //       <Text
          //         fontSize="xs"
          //         textTransform="uppercase"
          //         fontWeight="bold"
          //         letterSpacing="wider"
          //         color={"white"}
          //       >
          //         Per Location
          //       </Text>
          //     </Flex>
          //     <VStack spacing={6}>
          //       <Icon
          //         aria-hidden
          //         as={RiCupFill}
          //         fontSize="4xl"
          //         color={"orange.500"}
          //       />
          //       <Heading size="md">My Shop</Heading>
          //     </VStack>
          //     <Flex
          //       align="flex-end"
          //       justify="center"
          //       color={"orange.500"}
          //       my="8"
          //     >
          //       <Heading size="3xl" fontWeight="semibold" lineHeight="0.9em">
          //         $30
          //       </Heading>
          //       <Text fontWeight="semibold" fontSize="2xl">
          //         / m
          //       </Text>
          //     </Flex>
          //     <List spacing="4" mb="8" maxW="28ch" mx="auto">
          //       {features.map((feature, index) => (
          //         <ListItem fontWeight="medium" key={index}>
          //           <ListIcon
          //             fontSize="xl"
          //             as={HiCheckCircle}
          //             marginEnd={2}
          //             color={"orange.500"}
          //           />
          //           {feature}
          //         </ListItem>
          //       ))}
          //     </List>
          //     <Form method="post" ref={formRef}>
          //       <input
          //         type="hidden"
          //         name="price_id"
          //         value="price_1MvtiIFht5XCuRvsZLlvzZDp"
          //       />
          //       <input type="hidden" name="user_id" value={user.id} />
          //       <input type="hidden" name="session_type" value="checkout" />
          //       <Button
          //         type="button"
          //         isLoading={isLoading}
          //         onClick={() => {setIsLoading(true); submit(formRef.current);}}
          //         rightIcon={<ArrowForwardIcon />}
          //         colorScheme="orange"
          //         variant="solid"
          //         _hover={{
          //           color: "white",
          //           bgGradient: "linear(to-r, red.500, yellow.500)",
          //         }}
          //         size="lg"
          //         w="full"
          //       >
          //         Buy now
          //       </Button>
          //     </Form>
          //   </Box>
          // </Box>
        )}
      </>
    );
  } else {
    return (
      <Box padding="6" boxShadow="lg" bg="white" minW={"300px"}>
        {/* <SkeletonCircle size="10" /> */}
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
      </Box>
    );
  }
};
