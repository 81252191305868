import { useContext, useEffect, useState } from "react";
import {
  useRouteError,
  redirectDocument,
  useNavigation,
  isRouteErrorResponse,
} from "react-router-dom";
import { AIContext } from "../lib/aiContext";
import { DataContext } from "../lib/dataContext";
import {
  Box,
  Center,
  Code,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import localforage from "localforage";

interface ErrorPageProps {
  errorMessage?: string;
  errorLocation?: string;
}

export async function loader({ request }: any) {
  // This doesn't run
  if (process.env.NODE_ENV === "production") {
    try {
      const url = new URL(request.url);
      const hasCode = url.searchParams.has("code");
      const hasLoginRedirect = url.searchParams.has("post_login_redirect_uri");
      const hasLogoutRedirect = url.searchParams.has(
        "post_logout_redirect_uri"
      );
      const hasNoLogoutRedirect = url.href.includes(
        ".auth/logout/passage/callback"
      );
      const authComplete = url.href.includes("/.auth/complete");
      if (hasLogoutRedirect || hasNoLogoutRedirect)
        localforage.removeItem("userState");
      if (hasCode || hasLoginRedirect || hasLogoutRedirect || authComplete) {
        return redirectDocument(url.href);
      } else if (hasNoLogoutRedirect) {
        return redirectDocument(
          `${window.location.protocol}//${window.location.host}`
        );
      } else return;
    } catch (err) {
      throw new Error("Loader error");
    }
  }
}

export default function ErrorPage(props: ErrorPageProps) {
  // Context may not be defined if there is error at Root
  const error = useRouteError() as any;
  const navigation = useNavigation();
  const { trackEvent } = useContext(AIContext);
  const { user, userInfo } = useContext(DataContext);
  const [isRouteError, setIsRouteError] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (navigation.state === "loading") {
      onOpen();
    } else {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  useEffect(() => {
    //if (process.env.NODE_ENV !== "production") return;
    setIsRouteError(true);
    try {
      if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
          console.log("error status 404");
          const url = new URL(window.location.href);
          const hasCode = url.searchParams.has("code");
          const hasLoginRedirect = url.searchParams.has(
            "post_login_redirect_uri"
          );
          const hasLogoutRedirect = url.searchParams.has(
            "post_logout_redirect_uri"
          );
          const hasNoLogoutRedirect = url.href.includes(
            ".auth/logout/passage/callback"
          );
          const authComplete = url.href.includes("/.auth/complete");
          if (hasLogoutRedirect || hasNoLogoutRedirect) {
            localforage.removeItem("userState");
          }
          if (
            hasCode ||
            hasLoginRedirect ||
            hasLogoutRedirect ||
            authComplete
          ) {
            //console.log("auth complete");
            window.location.href = url.href;
          } else if (hasNoLogoutRedirect) {
            //console.log("hasNoLogoutRedirect", hasNoLogoutRedirect);
            window.location.href = `${window.location.protocol}//${window.location.host}`;
          } else {
            setIsRouteError(false);
            window.location.href = `${window.location.protocol}//${window.location.host}`;
          }
        }
        if (error.status === 401) {
          //console.log("error status 401");
          window.location.href = `${window.location.protocol}//${window.location.host}/login`;
        }
      } else {
        //console.log("Not a route error");
        setIsRouteError(false);
      }
    } catch (e) {
      //console.log(e);
      setIsRouteError(false);
      trackEvent({
        name: "Error",
        properties: {
          type: "catch",
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: props.errorLocation || "root",
          error: e,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, user, userInfo]);

  //console.log("isRouteError Flag", isRouteError);
  // console.log(
  //   "error flag",
  //   isRouteError ||
  //     isOpen ||
  //     (isRouteErrorResponse(error) &&
  //       (error.status === 401 || error.status === 404))
  // );

  return (
    <Container
      id="error-page"
      className="error-page"
      maxW="container.lg"
      minWidth={"-webkit-fill-available"}
      height="500px"
      centerContent
    >
      <Center>
        <Flex flexDirection={"column"}>
          {isRouteError ||
          isOpen ||
          (isRouteErrorResponse(error) &&
            (error.status === 401 || error.status === 404)) ? (
            <Box mt={"100px"}>
              <Spinner
                minH="80px"
                minW="80px"
                margin={"auto"}
                size={"xl"}
                color={"orange"}
                speed="0.65s"
                thickness="4px"
              />
            </Box>
          ) : (
            <Flex flexDirection={"column"}>
              <Heading size={"md"}>Oops!</Heading>
              <Text>Sorry, an unexpected error has occurred.</Text>
              <p>
                <Code
                  colorScheme="red"
                  children={
                    props.errorMessage ||
                    error?.message ||
                    error?.statusText ||
                    error
                  }
                />
              </p>
            </Flex>
          )}
        </Flex>
      </Center>
    </Container>
  );
}
