import { useContext, useEffect, useState, useRef } from "react";
import { isEqual } from "lodash";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import { AIContext } from "../lib/aiContext";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  SimpleGrid,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import localforage from "localforage";
import { FaSave } from "react-icons/fa";
import { CiLocationOn, CiLocationOff } from "react-icons/ci";
import { Location } from "../lib/constants";
import { useData } from "../lib/hooks";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import ErrorPage from "./ErrorPage";
import { SettingsLocationAdd } from "./SettingsLocationAdd";
import { SquareAPIUrl as url } from "../lib/constants";

export const SettingsLocation = () => {
  const { trackEvent } = useContext(AIContext);
  const { user, userInfo, merchant, setMerchant } = useContext(DataContext);
  const [noMerchant, setNoMerchant] = useState<boolean>(merchant === undefined);
  const [subscriptionLocations, setSubscriptionLocations] = useState<
    Location[] | undefined
  >(
    merchant?.subscription_locations
      ? [...merchant?.subscription_locations]
      : []
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isSettingUpSquare, setIsSettingUpSquare] = useState<boolean>(false);
  const addModalState = useDisclosure();
  const alertModalState = useDisclosure();
  const cancelRef = useRef<null | HTMLButtonElement>(null);
  const [deleteLocationID, setDeleteLocationID] = useState("");
  const toast = useToast();

  const [data, error, loading, reload] = useData(async (forced) => {
    try {
      if (merchant && merchant.id) {
        const lastRefresh = (await localforage.getItem(
          "lastRefresh_locations"
        )) as string;
        let locations = (await localforage.getItem(
          `${merchant.id}_locations`
        )) as Location[];
        if (
          !locations ||
          !lastRefresh ||
          dayjs(lastRefresh) < dayjs().add(-5, "minute") ||
          forced
        ) {
          const locationResponse: Location[] = await callFunction(
            "location",
            "get",
            undefined,
            {
              merchant_id: merchant.id,
            }
          );
          if (locationResponse) {
            const lr = [
              ...locationResponse.map((l) => ({
                ...l,
                active: false,
                manual: false,
              })),
            ];
            if (merchant.subscription_locations) {
              merchant.subscription_locations.forEach((l) => {
                if (l.manual) {
                  lr.push(l);
                } else {
                  const i = lr.findIndex((j) => l.id === j.id);
                  if (i > -1) {
                    lr[i].active = l.active;
                  }
                }
              });
            }
            localforage.setItem(`${merchant.id}_locations`, [...lr]);
            localforage.setItem("lastRefresh_locations", new Date());
            return { locations: [...lr] };
          }
        } else if (locations) {
          return { locations };
        }
      }
      // Not registered with Square load manual locations
      else if (merchant && merchant.subscription_locations) {
        return { locations: [...merchant.subscription_locations] };
      }
    } catch (err) {
      const msg = err as any;
      console.log(msg);
      throw new Error(msg);
    }
  });

  useEffect(() => {
    if (
      merchant !== undefined &&
      noMerchant &&
      merchant?.subscription_locations
    ) {
      setNoMerchant(false);
      setSubscriptionLocations([...merchant.subscription_locations]);
      reload();
    } else if (merchant && merchant.subscription_locations) {
      setSubscriptionLocations([...merchant.subscription_locations]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant]);

  if (error) {
    console.log("error", error);
    if (error.toString().indexOf("authoriz") > -1) {
      return (
        <Flex
          flexDirection={"column"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <ErrorPage
            errorMessage="Square authorization failed, need to re-auth"
            errorLocation="Settings"
          />
          <Button
            loadingText="Re-connecting to Square..."
            isLoading={isSettingUpSquare}
            colorScheme="orange"
            variant="outline"
            onClick={() => {
              setIsSettingUpSquare(true);
              localforage.removeItem("lastRefresh_locations");
              localforage.removeItem("lastRefresh_catalog");
              window.open(url, "_self")?.focus();
              //navigate("../../../");
            }}
            mt={"5"}
          >
            Please Re-authorize Square
          </Button>
        </Flex>
      );
    } else {
      return <ErrorPage errorMessage={`${error}`} errorLocation="Settings" />;
    }
  }

  if (!merchant) {
    return <Skeleton height="40px" />;
  }

  const handleToggleAddToSubscription = async (id: string) => {
    if (data.locations && subscriptionLocations) {
      const i = subscriptionLocations?.findIndex((o) => o.id === id);
      const j = data.locations?.findIndex((l: Location) => l.id === id);
      let newSubLocations;
      if (i > -1) {
        const item = { ...subscriptionLocations[i] };
        newSubLocations = [...subscriptionLocations];
        if (item.manual) {
          item.active = !item.active;
          newSubLocations[i] = item;
        } else {
          newSubLocations.splice(i, 1);
        }
      } else {
        newSubLocations = [...subscriptionLocations];
        newSubLocations.push({
          ...data.locations[j],
          active: true,
          manual: false,
        });
      }
      setSubscriptionLocations([
        ...newSubLocations.sort((a, b) => a.id.localeCompare(b.id)),
      ]);
    }
  };

  const handleSaveLocations = async () => {
    try {
      setIsSaving(true);
      const m = await callFunction("merchant", "put", {
        type: "location",
        merchant_id: merchant.id,
        catalog_items: merchant.catalog_items,
        subscription_locations: subscriptionLocations,
      });
      if (m) {
        setMerchant(m);
        toast({
          title: "Success",
          description: "Settings Updated.",
          position: "top",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await localforage.setItem(m.id, m);
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: `${e}`,
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      trackEvent({
        name: "ErrorPage",
        properties: {
          user: user || "no user",
          userInfo: userInfo || "no userInfo",
          location: "SettingsLocation",
          error: e,
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  const isChanged = (
    newList: Location[] | undefined,
    stateList: Location[] | undefined
  ) => {
    if (!newList && !stateList) return false; // both are undefined or empty, no change
    if (!newList || !stateList) return true; // one is undefined or empty, there is a change
    if (newList.length !== stateList.length) return true; // different length, there is a change

    // compare each element
    if (!isEqual(newList, stateList)) return true;

    return false; // no difference found
  };

  const canSave = isChanged(
    merchant.subscription_locations?.filter((l) => l.active),
    subscriptionLocations?.filter((l) => l.active)
  );

  const handleDeleteLocation = async () => {
    try {
      if (
        deleteLocationID !== "" &&
        merchant &&
        merchant.subscription_locations
      ) {
        const id = deleteLocationID;
        setIsDeleting(true);
        const i = merchant.subscription_locations.findIndex(
          (l: Location) => l.id === id
        );
        if (i > -1) {
          const item = merchant.subscription_locations[i];
          const newSubLocations = [
            ...merchant.subscription_locations,
          ] as Location[];
          if (item.manual) {
            newSubLocations.splice(i, 1);
            const m = await callFunction("merchant", "put", {
              type: "location",
              merchant_id: merchant.id,
              catalog_items: merchant.catalog_items,
              subscription_locations: newSubLocations.sort((a, b) =>
                a.id.localeCompare(b.id)
              ),
            });
            if (m) {
              setMerchant(m);
              await localforage.setItem(m.id, m);
              reload(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      const msg = err as any;
      throw new Error(msg);
    } finally {
      alertModalState.onClose();
      setIsDeleting(false);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: 10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{
          duration: 0.5,
        }}
      >
        <Flex direction="column">
          <Skeleton isLoaded={!loading} rounded="md">
            <Flex
              flexDirection={{ base: "column", sm: "row" }}
              justifyContent={"space-between"}
              minHeight={"40px"}
            >
              <Center>
                <Text fontSize="xl" color="gray.500">
                  {subscriptionLocations
                    ? `${
                        subscriptionLocations.filter((l) => l.active).length
                      } / ${user?.subscription_quantity} Active Locations`
                    : `Your subscription supports ${
                        user?.subscription_quantity
                      } ${
                        user?.subscription_quantity === 1
                          ? "location"
                          : "locations"
                      }`}
                </Text>
                <Tooltip
                  placement="top"
                  label={`Choose which locations you want to use`}
                >
                  <QuestionOutlineIcon ml="3" boxSize="5" color={"gray.500"} />
                </Tooltip>
                <Tooltip placement="top" label={`Add a location`}>
                  <Button
                    onClick={addModalState.onOpen}
                    variant={"outline"}
                    boxSize="2em"
                    ml={"1em"}
                  >
                    <AddIcon color={"gray.500"} />
                  </Button>
                </Tooltip>
              </Center>

              {canSave && (
                <motion.div
                  initial={{
                    opacity: 0,
                    x: 20,
                    position: "absolute",
                    top: "40px",
                    right: "1em",
                  }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{
                    duration: 0.2,
                  }}
                >
                  <Flex
                    display={"flex"}
                    justifyContent={{ base: "center", sm: "start" }}
                    marginTop={{ base: "0px", sm: "0px" }}
                  >
                    <Button
                      key={"btnSaveLocation"}
                      isLoading={isSaving}
                      loadingText="Saving..."
                      leftIcon={<FaSave />}
                      colorScheme="orange"
                      variant="outline"
                      onClick={handleSaveLocations}
                      hideBelow="sm"
                    >
                      Save
                    </Button>
                    <Button
                      key={"btnSmlSaveLocation"}
                      isLoading={isSaving}
                      colorScheme="orange"
                      variant="outline"
                      onClick={handleSaveLocations}
                      hideFrom="sm"
                    >
                      <FaSave />
                    </Button>
                  </Flex>
                </motion.div>
              )}
            </Flex>
          </Skeleton>
          {!loading ? (
            <SimpleGrid minChildWidth="180px" spacing="5px" mt={"5"}>
              {data &&
                data.locations &&
                data.locations.map((l: Location) => {
                  const flag =
                    subscriptionLocations &&
                    subscriptionLocations.findIndex(
                      (i) => l.id === i.id && i.active
                    ) > -1;
                  const cannotClick =
                    subscriptionLocations &&
                    subscriptionLocations.filter((l) => l.active).length > 0 &&
                    subscriptionLocations.filter((l) => l.active).length ===
                      user?.subscription_quantity &&
                    subscriptionLocations.findIndex(
                      (i) => i.id === l.id && i.active
                    ) === -1;
                  return (
                    <Box
                      key={l.id as React.Key}
                      id={l.id}
                      padding="2"
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Center
                        w={"100%"}
                        h={{ base: "4em", sm: "5em", md: "5em" }}
                        bg={flag ? "orange.500" : ""}
                        boxShadow={flag ? "base" : ""}
                        outline={flag ? "none" : "1px solid"}
                        outlineColor={flag ? "none" : "gray.200"}
                        rounded="md"
                        roundedTopRight={l.manual ? "none" : "md"}
                        roundedBottomRight={l.manual ? "none" : "md"}
                        onClick={() => {
                          if (!cannotClick) handleToggleAddToSubscription(l.id);
                        }}
                        cursor={!cannotClick ? "pointer" : "not-allowed"}
                        pos={"relative"}
                      >
                        <Text
                          color={flag ? "white" : "gray.500"}
                          textAlign={"center"}
                        >
                          {l.name}{" "}
                        </Text>
                        {flag ? (
                          <Icon
                            as={CiLocationOn}
                            color="white"
                            pos={"absolute"}
                            top={"5px"}
                            left={"5px"}
                          />
                        ) : (
                          <Icon
                            as={CiLocationOff}
                            color="gray.500"
                            pos={"absolute"}
                            top={"5px"}
                            left={"5px"}
                          />
                        )}
                      </Center>
                      {l.manual && (
                        <Button
                          w={{
                            base: "48px",
                            sm: "48px",
                            md: "48px",
                            lg: "48px",
                          }}
                          h={{ base: "4em", sm: "5em", md: "5em" }}
                          outline={flag ? "none" : "1px solid"}
                          outlineColor={flag ? "none" : "gray.200"}
                          roundedTopLeft={l.manual ? "none" : "md"}
                          roundedBottomLeft={l.manual ? "none" : "md"}
                          colorScheme={flag ? "red" : "gray"}
                          onClick={() => {
                            setDeleteLocationID(l.id);
                            alertModalState.onOpen();
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </Box>
                  );
                })}
            </SimpleGrid>
          ) : (
            <SimpleGrid minChildWidth="180px" spacing="5px" mt={"5"}>
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <Box key={`${i}`} id={`${i}`} padding="2">
                  <Skeleton
                    h={{ base: "4em", sm: "4em", md: "5em" }}
                    rounded="md"
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Flex>
      </motion.div>
      <SettingsLocationAdd
        reload={() => {
          reload(true);
        }}
        isOpen={addModalState.isOpen}
        onClose={addModalState.onClose}
        onOpen={addModalState.onOpen}
      />
      <AlertDialog
        isOpen={alertModalState.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertModalState.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Location
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={alertModalState.onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDeleteLocation}
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
