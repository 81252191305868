import {
  Form,
  redirect,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { callFunction } from "../controllers/apiClient";
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Button,
  Center,
  Divider,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { User } from "../lib/constants";
import { useState } from "react";

export async function action({ request }: any) {
  try {
    console.log('running action...');
    const formData = await request.formData();
    const userId = formData.get("userId");
    const planId = formData.get("planId");
    if (userId && planId) {
      const result = await callFunction("dashboard/users", "post", {
        userId,
        planId,
      });
      if (result) {
        if (result.subscription_id && result.subscription_customer_id) {
          return redirect(`/dashboard`);
        } else {
          throw new Error("User subscription account could not be created");
        }
      } else {
        throw new Error("Could not get result");
      }
    } else {
      throw new Error("Parameters missing");
    }
  } catch (e) {
    console.log(e);
    throw new Error(e as any);
  }
}

export async function loader({ _params }: any) {
  try {
    const userResponse = await callFunction(
      "dashboard/users",
      "get",
      undefined,
      undefined
    );
    return { userResponse };
  } catch (err) {
    throw new Error("Could not fetch users data");
  }
}

export const Dashboard = () => {
  const navigate = useNavigate();
  const { userResponse } = useLoaderData() as any;
  const users = (userResponse || []) as User[];
  const navigation = useNavigation();
  const [submittingUserId, setSubmittingUserId] = useState<string>("");

  return (
    <Flex
      id="CustomerStartClaimContainer"
      width={[
        "90%", // 0-30em      // 480px
        "90%", // 30em-48em   // 768px
        "75%", // 48em-62em   // 992px
        "75%", // 62em-80em   // 1280px
        "75%", // 80em-96em+  // 1536px
      ]}
      maxW={"900"}
    >
      <Center flexDirection={"column"} height={"100%"} width={"100%"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Button
            onClick={() => navigate("../")}
            leftIcon={<ArrowBackIcon />}
            variant="ghost"
            width={"fit-content"}
            marginBottom={"5"}
          >
            Back
          </Button>
        </Flex>
        <Heading marginBottom={"5"} textAlign={"center"}>
          Dashboard
        </Heading>
        <Divider orientation="horizontal" mb={"5"} />
        <div style={{ width: "100%" }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
            }}
          >
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Action</Th>
                    <Th>UserId</Th>
                    <Th>MerchantId</Th>
                    <Th>SubscriptionId</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((u) => (
                    <Tr key={u.id}>
                      <Td>{u.user_name}</Td>
                      <Td>{u.user_details}</Td>
                      <Td>
                        {u.subscription_id ? (
                          <CheckIcon />
                        ) : (
                          <Form method="post">
                            <input type="hidden" name="userId" value={u.id} />
                            <input
                              type="hidden"
                              name="planId"
                              value="price_1MvtiIFht5XCuRvsZLlvzZDp"
                            />
                            <Button
                              type="submit"
                              onClick={() => setSubmittingUserId(u.id)}
                              isDisabled={
                                navigation.state === "submitting" &&
                                submittingUserId !== u.id
                              }
                              isLoading={
                                navigation.state === "submitting" &&
                                submittingUserId === u.id
                              }
                            >
                              Create Sub
                            </Button>
                          </Form>
                        )}
                      </Td>
                      <Td>
                        <Text fontSize="sm">{u.id}</Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">{u.merchant_id || ""}</Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">{u.subscription_id || ""}</Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            </TableContainer>
          </motion.div>
        </div>
      </Center>
    </Flex>
  );
};
