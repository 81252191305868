type Props = {
  size: string;
};

export const FeatureRedeem: React.FC<Props> = ({ size }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_205_264)">
        <rect width="200" height="200" fill="white" />
        <rect
          x="139.01"
          y="49.647"
          width="148.353"
          height="77.5803"
          rx="10"
          transform="rotate(90 139.01 49.647)"
          fill="#D9D9D9"
          stroke="#202020"
          strokeWidth="4"
        />
        <circle
          cx="117.753"
          cy="75.0867"
          r="7.8108"
          transform="rotate(90 117.753 75.0867)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="83.2385"
          cy="123.547"
          r="7.8108"
          transform="rotate(90 83.2385 123.547)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="83.2385"
          cy="99.1419"
          r="7.8108"
          transform="rotate(90 83.2385 99.1419)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="83.2385"
          cy="75.0867"
          r="7.8108"
          transform="rotate(90 83.2385 75.0867)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="83.0342"
          cy="172.356"
          r="7.8108"
          transform="rotate(90 83.0342 172.356)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="117.753"
          cy="123.547"
          r="7.8108"
          transform="rotate(90 117.753 123.547)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="83.2385"
          cy="147.951"
          r="7.8108"
          transform="rotate(90 83.2385 147.951)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="117.753"
          cy="147.951"
          r="7.8108"
          transform="rotate(90 117.753 147.951)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="117.897"
          cy="172.356"
          r="7.8108"
          transform="rotate(90 117.897 172.356)"
          fill="#0FC737"
          stroke="black"
          strokeWidth="3"
        />
        <circle
          cx="117.753"
          cy="99.1419"
          r="7.8108"
          transform="rotate(90 117.753 99.1419)"
          fill="#FBAF1B"
          stroke="black"
          strokeWidth="3"
        />
        <path
          d="M141.279 100.12V102.12H143.279H165.339C165.63 102.12 165.9 102.273 166.051 102.523C166.202 102.778 166.208 103.089 166.073 103.343C166.073 103.343 166.072 103.343 166.072 103.343L159.124 116.373L158.622 117.314L159.124 118.256L166.072 131.286C166.072 131.286 166.073 131.286 166.073 131.286C166.208 131.54 166.202 131.851 166.051 132.106C165.9 132.356 165.63 132.509 165.339 132.509H140.449C139.99 132.509 139.619 132.139 139.619 131.678V122.38V120.835L138.124 120.445C111.724 113.557 88.7582 113.557 62.3589 120.445L60.8638 120.835V122.38V131.678C60.8638 132.139 60.4928 132.509 60.0335 132.509H35.1438C34.8531 132.509 34.5832 132.357 34.4324 132.106C34.2805 131.852 34.2747 131.54 34.4101 131.286L41.3586 118.256L41.8604 117.314L41.3586 116.373L34.4101 103.343C34.4101 103.343 34.41 103.343 34.4099 103.343C34.2747 103.089 34.2805 102.777 34.4324 102.522C34.5832 102.272 34.8531 102.12 35.1438 102.12H57.2032H59.2032V100.12V87.6285C59.2032 87.2546 59.4524 86.926 59.8179 86.826L59.2903 84.8981L59.8164 86.8264C88.1725 79.0906 112.309 79.0904 140.665 86.826C141.03 86.926 141.279 87.2546 141.279 87.6285V100.12Z"
          fill="#FFC85C"
          stroke="#202020"
          strokeWidth="4"
        />
        <path
          d="M127.964 21.944L127.964 21.944C128.276 21.9893 128.536 22.2067 128.635 22.5076C128.732 22.8125 128.649 23.142 128.425 23.3602C128.425 23.3603 128.425 23.3603 128.425 23.3603L115.688 35.7798L114.936 36.5136L115.114 37.5495L118.118 55.0867L118.118 55.0877C118.171 55.398 118.044 55.7125 117.789 55.8985C117.641 56.0059 117.47 56.0585 117.3 56.0585C117.169 56.0585 117.036 56.027 116.914 55.9628L116.913 55.9621L101.172 47.6842L100.241 47.1947L99.3101 47.6842L83.5692 55.9621L83.5667 55.9634C83.29 56.1094 82.9551 56.0881 82.6933 55.8985C82.438 55.7124 82.3106 55.398 82.364 55.0877L82.3641 55.0867L85.3686 37.5495L85.546 36.5136L84.7936 35.7798L72.0572 23.3603C71.8334 23.1421 71.7496 22.8124 71.847 22.5075C71.9466 22.2067 72.2064 21.9893 72.518 21.944L72.5184 21.944L90.1194 19.3865L91.1602 19.2353L91.6255 18.2922L99.4973 2.33641L99.4988 2.33342C99.5587 2.21137 99.7749 2 100.241 2C100.707 2 100.923 2.21137 100.983 2.33342L100.985 2.33641L108.857 18.2922L109.322 19.2353L110.363 19.3865L127.964 21.944Z"
          fill="#FB831B"
          stroke="#202020"
          strokeWidth="4"
        />
        <path
          d="M54.076 31.3166L54.0774 31.3194L59.0388 41.3778L59.5039 42.3208L60.5444 42.4722L71.6391 44.0869L71.6399 44.087C71.9511 44.1322 72.2111 44.3493 72.3109 44.6532C72.4077 44.9538 72.3257 45.2839 72.1007 45.5033L64.0716 53.3302L63.3187 54.0641L63.4964 55.1004L65.3925 66.1569L65.3926 66.1576C65.4459 66.4679 65.3185 66.7823 65.0633 66.9684C64.9154 67.0758 64.745 67.1284 64.5749 67.1284C64.4432 67.1284 64.3106 67.0969 64.1887 67.0326L64.1876 67.0321L54.2649 61.8121L53.3337 61.3223L52.4026 61.8121L42.4799 67.0321L42.4755 67.0344C42.2082 67.1759 41.8701 67.1592 41.6025 66.9699C41.3465 66.7796 41.222 66.4649 41.2748 66.1576L41.275 66.1565L43.1684 55.1028L43.3458 54.0669L42.5934 53.3332L34.564 45.5033C34.564 45.5033 34.564 45.5033 34.564 45.5032C34.339 45.2838 34.257 44.9538 34.3538 44.6532C34.4535 44.3493 34.7136 44.1322 35.0249 44.087L35.0257 44.0869L46.1203 42.4722L47.1605 42.3209L47.6257 41.3782L52.5899 31.3198L52.5915 31.3166C52.7296 31.0354 53.016 30.856 53.3337 30.856C53.6514 30.856 53.9379 31.0354 54.076 31.3166Z"
          fill="#FBAF1B"
          stroke="#202020"
          strokeWidth="4"
        />
        <path
          d="M147.762 31.4606L147.764 31.4634L152.725 41.5219L153.19 42.4649L154.231 42.6163L165.325 44.2309L165.326 44.2311C165.637 44.2762 165.897 44.4933 165.997 44.7972C166.094 45.0978 166.012 45.4279 165.787 45.6473L157.758 53.4743L157.005 54.2082L157.183 55.2444L159.079 66.301L159.079 66.3016C159.132 66.6119 159.005 66.9264 158.75 67.1124C158.602 67.2198 158.431 67.2724 158.261 67.2724C158.129 67.2724 157.997 67.2409 157.875 67.1767L157.874 67.1761L147.951 61.9562L147.02 61.4663L146.089 61.9562L136.166 67.1761L136.162 67.1784C135.894 67.3199 135.556 67.3032 135.289 67.1139C135.033 66.9237 134.908 66.609 134.961 66.3016L134.961 66.3006L136.855 55.2468L137.032 54.211L136.28 53.4773L128.25 45.6473C128.25 45.6473 128.25 45.6473 128.25 45.6473C128.025 45.4279 127.943 45.0978 128.04 44.7973C128.14 44.4934 128.4 44.2762 128.711 44.2311L128.712 44.2309L139.807 42.6163L140.847 42.4649L141.312 41.5223L146.276 31.4638L146.278 31.4606C146.416 31.1795 146.702 31 147.02 31C147.338 31 147.624 31.1795 147.762 31.4606Z"
          fill="#FBAF1B"
          stroke="#202020"
          strokeWidth="4"
        />
        <mask
          id="mask0_205_264"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="90"
          y="88"
          width="23"
          height="21"
        >
          <path
            d="M110.613 88.4435C110.581 88.348 94.469 88.4117 92.4948 88.4435C90.5206 88.4754 90.3932 89.7809 90.3932 89.7809C90.3932 89.7809 90.2977 98.9196 90.2659 102.932C90.5206 106.689 94.0868 108.062 95.2332 108.09C96.5388 108.122 102.78 108.217 107.047 108.154C111.823 107.804 112.428 103.346 112.428 103.346C112.428 103.346 112.428 102.345 112.429 100.894C112.431 97.6834 112.438 92.2653 112.46 90.5769C112.492 88.1251 110.645 88.539 110.613 88.4435Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_205_264)">
          <path
            d="M112.05 88.3429C109.856 87.0708 102.819 85.3196 97.2804 89.1482C92.3772 92.5377 87.925 92.4621 87.925 92.4621L87.097 92.4212L87.085 104.61L124.395 104.637C124.395 104.637 124.229 96.282 124.285 92.5508C118.301 92.6059 114.365 89.685 112.05 88.3429Z"
            fill="#FFC85C"
          />
          <path
            d="M102.264 89.5851C100.071 88.313 93.0332 86.5618 87.4948 90.3904C82.5916 93.7799 78.1393 93.7043 78.1393 93.7043L77.3113 93.6634L77.2993 105.852L114.609 105.879C114.609 105.879 114.444 97.5242 114.499 93.793C108.515 93.8481 104.579 90.9272 102.264 89.5851Z"
            fill="#FBAF1B"
          />
          <path
            d="M96.3096 91.8097C94.1158 90.5376 87.0783 88.7864 81.5399 92.615C76.6368 96.0045 72.1845 95.9289 72.1845 95.9289L71.3565 95.888L71.3445 108.077L108.654 108.104C108.654 108.104 108.489 99.7488 108.544 96.0176C102.56 96.0727 98.6242 93.1518 96.3096 91.8097Z"
            fill="#FB831B"
          />
          <path
            d="M133.53 91.8133C131.327 90.5443 124.225 88.7491 118.699 92.6167C113.801 96.0446 109.257 95.9977 109.257 95.9977H108.302L108.461 108.042L145.926 108.069C145.926 108.069 145.76 99.7201 145.815 95.9977C139.797 96.2856 135.854 93.1523 133.53 91.8133Z"
            fill="#FB831B"
          />
        </g>
        <path
          d="M110.654 88.3405C110.622 88.245 94.5098 88.3086 92.5356 88.3405C90.5614 88.3723 90.434 89.6779 90.434 89.6779C90.434 89.6779 90.3385 98.8166 90.3066 102.829C90.5614 106.586 94.1276 107.959 95.274 107.987C96.5795 108.019 102.821 108.114 107.087 108.051C111.864 107.701 112.469 103.243 112.469 103.243C112.469 103.243 112.469 102.242 112.47 100.791C112.472 97.5804 112.479 92.1623 112.501 90.4739C112.532 88.0221 110.686 88.436 110.654 88.3405Z"
          stroke="#202020"
          strokeWidth="3"
        />
        <path
          d="M110.654 88.3405C110.622 88.245 94.5098 88.3086 92.5356 88.3405C90.5614 88.3723 90.434 89.6779 90.434 89.6779C90.434 89.6779 90.3385 98.8166 90.3066 102.829C90.5614 106.586 94.1276 107.959 95.274 107.987C96.5795 108.019 102.821 108.114 107.087 108.051C111.864 107.701 112.469 103.243 112.469 103.243C112.469 103.243 112.469 102.242 112.47 100.791C112.472 97.5804 112.479 92.1623 112.501 90.4739C112.532 88.0221 110.686 88.436 110.654 88.3405Z"
          stroke="#202020"
          strokeWidth="3"
        />
        <path
          d="M112.5 93.3072L112.516 100.878C112.516 100.878 112.667 100.846 113.01 100.822C115.653 100.313 117.245 98.7204 117.818 96.2686C118.391 93.8167 116.481 93.2754 116.481 93.2754L112.5 93.3072Z"
          stroke="#202020"
          strokeWidth="3"
        />
      </g>
      <defs>
        <clipPath id="clip0_205_264">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
