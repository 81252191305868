export const Empty = (props: { size: number }) => {
  return (
    <svg
      width={props.size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{margin: "auto"}}
    >
      <path
        d="M144.027 39.3123C143.83 38.721 44.0981 39.1152 31.878 39.3123C19.6578 39.5094 18.8695 47.5905 18.8695 47.5905C18.8695 47.5905 18.2782 104.158 18.0811 128.992C19.6578 152.25 41.7322 160.749 48.8285 160.922C56.9096 161.12 95.541 161.711 121.952 161.317C151.517 159.149 155.262 131.555 155.262 131.555C155.262 131.555 155.262 125.363 155.268 116.378C155.281 96.5061 155.324 62.9689 155.459 52.5179C155.656 37.3413 144.224 39.9036 144.027 39.3123Z"
        stroke="#EDF2F7"
        strokeWidth="8"
      />
      <path
        d="M155.459 70.0599L155.558 116.92C155.558 116.92 156.494 116.723 158.613 116.575C174.972 113.422 184.827 103.567 188.375 88.3901C191.923 73.2135 180.097 69.8628 180.097 69.8628L155.459 70.0599Z"
        stroke="#EDF2F7"
        strokeWidth="8"
      />
      <path
        d="M10 161.12H164.329"
        stroke="#EDF2F7"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M178.125 161.12H192.908"
        stroke="#EDF2F7"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  );
};
