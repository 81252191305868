import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Moranga Bold';
        src: url("/fonts/moranga-bold.otf") format("opentype");}
      `}
  />
)

export default Fonts