import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { callFunction } from "../controllers/apiClient";
import { v4 as uuid } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";
import { Claim } from "../lib/constants";

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const CreateClaim = () => {
  const id = uuid();
  const navigate = useNavigate();
  const [claim, setClaim] = useState<Claim>();
  const { merchantId, locationId } = useParams();
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () => {
      navigate(`../`);
    },
  });
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const handleCreateClaim = async (n: number) => {
    try {
      if (merchantId && locationId) {
        setIsCreating(true);
        const claim = await callFunction("claim", "post", {
          id,
          quantity: n,
          merchantId: merchantId,
          locationId: locationId,
        });
        if (claim) {
          setClaim(claim);
        }
      } else {
        throw new Error("No merchant or location data");
      }
    } catch (err) {
    } finally {
      setIsCreating(false);
    }
  };
  return (
    <Modal
      closeOnOverlayClick={isCreating ? false : true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h3" size="md">
            {claim ? "Please Scan!" : "Select quantity"}
          </Heading>
        </ModalHeader>
        {/* <ModalCloseButton>
          {isCreating ? <Spinner size={"md"} /> : <CloseButton />}
        </ModalCloseButton> */}
        <ModalBody
          pb={6}
          pt={6}
          position={"relative"}
          height={"300px"}
          flex={"unset"}
        >
          <AnimatePresence mode={"popLayout"}>
            {claim && (
              <motion.div
                key="qrcode"
                id="qrcode"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.3,
                }}
              >
                <Flex
                  id={claim.id}
                  flexDirection={"column"}
                  textAlign={"center"}
                >
                  <QRCodeSVG
                    size={250}
                    style={{ margin: "auto" }}
                    value={`${window.location.protocol}//${window.location.host}/gateway/claim/${claim.id}`}
                  />
                  {process.env.NODE_ENV !== "production" && (
                    <p>
                      {`${window.location.protocol}//${window.location.host}/gateway/claim/${claim.id}`}
                    </p>
                  )}
                </Flex>
              </motion.div>
            )}
            {!claim && (
              <motion.div
                key="numpad"
                id="numpad"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{
                  duration: 0.3,
                }}
              >
                <SimpleGrid columns={3} spacing={5} id={id}>
                  {num.map((n) => (
                    <Box
                      as="button"
                      borderRadius="md"
                      bg={"orange.400"}
                      color="white"
                      px={4}
                      h={20}
                      key={n}
                      onClick={() => handleCreateClaim(n)}
                      disabled={isCreating}
                    >
                      {n}
                    </Box>
                  ))}
                </SimpleGrid>
              </motion.div>
            )}
          </AnimatePresence>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isCreating} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
