import { useEffect, useState, useRef } from "react";
import {
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  Highlight,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useLoaderData,
  useNavigate,
  useParams,
  redirect,
} from "react-router-dom";
import { callFunction } from "../controllers/apiClient";
import { Card, Claim } from "../lib/constants";
import { BiError } from "react-icons/bi";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import * as animationData from "../lib/animations/checkoutSuccess.json";
import { Stamp } from "./Stamp";
import localforage from "localforage";
import pluralize from "pluralize";
import { Punch } from "./Punch";

export async function loader({ params }: any) {
  const claimId = params.claimId;
  if (claimId) {
    try {
      const c = await callFunction("claim", "get", undefined, {
        id: claimId,
      });
      if (!c) {
        throw new Error("Could not retrieve claim");
      } else {
        const cd = await callFunction("card", "get", undefined, {
          merchant_id: c.merchant_id,
        });
        if (!cd) throw new Error("Could not retrieve card");
        return { card: cd, claim: c };
      }
    } catch (err) {
      console.log("Error:", err);
      throw new Error("Could not retrieve claim");
    }
  }
}

export async function action({ request, params }: any) {
  const claimId = params.claimId;
  const formData = await request.formData();
  const cardId = formData.get("cardId");
  const userId = formData.get("userId");
  if (cardId && claimId) {
    await callFunction("card", "put", {
      cardId: cardId,
      claimId: claimId,
    });
    return redirect(`/customer/${userId}`);
  } else {
    throw new Error("No cardId provided to claim against");
  }
}

export const CustomerAcceptClaim = () => {
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () => {
      card.user_id ? navigate(`../${card.user_id}`) : navigate(`../`);
    },
  });
  let { claimId } = useParams();
  const data = useLoaderData() as any;
  const card = data?.card as Card;
  const claim = data?.claim as Claim;
  const heading = claim?.card_id ? "Sorry!" : "Thanks for your purchase! 😊";
  const [isClaiming, setIsClaiming] = useState<boolean>(false);
  const [hasClaimed, setHasClaimed] = useState<boolean>(false);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    localforage.removeItem("redirect");
  }, []);

  useEffect(() => {
    if (hasClaimed) lottieRef.current?.play();
  }, [hasClaimed]);

  if (!claimId) throw new Error("No claimId");
  if (!card || !claim) throw new Error("Could not load");

  const handleClaim = async () => {
    try {
      if (card?.id && claim.id) {
        setIsClaiming(true);
        const result = await callFunction("card", "put", {
          cardId: card.id,
          claimId: claim.id,
        });
        if (result) {
          setHasClaimed(true);
        }
        // return redirect(`/customer/${userId}`);
      } else {
        throw new Error("No cardId provided to claim against");
      }
    } catch (err) {
    } finally {
      setIsClaiming(false);
    }
  };
  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay bgColor={"orange.100"} />
      <ModalContent>
        <ModalHeader>
          {!hasClaimed && (
            <Heading as="h3" size="md">
              {heading}
            </Heading>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex id="SuccessContainer" flexDirection={"column"}>
            <Collapse in={!hasClaimed} animateOpacity>
              <Center>
                {claim?.card_id ? (
                  <Flex flexDirection={"column"}>
                    <Icon
                      margin={"auto"}
                      as={BiError}
                      boxSize={"20"}
                      color={"orange.500"}
                    />
                    <p>Already claimed!</p>
                  </Flex>
                ) : (
                  <Flex flexDirection={"column"}>
                    <Flex flexDirection={"column"} textAlign={"center"}>
                      <SimpleGrid columns={{ base: 3, sm: 5 }}>
                        {Array.from(
                          { length: card.redeem_index },
                          (_, i) => i + 1
                        ).map((c) => {
                          let date = "";
                          let count = 0;
                          if (card.claims) {
                            for (const e of card.claims.sort((a, b) => {
                              const dateA = new Date(a.created_at);
                              const dateB = new Date(b.created_at);
                              return dateA.getTime() - dateB.getTime();
                            })) {
                              count += e.quantity;
                              if (count >= c) {
                                date = e.created_at;
                                break;
                              }
                            }
                          }
                          const stamped =
                            c <=
                            (card && card.claims
                              ? card.claims.reduce((a, c) => a + c.quantity, 0)
                              : 0);
                          return (
                            <Center id={`${c}`} key={c}>
                              <Stamp
                                size={100}
                                stamped={stamped}
                                free={c === card.redeem_index}
                                date={stamped === true ? date : undefined}
                              />
                            </Center>
                          );
                        })}
                      </SimpleGrid>
                    </Flex>
                    <Heading size={"md"} mt={"5"} textAlign={"center"}>
                      <Highlight
                        query={`${claim.quantity} ${pluralize(
                          "punch",
                          claim.quantity
                        )}`}
                        styles={{ px: "1", py: "1", bg: "orange.100" }}
                      >
                        {`You will receive ${claim.quantity} ${pluralize(
                          "punch",
                          claim.quantity
                        )}`}
                      </Highlight>
                    </Heading>
                  </Flex>
                )}
              </Center>
            </Collapse>
            <Collapse in={hasClaimed} animateOpacity>
              <Center>
                <Flex flexDirection={"column"}>
                  <Lottie
                    loop={false}
                    autoplay={false}
                    animationData={animationData}
                    lottieRef={lottieRef}
                    style={{ height: "200px", width: "200px", margin: "auto" }}
                    onComplete={() => setTimeout(() => onClose(), 1000)}
                  />
                  <Heading as="h3" size="md">
                    Your card has been punched!
                  </Heading>
                  <Heading as="h4" size="sm"></Heading>
                </Flex>
              </Center>
            </Collapse>
          </Flex>
        </ModalBody>
        <ModalFooter>
          {!hasClaimed && !claim.card_id && (
            <Button
              type={"button"}
              onClick={handleClaim}
              isLoading={isClaiming}
              loadingText="Punching..."
              leftIcon={<Punch size={30} />}
              colorScheme="orange"
              _hover={{
                color: "white",
                bgGradient: "linear(to-r, yellow.500, red.500,)",
              }}
              variant="solid"
              disabled={!card || !claim}
            >
              Punch my card
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
