import { createContext, useContext } from 'react';
import { IEventTelemetry,  IMetricTelemetry, IPageViewTelemetry } from "@microsoft/applicationinsights-web";

export interface aiContent {
    trackEvent: (event: IEventTelemetry, customProperties?: {[key:string]: any}) => boolean,
    trackPageView: (pageView?: IPageViewTelemetry) => boolean,
    trackMetric: (metric: IMetricTelemetry, customProperties?: {[name: string]: any}) => boolean,
    startTrackEvent: (name?: string) => boolean,
    stopTrackEvent: (name: string, properties?: { [key: string]: string; }, measurements?: { [key: string]: number; }) => boolean
    setUserId: (userId: string) => boolean,
    getCookieManager: () => any
}

export const AIContext = createContext<aiContent>({
    trackEvent: () => true,
    trackPageView: () => true,
    trackMetric: () => true,
    startTrackEvent: () => true,
    stopTrackEvent: () => true,
    setUserId: () => true,
    getCookieManager: () => null,
});

export const useAIContext = () => useContext(AIContext)