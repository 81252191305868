import { useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { callFunction } from "../controllers/apiClient";
import { v4 as uuid } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { Claim, User } from "../lib/constants";

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const CustomerCreateClaim = (props: {
  isOpen: boolean;
  onClose: () => void;
  merchantId: string;
  locationId: string;
  user: User | undefined;
  claim: Claim | undefined;
  setClaim: (claim: Claim | undefined) => void;
}) => {
  const id = uuid();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const handleCreateClaim = async (n: number) => {
    try {
      if (props.locationId && props.user && props.merchantId) {
        setIsCreating(true);
        const card = await callFunction("card", "get", undefined, {
          merchant_id: props.merchantId,
        });
        if (card) {
          const claim = await callFunction("claim", "post", {
            id,
            quantity: n,
            merchantId: props.merchantId,
            locationId: props.locationId,
            userId: props.user.id,
            cardId: card.id,
            isPending: true,
          });
          if (claim) {
            props.setClaim(claim);
            props.onClose();
          }
        } else {
          throw new Error("Could not get merchant card");
        }
      } else {
        throw new Error("No merchant or location data");
      }
    } catch (err) {
    } finally {
      setIsCreating(false);
    }
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h3" size="md">
            {props.claim
              ? "Submitted!"
              : props.user
              ? "How many items did you purchase?"
              : "Loading..."}
          </Heading>
        </ModalHeader>
        <ModalBody
          pb={6}
          pt={6}
          position={"relative"}
          height={"300px"}
          flex={"unset"}
        >
          <AnimatePresence>
            {!props.claim && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{
                  duration: 0.3,
                }}
                style={{ height: "100%" }}
              >
                {props.user ? (
                  <SimpleGrid columns={3} spacing={5} id={id}>
                    {num.map((n) => (
                      <Box
                        as="button"
                        borderRadius="md"
                        bg={"orange.400"}
                        color="white"
                        px={4}
                        h={20}
                        key={n}
                        onClick={() => handleCreateClaim(n)}
                        disabled={isCreating}
                      >
                        {n}
                      </Box>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Center height={"100%"}>
                    <Spinner
                      height={"80px"}
                      width={"80px"}
                      margin={"auto"}
                      size={"xl"}
                      color={"orange"}
                      speed="0.65s"
                      thickness="4px"
                    />
                  </Center>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isCreating} onClick={props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
