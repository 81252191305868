import {
  Avatar,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { Stamp } from "./Stamp";
import { useLoaderData, useNavigate } from "react-router-dom";
import { callFunction } from "../controllers/apiClient";
import { Card } from "../lib/constants";

export async function loader({ params }: any) {
  const merchantId = params.merchantId;
  if (merchantId) {
    try {
      const cd = await callFunction("card", "get", undefined, {
        merchant_id: merchantId,
      });
      if (!cd) throw new Error("Could not retrieve card");
      return { card: cd };
    } catch (err) {
      console.log("Error:", err);
      throw new Error("Could not retrieve claim");
    }
  }
}

export const CustomerCard = () => {
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () => {
      navigate(`../`);
    },
  });
  const data = useLoaderData() as any;
  const card = data?.card as Card;

  if (!card) throw new Error("Could not load");

  const freeClaim =
    card.redeem_index - 1 - card.claims?.reduce((a, c) => a + c.quantity, 0) <=
    0;

  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay bgColor={"orange.100"}/>
      <ModalContent>
        <ModalHeader>
          <Avatar
            name={card.merchant?.business_name}
            src={card.merchant?.logo_url || ""}
            color="white"
            size="sm"
            mr={"2"}
          />
          {card.merchant?.business_name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"} textAlign={"center"}>
            {/* <Text mb={"7"}>{`${
              card.redeem_index -
              card.claims.reduce((a, c) => a + c.quantity, 0)-1 
            } to go!`}</Text> */}
            <SimpleGrid columns={{ base: 3, sm: 5 }}>
              {Array.from({ length: card.redeem_index }, (_, i) => i + 1).map(
                (c) => {
                  let date = "";
                  let count = 0;
                  if (card.claims) {
                    for (const e of card.claims.sort((a, b) => {
                      const dateA = new Date(a.created_at);
                      const dateB = new Date(b.created_at);
                      return dateA.getTime() - dateB.getTime();
                    })) {
                      count += e.quantity;
                      if (count >= c) {
                        date = e.created_at;
                        break;
                      }
                    }
                  }
                  const stamped =
                    c <=
                    (card && card.claims
                      ? card.claims.reduce((a, c) => a + c.quantity, 0)
                      : 0);
                  return (
                    <Center id={`${c}`} key={c}>
                      <Stamp
                        size={100}
                        stamped={
                          card.claims
                            ? c <=
                              card.claims.reduce((a, c) => a + c.quantity, 0)
                            : false
                        }
                        free={c === card.redeem_index}
                        date={stamped === true ? date : undefined}
                      />
                    </Center>
                  );
                }
              )}
            </SimpleGrid>
          </Flex>
        </ModalBody>
        <ModalFooter>
          {freeClaim && (
            <Button
              variant={"solid"}
              color="white"
              bgGradient="linear(to-r, green.500, yellow.500)"
              _hover={{
                color: "white",
                bgGradient: "linear(to-r, yellow.500, red.500,)",
              }}
            >
              {"Redeem Free Item!"}
            </Button>
          )}
          {/* <Button onClick={onClose}>Close</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
