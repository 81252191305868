import { useContext, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../lib/dataContext";

export const MerchantShowClaim = () => {
  const navigate = useNavigate();
  const { events } = useContext(DataContext);
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () => {
      navigate("../");
    },
  });
  let { claimId } = useParams();

  useEffect(() => {
    if (claimId) {
      if (events?.findIndex((e) => claimId === e.id) === -1) {
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  if (!claimId) throw new Error("No claimId");
  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please scan!</ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody pb={6} pt={6}>
          <>
            <QRCodeSVG
              size={250}
              style={{ margin: "auto" }}
              value={`${window.location.protocol}//${window.location.host}/gateway/claim/${claimId}`}
            />
            {process.env.NODE_ENV !== "production" && (
              <p>
                {`${window.location.protocol}//${window.location.host}/gateway/claim/${claimId}`}
              </p>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
