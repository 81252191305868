import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DataContext } from "../lib/dataContext";
import Lottie from "lottie-react";
import * as animationData from "../lib/animations/checkoutSuccess.json";
import { Center, Collapse, Flex, Heading, Spinner } from "@chakra-ui/react";
import { callFunction } from "../controllers/apiClient";
import { User } from "../lib/constants";

export const CheckoutSuccess = () => {
  const [isProvisioning, setIsProvisioning] = useState<boolean>(false);
  const { connect, setUser } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userId } = useParams();
  const session_id = searchParams.get("session_id");

  if (!session_id) {
    navigate("/");
  }

  useEffect(() => {
    const timer = setTimeout(() => navigate("/"), 60000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchUser() {
      const user = (await callFunction("user", "get")) as User;
      return user;
    }
    if (isProvisioning) {
      fetchUser().then((u) => {
        if (u && u.subscription_id) {
          setUser(u);
          navigate("/merchant/new");
        } else {
          if (userId) connect(userId);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProvisioning, userId]);

  return (
    <Flex id="CheckoutSuccessContainer" flexDirection={"column"}>
      <Collapse in={!isProvisioning} animateOpacity>
        <Center m="10">
          <Heading as="h3" size="lg">
            Thanks for subscribing! 😊
          </Heading>
        </Center>
        <Lottie
          animationData={animationData}
          loop={false}
          autoPlay={true}
          style={{ height: "400px", width: "400px" }}
          onComplete={() => {
            setIsProvisioning(true);
          }}
        />
      </Collapse>
      <Collapse in={isProvisioning} animateOpacity>
        <Center>
          <Flex>
            <Center>
              <Spinner
                thickness="6px"
                speed="0.99s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
                marginRight={"5"}
              />
              Please wait while we set things up...
            </Center>
          </Flex>
        </Center>
      </Collapse>
    </Flex>
  );
};
