import axios from "axios";

const apiBaseUrl = "/api/";

const callFunction = async (
  functionName: string,
  method: string,
  data?: any,
  params?: any,
  headers?: any
) => {
  try {
    const response = await axios({
      url: apiBaseUrl + functionName,
      method,
      params,
      data,
      headers,
    });
    return response.data;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy to the cloud") first before running this App`;
      } else if(err?.response?.status === 428) {
        return { status: 428, message: err.response.data.error }
      } else if (err.message === "Network Error") {
        funcErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }
      // Check for 500 response from Square losing auth
      if(err?.response?.status === 500 && err?.response?.data?.code && err?.response?.data?.detail){
        throw new Error(err?.response?.data?.detail)
      } else if(err?.response?.status === 401) {
        // redirect to login
        window.location.href = `${window.location.protocol}//${window.location.host}/login`
      }
      console.log(funcErrorMsg);
      throw new Error(funcErrorMsg);
    }
    throw err;
  }
};

export { callFunction };
