import { useContext, useEffect, useState } from "react";
import {
  Link as ReactLink,
  Outlet,
  useLoaderData,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DataContext } from "../lib/dataContext";
import { callFunction } from "../controllers/apiClient";
import { Card, CardCache } from "../lib/constants";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  CardHeader,
  Heading,
  CardFooter,
  Button,
  Card as ChakraCard,
  Box,
  Link,
  Image,
  Text,
  Center,
  Avatar,
  Tag,
  Tooltip,
  CardBody,
  Divider,
  useToast,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { AiFillCamera } from "react-icons/ai";
import { motion, LayoutGroup } from "framer-motion";
import localforage from "localforage";
import dayjs from "dayjs";
import pluralize from "pluralize";
import { max } from "lodash";
import { Empty } from "./Empty";
import { Punch } from "./Punch";
import QrScanner from "qr-scanner";

export async function loader({ params }: any) {
  try {
    const userId = params.userId;
    if (userId) {
      let cards;
      let cardCache = (await localforage.getItem(userId)) as CardCache;
      if (
        true
        // !cardCache ||
        // (cardCache && dayjs(cardCache.refreshed_at) < dayjs().add(-1, "minute"))
      ) {
        const cardResponse = await callFunction(
          "card",
          "get",
          undefined,
          undefined
        );
        if (cardResponse) {
          localforage.setItem(userId, {
            cards: cardResponse,
            refreshed_at: new Date(),
          });
          cards = cardResponse;
        } else {
          cards = [];
        }
      } else if (cardCache && cardCache.cards) {
        cards = cardCache.cards;
      }
      return { cards };
    } else return;
  } catch (err) {
    throw new Error("Could not fetch Merchant data");
  }
}

export const Customer = () => {
  const {
    connect,
    user,
    userInfo,
    claimAccepted,
    setClaimAccepted,
    claimRejected,
    setClaimRejected,
    redeemed,
    setRedeemed,
    redeemRejected,
    setRedeemRejected,
  } = useContext(DataContext);
  const data = useLoaderData() as any;
  const [searchParams] = useSearchParams();
  const isAtCustomer = useMatch("/customer/:customerId");
  const isCustomerRoot = useMatch("/customer/");
  const isScanning = useMatch("/customer/:customerId/scan");
  const redirect = searchParams.get("redirect");
  const navigate = useNavigate();
  const toast = useToast();
  const [hasCamera, setHasCamera] = useState<boolean>(false);
  useEffect(() => {
    QrScanner.hasCamera()
      .then(() => setHasCamera(true))
      .catch((e) => {
        console.log(e);
        setHasCamera(false);
      });
  }, []);

  const variants = {
    pre: { opacity: 0 },
    visible: { opacity: 1 },
    hide: { opacity: 0, display: "none" },
  };

  useEffect(() => {
    if (user && userInfo && connect) {
      connect(user.id);
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userInfo]);

  useEffect(() => {
    if (!redirect && isCustomerRoot && userInfo && user && user.id) {
      navigate(`./${user.id}`);
    } else if (
      redirect &&
      redirect === "checkout" &&
      isCustomerRoot &&
      userInfo &&
      user &&
      user.id
    ) {
      navigate(`./${user.id}/checkout`);
    } else if (isCustomerRoot && userInfo && user) {
      navigate(`../`);
    }
  }, [redirect, isCustomerRoot, user, userInfo, navigate]);

  useEffect(() => {
    if (redeemed) {
      setRedeemed(undefined);
      navigate("./");
      toast({
        title: "Your free item has been redeemed",
        position: "top",
        status: "success",
        duration: 1500,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemed, setRedeemed]);

  useEffect(() => {
    if (redeemRejected) {
      setRedeemRejected(undefined);
      navigate("./");
      toast({
        title: "Uh oh!",
        description: `The merchant has declined your FREE item.`,
        position: "top",
        status: "warning",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemRejected, setRedeemRejected]);

  useEffect(() => {
    if (claimAccepted) {
      setClaimAccepted(undefined);
      navigate(`./`);
      toast({
        title: "Your card has been punched!",
        description: `It has been punched ${
          claimAccepted?.quantity
        } ${pluralize("time", claimAccepted?.quantity)}`,
        position: "top",
        status: "success",
        duration: 1500,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimAccepted]);

  useEffect(() => {
    if (claimRejected) {
      setClaimRejected(undefined);
      navigate("./");
      toast({
        title: "Uh oh!",
        description: `The merchant has declined to punch your card.`,
        position: "top",
        status: "warning",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimRejected]);

  // Has another new card from merchant
  // Add them 'yet to be redeemed'
  const cards = [] as Card[];
  const toRedeemCards = [] as Card[];

  if (data && data.cards) {
    const checkCards = data?.cards as Card[];
    checkCards.forEach((c) => {
      const qtyToClaim =
        c.redeem_index -
        1 -
        (c.claims ? c.claims.reduce((a, c) => a + c.quantity, 0) : 0);
      // Check Cards for full claims
      if (qtyToClaim === 0) {
        // Not redeemed
        if (c.redeemed_at === null) {
          // Has another newer card from the merchant
          if (
            checkCards.filter(
              (k) =>
                c.merchant_id === k.merchant_id && c.created_at < k.created_at
            ).length > 0
          ) {
            toRedeemCards.push(c);
          } else {
            // Is the newest
            cards.push(c);
          }
        } else {
          cards.push(c);
        }
      } else {
        cards.push(c);
      }
    });
  }

  return (
    <>
      <Flex
        id="CustomerContainer"
        flexDirection={"column"}
        width={[
          "90%", // 0-30em      // 480px
          "90%", // 30em-48em   // 768px
          "75%", // 48em-62em   // 992px
          "75%", // 62em-80em   // 1280px
          "75%", // 80em-96em+  // 1536px
        ]}
        maxW={"900"}
      >
        {(isAtCustomer || isScanning) && (
          <Center flexDirection={"column"} height={"100%"} width={"100%"}>
            <Flex
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Button
                onClick={() => navigate("../")}
                leftIcon={<ArrowBackIcon />}
                variant="ghost"
                width={"fit-content"}
                marginBottom={"5"}
              >
                Back
              </Button>
              <Flex flexDirection={"row"}>
                {hasCamera && (
                  <Button
                    leftIcon={<AiFillCamera />}
                    onClick={() => navigate("./scan")}
                  >
                    Scan
                  </Button>
                )}
                {/* {user && user.id && user.subscription_customer_id && (
                <Form method="post" action="./checkout">
                  <input type="hidden" name="user_id" value={user.id} />
                  <input
                    type="hidden"
                    name="customer_id"
                    value={user.subscription_customer_id}
                  />
                  <input type="hidden" name="session_type" value="portal" />
                  <Button
                    type="submit"
                    leftIcon={<IoSettingsOutline />}
                    variant="ghost"
                    _hover={{
                      color: "white",
                      bgGradient: "linear(to-r, red.500, yellow.500)",
                    }}
                  >
                    Subscription
                  </Button>
                </Form>
              )} */}
              </Flex>
            </Flex>
            <Heading marginBottom={"5"} textAlign={"center"}>
              My Cards
            </Heading>
            <Divider orientation="horizontal" mb={"5"} />
            <div style={{ width: "100%" }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                }}
              >
                {user && user.subscription_id && !user.merchant_id && (
                  <Flex direction={"column"}>
                    <ChakraCard
                      direction={{ base: "column", sm: "row" }}
                      margin={"5"}
                      bg={"orange.50"}
                      overflow="hidden"
                    >
                      <Image
                        objectFit="cover"
                        maxW={{ base: "100%", sm: "200px" }}
                        src="https://images.pexels.com/photos/4992835/pexels-photo-4992835.jpeg?auto=compress&cs=tinysrgb&dpr=1&auto=format&fit=crop&w=200&h=200&q=80"
                        alt="Coffee shop business location"
                        borderRadius="lg"
                      />
                      <Stack>
                        <CardBody>
                          <Heading size="md">Thanks for subscribing!</Heading>
                          <Text>
                            {`Please finish setting up your business profile.`}
                          </Text>
                          <Text color="orange.600" fontSize="s"></Text>
                        </CardBody>
                        <CardFooter>
                          <Link as={ReactLink} to={`/merchant/new`}>
                            <Button
                              rightIcon={<ArrowForwardIcon />}
                              variant="outline"
                            >
                              Finish set-up
                            </Button>
                          </Link>
                        </CardFooter>
                      </Stack>
                    </ChakraCard>
                    {cards && cards.length > 0 && (
                      <Divider orientation={"horizontal"} />
                    )}
                  </Flex>
                )}
                <Flex flexDirection={"column"}>
                  <LayoutGroup>
                    <motion.ul layout style={{ listStyleType: "none" }}>
                      {cards.map((c) => {
                        const qtyToClaim =
                          c.redeem_index -
                          1 -
                          (c.claims
                            ? c.claims.reduce((a, c) => a + c.quantity, 0)
                            : 0);
                        const pc = Math.round(
                          ((c.claims
                            ? c.claims?.reduce((a, c) => a + c.quantity, 0)
                            : 0) /
                            (c.redeem_index - 1)) *
                            100
                        );
                        const toRedeem =
                          toRedeemCards?.filter(
                            (r) => r.merchant_id === c.merchant_id
                          ).length > 0;
                        return (
                          <motion.li
                            layout
                            variants={variants}
                            initial="pre"
                            animate="visible"
                            key={c.id}
                          >
                            <ChakraCard
                              direction={{ base: "column", sm: "row" }}
                              overflow="hidden"
                              my={"5"}
                              mx={"auto"}
                              size={"sm"}
                              minW={"300px"}
                              width={"100%"}
                              maxW={"640px"}
                              bgGradient="linear(to-r, white, orange.50)"
                              borderBottom={"8px aqua"}
                              zIndex={toRedeem ? 1 : 0}
                            >
                              <Stack width={"100%"}>
                                <CardHeader
                                  display={"flex"}
                                  flexDirection={"row"}
                                >
                                  <Avatar
                                    name={c.merchant?.business_name}
                                    color="white"
                                    src={c.merchant?.logo_url || ""}
                                    size="lg"
                                  />
                                  <Heading size="md" my="auto" ml="5">
                                    {c.merchant?.business_name}
                                  </Heading>
                                </CardHeader>

                                <CardFooter>
                                  <Flex
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                  >
                                    <Flex>
                                      <Button
                                        rightIcon={<ArrowForwardIcon />}
                                        variant={"solid"}
                                        _hover={{
                                          color: "white",
                                          bgGradient:
                                            "linear(to-r, red.500, yellow.500)",
                                        }}
                                        onClick={() =>
                                          navigate(`./card/${c.merchant_id}`)
                                        }
                                      >
                                        Open
                                      </Button>
                                      {qtyToClaim > 0 &&
                                        c.redeem_index - 1 !== qtyToClaim && (
                                          <Tooltip
                                            placement="bottom-end"
                                            label={`How many purchases until you can claim a free item. Currently: ${
                                              c.redeem_index - qtyToClaim - 1
                                            }/${c.redeem_index}`}
                                          >
                                            <Tag
                                              size="lg"
                                              ml="5"
                                              variant={"outline"}
                                              colorScheme={"orange"}
                                            >
                                              {`${qtyToClaim}`} more!
                                            </Tag>
                                          </Tooltip>
                                        )}
                                    </Flex>
                                    <Menu>
                                      <MenuButton
                                        as={IconButton}
                                        aria-label="Punch Card"
                                        icon={<Punch size={16} />}
                                        variant="solid"
                                        backgroundColor={"orange.300"}
                                        colorScheme="orange"
                                      />
                                      <MenuList padding={"unset"}>
                                        <MenuItem
                                          minH="58px"
                                          icon={<Punch size={30} />}
                                          backgroundColor={"orange.300"}
                                          color={"white"}
                                          _hover={{
                                            color: "white",
                                            bgGradient:
                                              "linear(to-r, red.500, yellow.500)",
                                          }}
                                          onClick={() =>
                                            navigate(`./claim/${c.id}`)
                                          }
                                          borderRadius={"md"}
                                        >
                                          Get your card punched
                                        </MenuItem>
                                      </MenuList>
                                    </Menu>
                                  </Flex>
                                </CardFooter>
                                <Box
                                  height={"2"}
                                  borderBottomRightRadius={"3"}
                                  borderTopRightRadius={pc === 100 ? "0" : "3"}
                                  width={`${pc}%`}
                                  backgroundColor={"orange.300"}
                                  // bgGradient={"linear(to-r, red.500, yellow.500)"}
                                >
                                  _
                                </Box>
                              </Stack>
                            </ChakraCard>
                            {toRedeem && (
                              <ChakraCard
                                backgroundColor={"green.300"}
                                color={"white"}
                                direction={"column"}
                                maxW={"620px"}
                                minW={"280px"}
                                mx={"auto"}
                                my={"5"}
                                overflow="hidden"
                                size={"sm"}
                                transform={"translateY(-40px)"}
                                width={"95%"}
                              >
                                <Stack width={"100%"}>
                                  <CardHeader
                                    display={"flex"}
                                    flexDirection={"row"}
                                  >
                                    <Heading
                                      size="md"
                                      mt={"5"}
                                      mx={"auto"}
                                      textAlign={"center"}
                                    >
                                      You have an item to redeem!
                                    </Heading>
                                  </CardHeader>
                                  <CardBody>
                                    <Stack>
                                      {toRedeemCards
                                        .filter(
                                          (k) => k.merchant_id === c.merchant_id
                                        )
                                        .map((i) => {
                                          const buttons = [];
                                          for (
                                            let j = 0;
                                            j < i.redeem_quantity;
                                            j++
                                          ) {
                                            // if there are redeems pending
                                            if (
                                              i.redeems &&
                                              i.redeems.length > 0
                                            ) {
                                              if (j <= i.redeems.length - 1) {
                                                buttons.push(
                                                  <Tooltip
                                                    placement={"top"}
                                                    label={
                                                      "Waiting on the shop to accept. Click to open a QR code for them to scan."
                                                    }
                                                  >
                                                    <Button
                                                      variant={"solid"}
                                                      colorScheme="orange"
                                                      onClick={() => {
                                                        navigate(
                                                          `./redeemed/${
                                                            i.redeems![j].id
                                                          }`
                                                        );
                                                      }}
                                                    >{`Redeemed ${dayjs(
                                                      i.redeems![j].created_at
                                                    ).fromNow()}`}</Button>
                                                  </Tooltip>
                                                );
                                              } else {
                                                buttons.push(
                                                  <Button
                                                    id={`${i.id}-${j}`}
                                                    key={`${i.id}-${j}`}
                                                    variant={"solid"}
                                                    color={"white"}
                                                    backgroundColor={
                                                      "green.500"
                                                    }
                                                    rightIcon={
                                                      <ArrowForwardIcon />
                                                    }
                                                    _hover={{
                                                      color: "white",
                                                      bgGradient:
                                                        "linear(to-r, yellow.500, red.500,)",
                                                    }}
                                                    onClick={() =>
                                                      navigate(
                                                        `./redeem/${i.id}`
                                                      )
                                                    }
                                                  >
                                                    <Text
                                                      fontSize={{
                                                        base: "xs",
                                                        sm: "md",
                                                      }}
                                                    >
                                                      {i.redeem_quantity > 1
                                                        ? `${pluralize(
                                                            "item",
                                                            i.redeem_quantity
                                                          )} from ${dayjs(
                                                            max(
                                                              i.claims.map(
                                                                (m) =>
                                                                  m.created_at
                                                              )
                                                            )
                                                          ).fromNow()}`
                                                        : `Claim FREE item! From ${dayjs(
                                                            max(
                                                              i.claims.map(
                                                                (m) =>
                                                                  m.created_at
                                                              )
                                                            )
                                                          ).fromNow()}`}
                                                    </Text>
                                                  </Button>
                                                );
                                              }
                                            } else {
                                              buttons.push(
                                                <Button
                                                  id={`${i.id}-${j}`}
                                                  key={`${i.id}-${j}`}
                                                  variant={"solid"}
                                                  color={"white"}
                                                  backgroundColor={"green.500"}
                                                  rightIcon={
                                                    <ArrowForwardIcon />
                                                  }
                                                  _hover={{
                                                    color: "white",
                                                    bgGradient:
                                                      "linear(to-r, yellow.500, red.500,)",
                                                  }}
                                                  onClick={() =>
                                                    navigate(`./redeem/${i.id}`)
                                                  }
                                                >
                                                  <Text
                                                    fontSize={{
                                                      base: "xs",
                                                      sm: "md",
                                                    }}
                                                  >
                                                    {i.redeem_quantity > 1
                                                      ? `${pluralize(
                                                          "item",
                                                          i.redeem_quantity
                                                        )} from ${dayjs(
                                                          max(
                                                            i.claims.map(
                                                              (m) =>
                                                                m.created_at
                                                            )
                                                          )
                                                        ).fromNow()}`
                                                      : `Claim FREE item! From ${dayjs(
                                                          max(
                                                            i.claims.map(
                                                              (m) =>
                                                                m.created_at
                                                            )
                                                          )
                                                        ).fromNow()}`}
                                                  </Text>
                                                </Button>
                                              );
                                            }
                                            // buttons.push(
                                            //   <Button
                                            //     id={`${i.id}-${j}`}
                                            //     key={`${i.id}-${j}`}
                                            //     variant={"solid"}
                                            //     color={"white"}
                                            //     backgroundColor={"green.500"}
                                            //     rightIcon={<ArrowForwardIcon />}
                                            //     _hover={{
                                            //       color: "white",
                                            //       bgGradient:
                                            //         "linear(to-r, yellow.500, red.500,)",
                                            //     }}
                                            //     onClick={() =>
                                            //       navigate(`./redeem/${i.id}`)
                                            //     }
                                            //   >
                                            //     {i.redeem_quantity > 1
                                            //       ? `${pluralize(
                                            //           "item",
                                            //           i.redeem_quantity
                                            //         )} from ${dayjs(
                                            //           max(
                                            //             i.claims.map(
                                            //               (m) => m.created_at
                                            //             )
                                            //           )
                                            //         ).fromNow()}`
                                            //       : `FREE item! From ${dayjs(
                                            //           max(
                                            //             i.claims.map(
                                            //               (m) => m.created_at
                                            //             )
                                            //           )
                                            //         ).fromNow()}`}
                                            //   </Button>
                                            // );
                                          }
                                          return buttons;
                                        })}
                                    </Stack>
                                  </CardBody>
                                  <CardFooter>
                                    {/* <Text ml="5">
                                    Don't forget to redeem things. You want free
                                    stuff, right?.
                                  </Text> */}
                                  </CardFooter>
                                </Stack>
                              </ChakraCard>
                            )}
                          </motion.li>
                        );
                      })}
                      {((cards && cards.length === 0) || !cards) && (
                        <Flex flexDirection={"column"} textAlign={"center"}>
                          <Empty size={200} />
                          <Heading size={"lg"} color="orange">
                            No cards yet!
                          </Heading>
                          <Text>
                            Head to participating stores and get a card punched.
                          </Text>
                        </Flex>
                      )}
                    </motion.ul>
                  </LayoutGroup>
                </Flex>
              </motion.div>
            </div>
          </Center>
        )}
      </Flex>
      <Outlet />
    </>
  );
};
