import { useContext, useEffect, useState } from "react";
import {
  Link as ReactLink,
  Outlet,
  useLoaderData,
  useNavigate,
  useSearchParams,
  useMatch,
} from "react-router-dom";
import localforage from "localforage";
import { callFunction } from "../controllers/apiClient";
import { DataContext } from "../lib/dataContext";
import { AIContext } from "../lib/aiContext";
import { Merchant, User } from "../lib/constants";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  Heading,
  Link,
  Image,
  Spinner,
  Stack,
  Text,
  WrapItem,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { IoSettingsOutline } from "react-icons/io5";
import { LayoutGroup, motion } from "framer-motion";

export async function loader({ params }: any) {
  try {
    const merchantId = params.merchantId;
    if (merchantId) {
      let merchantResponse = await localforage.getItem(merchantId);
      let locationResponse = await localforage.getItem(
        `${merchantId}_locations`
      );

      if (!merchantResponse || !locationResponse) {
        merchantResponse = await callFunction("merchant", "get", undefined, {
          merchant_id: params.merchantId,
        });
        if (merchantResponse) {
          localforage.setItem(merchantId, merchantResponse);
        }
      }
      return { merchantResponse };
    } else return;
  } catch (err) {
    throw new Error("Could not fetch Merchant data");
  }
}

export const MerchantPage = () => {
  const { trackEvent } = useContext(AIContext);
  const idempotencyKey = uuid();
  const data = useLoaderData() as any;
  const merchantResponse = data?.merchantResponse as Merchant;
  const {
    location,
    merchant,
    setMerchant,
    user,
    userInfo,
    getUserInfo,
    setUser,
    squareAuthCode,
    setSquareAuthCode,
  } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const responseType = searchParams.get("response_type");
  const state = searchParams.get("state");
  const redirect = searchParams.get("redirect");
  const refresh = searchParams.get("refresh");
  const [isSettingUpSquare, setIsSettingUpSquare] = useState<boolean>(false);
  const isMerchant = useMatch("/merchant/:merchantId");
  const isMerchantRoot = useMatch("/merchant/");
  const navigate = useNavigate();

  useEffect(() => {
    if (code && responseType && state && code !== squareAuthCode) {
      setSquareAuthCode(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchAuth() {
      if (squareAuthCode) {
        try {
          setIsSettingUpSquare(true);
          let ui;
          if (!userInfo) {
            ui = await getUserInfo();
          } else {
            ui = userInfo;
          }
          if (ui) {
            const m = await callFunction("square/auth", "post", {
              redirectUri: `${window.location.protocol}//${window.location.host}/merchant`,
              code: squareAuthCode,
              user_id: ui.userId,
              idempotencyKey,
            });
            setMerchant(m);
            if (redirect && redirect === "settings") {
              navigate(`${m.id}/settings?refresh=true`);
            } else {
              navigate(`${m.id}?refresh=true`);
            }
          } else {
            throw new Error("Could not get user information");
          }
          return true;
        } catch (e) {
          const msg = e as any;
          trackEvent({
            name: "ErrorPage",
            properties: {
              user: user || "no user",
              userInfo: userInfo || "no userInfo",
              location: "Merchant",
              error: e,
            },
          });
          throw new Error(msg);
        } finally {
          localforage.removeItem("lastRefresh_locations");
          localforage.removeItem("lastRefresh_catalog");
          setIsSettingUpSquare(false);
        }
      }
    }

    // To-do, think about catching errors and showing messages?
    // I would like it to bubble up to the ErrorElement!

    // Because this is within an outlet it gets re-rendered when
    // user is set which can happen after code and authcode are set
    if (code && squareAuthCode && user && userInfo) {
      fetchAuth()
        .then()
        .catch((e) => {
          throw new Error("Could not fetch auth");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareAuthCode, user, userInfo]);

  useEffect(() => {
    if (merchantResponse && !isEqual(merchantResponse, merchant)) {
      const t1 = {
        ...merchantResponse,
        business_name: undefined,
        redeem_index: undefined,
        logo_url: undefined,
        catalog_items: undefined,
        subscription_locations: undefined,
      };
      const t2 = {
        ...merchant,
        business_name: undefined,
        redeem_index: undefined,
        logo_url: undefined,
        catalog_items: undefined,
        subscription_locations: undefined,
      };

      if (!isEqual(t1, t2)) {
        setMerchant(merchantResponse);
      } else {
        // then we assume the other settings have changed
        // and those components will use setMerchant themselves
      }
    }
    if (
      isMerchant &&
      merchantResponse?.subscription_locations &&
      merchantResponse.subscription_locations.length === 1 &&
      merchantResponse.subscription_locations[0] !== location
    ) {
      // navigate(`./location/${locationResponse[0].id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, merchantResponse]);

  const variants = {
    pre: { opacity: 0 },
    visible: { opacity: 1 },
    hide: { opacity: 0, display: "none" },
  };

  useEffect(() => {
    if (
      !squareAuthCode &&
      !redirect &&
      isMerchantRoot &&
      userInfo &&
      user &&
      user.merchant_id
    ) {
      navigate(`./${user.merchant_id}`);
    } else if (!code && !squareAuthCode && isMerchantRoot && userInfo && user) {
      navigate(`../`);
    }
  }, [
    code,
    squareAuthCode,
    redirect,
    isMerchantRoot,
    user,
    userInfo,
    navigate,
  ]);

  // This is here when a user has subscribed, and created a business,
  // the user is redirected to this page but the user state has not
  // refreshed (i.e. missing merchant_id, locations, etc.), so we refresh
  useEffect(() => {
    async function fetchUser() {
      const user = (await callFunction("user", "get")) as User;
      return user;
    }
    if (refresh) {
      fetchUser().then((u) => {
        if (u && u.subscription_id && u.merchant_id) {
          setUser(u);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const disabled =
    merchant && user
      ? (merchant?.subscription_locations?.filter((l) => l.active).length ||
          0) > (user?.subscription_quantity || 0)
      : false;

  return (
    <Flex
      id="MerchantContainer"
      width={[
        "90%", // 0-30em      // 480px
        "90%", // 30em-48em   // 768px
        "75%", // 48em-62em   // 992px
        "75%", // 62em-80em   // 1280px
        "75%", // 80em-96em+  // 1536px
      ]}
      maxW={"900"}
    >
      {isSettingUpSquare ? (
        <Center height={"100%"} width={"100%"} mt="20">
          <Flex flexDirection={"column"}>
            <Center>
              <Spinner
                size={"xl"}
                color={"orange"}
                speed="0.65s"
                thickness="4px"
              />
            </Center>
            <Center padding={"5"}>
              <Text fontSize="2xl" color={"gray.500"}>
                Setting things up...
              </Text>
            </Center>
          </Flex>
        </Center>
      ) : (
        <Center height={"100%"} width={"100%"}>
          <Flex flexDirection={"column"} width={"100%"}>
            {isMerchant && merchant && (
              <>
                <Flex
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Button
                    onClick={() => navigate("../")}
                    leftIcon={<ArrowBackIcon />}
                    variant="ghost"
                    width={"fit-content"}
                    marginBottom={"5"}
                  >
                    Back
                  </Button>
                  <Button
                    leftIcon={<IoSettingsOutline />}
                    variant="ghost"
                    width={"fit-content"}
                    marginBottom={"5"}
                    onClick={() => navigate("./settings")}
                  >
                    Settings
                  </Button>
                </Flex>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  <Center marginBottom={"5"}>
                    <Heading>My Business</Heading>
                  </Center>
                  <Divider orientation="horizontal" mb={"5"} />
                  {(!merchantResponse?.subscription_locations ||
                    merchantResponse.subscription_locations.filter(
                      (l) => l.active
                    ).length === 0) && (
                    <WrapItem>
                      <Card
                        direction={{ base: "column", sm: "row" }}
                        margin={"5"}
                        bg={"orange.50"}
                        width={"100%"}
                        overflow="hidden"
                      >
                        <Image
                          objectFit="cover"
                          maxW={{ base: "100%", sm: "200px" }}
                          src="https://images.pexels.com/photos/4992835/pexels-photo-4992835.jpeg?auto=compress&cs=tinysrgb&dpr=1&auto=format&fit=crop&w=200&h=200&q=80"
                          alt="Coffee shop business location"
                          borderRadius="lg"
                        />
                        <Stack>
                          <CardBody>
                            <Heading size="md">Setup Locations</Heading>
                            <Text>
                              {`You need to have at least one business location configured.`}
                            </Text>
                            {/* <Text>
                            {" "}
                            {`Click here to add a location manually`}
                            {user?.merchant_id ? "." : " or via Square."}
                          </Text> */}
                            <Text color="orange.600" fontSize="s"></Text>
                          </CardBody>
                          <CardFooter>
                            <Link
                              as={ReactLink}
                              to={`./settings?index=location`}
                            >
                              <Button
                                rightIcon={<ArrowForwardIcon />}
                                variant="outline"
                              >
                                Add
                              </Button>
                            </Link>
                          </CardFooter>
                        </Stack>
                      </Card>
                    </WrapItem>
                  )}
                  {!!merchantResponse?.merchant_id &&
                    (!merchantResponse?.catalog_items ||
                      merchantResponse.catalog_items.length === 0) && (
                      <WrapItem>
                        <Card
                          direction={{ base: "column", sm: "row" }}
                          margin={"5"}
                          bg={"orange.50"}
                          width={"100%"}
                          overflow="hidden"
                        >
                          <Image
                            objectFit="cover"
                            maxW={{ base: "100%", sm: "200px" }}
                            src="https://images.pexels.com/photos/7362647/pexels-photo-7362647.jpeg?auto=compress&cs=tinysrgb&auto=format&fit=crop&w=200&h=200&q=80"
                            alt="Coffee cup on a table"
                            borderRadius="lg"
                          />
                          <Stack>
                            <CardBody>
                              <Heading size="md">Setup Catalog</Heading>
                              <Text>
                                {`Choose which items from your Square Catalog can accrue punches.`}
                              </Text>
                              {/* <Text>
                            {" "}
                            {`Click here to add a location manually`}
                            {user?.merchant_id ? "." : " or via Square."}
                          </Text> */}
                              <Text color="orange.600" fontSize="s"></Text>
                            </CardBody>
                            <CardFooter>
                              <Link
                                as={ReactLink}
                                to={`./settings?index=catalog`}
                              >
                                <Button
                                  rightIcon={<ArrowForwardIcon />}
                                  variant="outline"
                                >
                                  Choose
                                </Button>
                              </Link>
                            </CardFooter>
                          </Stack>
                        </Card>
                      </WrapItem>
                    )}
                  {disabled && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertTitle>Too many locations!</AlertTitle>
                      <AlertDescription>
                        {`Number of locations your subscription supports is ${user?.subscription_quantity}.`}
                        <br />
                        {`Please modify your locations in the `}
                        <Link as={ReactLink} to={`./settings?index=location`}>
                          <u>Settings</u>
                        </Link>
                      </AlertDescription>
                    </Alert>
                  )}
                  {merchantResponse?.subscription_locations &&
                    merchantResponse?.subscription_locations.filter(
                      (l) => l.active
                    ).length > 0 && (
                      <Flex flexDirection={"column"}>
                        <LayoutGroup>
                          <motion.ul layout style={{ listStyleType: "none" }}>
                            {merchantResponse.subscription_locations
                              .filter((l) => l.active)
                              .map((l) => (
                                <motion.li
                                  layout
                                  variants={variants}
                                  initial="pre"
                                  animate="visible"
                                  key={l.id}
                                >
                                  <Card
                                    direction={{ base: "column", sm: "row" }}
                                    overflow="hidden"
                                    margin={"5"}
                                    size={"sm"}
                                    bgGradient={
                                      disabled
                                        ? "linear(to-r, white, red.50)"
                                        : "linear(to-r, white, orange.50)"
                                    }
                                  >
                                    <Stack width={"100%"}>
                                      <CardHeader>
                                        <Heading size="md">{l.name}</Heading>
                                      </CardHeader>

                                      <CardFooter>
                                        <Button
                                          isDisabled={disabled}
                                          rightIcon={<ArrowForwardIcon />}
                                          variant={"solid"}
                                          onClick={() =>
                                            navigate(`./location/${l.id}`)
                                          }
                                          _hover={
                                            disabled
                                              ? {}
                                              : {
                                                  color: "white",
                                                  bgGradient:
                                                    "linear(to-r, red.500, yellow.500)",
                                                }
                                          }
                                        >
                                          Open
                                        </Button>
                                      </CardFooter>
                                    </Stack>
                                  </Card>
                                </motion.li>
                              ))}
                          </motion.ul>
                        </LayoutGroup>
                      </Flex>
                    )}
                </motion.div>
              </>
            )}
            <Outlet />
          </Flex>
        </Center>
      )}
    </Flex>
  );
};
