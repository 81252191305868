import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Center, Flex, Text, Spinner } from "@chakra-ui/react";
import ErrorPage from "./ErrorPage";
import { GatewayRedirect } from "../lib/constants";
import localforage from "localforage";

export const Gateway = () => {
  const { type, id } = useParams();

  useEffect(() => {
    if (type && id) {
      localforage.setItem("redirect", { type, id } as GatewayRedirect);
      switch (type) {
        case "claim":
          window.location.href = `${window.location.protocol}//${window.location.host}/customer/claim/${id}`;
          break;
        case "redeem":
          window.location.href = `${window.location.protocol}//${window.location.host}/merchant/redeem/${id}`;
          break;
        case "location":
          window.location.href = `${window.location.protocol}//${window.location.host}/claim/${id}`;
          break;
        default:
          window.location.href = `${window.location.protocol}//${window.location.host}`;
          break;
      }
    }
  }, [id, type]);

  if (!type && !id) {
    return <ErrorPage errorMessage="No type or id" errorLocation="Gateway" />;
  }

  return (
    <Container
      className="content"
      maxW="container.lg"
      minWidth={"-webkit-fill-available"}
      height="500px"
      centerContent
    >
      <Center>
        <Flex flexDirection={"column"}>
          <Spinner
            margin={"auto"}
            size={"xl"}
            color={"orange"}
            speed="0.65s"
            thickness="4px"
          />
          <Text mt={"2"} fontSize={"lg"} textAlign={"center"}>
            Opening...
          </Text>
        </Flex>
      </Center>
    </Container>
  );
};
