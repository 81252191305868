type Props = {
  size: string;
};

export const FeatureBuy: React.FC<Props> = ({ size }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="200" height="200" fill="white" />
      <path
        d="M105.86 85.7314L126.777 138.893H191.609L167.453 85.7314H105.86Z"
        fill="#20242B"
      />
      <path
        d="M90.0382 148.092L126.377 138.893H191.61L191.288 148.092L166.23 156.891L89.731 156.891L90.0382 148.092Z"
        fill="#1E2227"
      />
      <path
        d="M69.9752 134.094H163.825C166.101 134.094 168.171 132.64 168.981 130.479L194.451 64.8347C195.099 63.096 193.886 61.3022 192.059 61.3022H98.21C95.9336 61.3022 93.8634 62.7557 93.0535 64.9175L67.5834 130.561C66.9352 132.3 68.1485 134.094 69.9752 134.094Z"
        fill="black"
      />
      <path
        d="M74.7418 127.694H163.641L187.319 66.9014H98.4205L74.7418 127.694Z"
        fill="#FFF6EE"
      />
      <path
        d="M124.056 118.235C136.333 118.235 149.731 108.866 153.922 97.3496C158.114 85.8331 151.536 76.4639 139.26 76.4639C126.981 76.4639 113.581 85.8331 109.389 97.3496C105.198 108.866 111.777 118.235 124.056 118.235ZM133.548 85.6287L133.941 84.5485C134.39 83.3175 135.817 82.3195 137.129 82.3195C138.441 82.3195 139.142 83.3175 138.694 84.5485L138.299 85.6333C140.309 86.2102 141.703 87.575 142.04 89.4286C142.239 90.5245 141.288 91.8675 139.915 92.4283C138.543 92.989 137.269 92.5554 137.07 91.4594C136.886 90.4437 135.84 89.7874 134.406 89.7874C132.494 89.7874 130.434 91.0086 129.908 92.454C129.307 94.1075 129.731 94.4884 132.94 95.1705C134.488 95.4998 136.09 95.8402 137.239 96.7577C138.738 97.9531 139.046 99.7993 138.156 102.245C136.985 105.462 133.501 108.186 129.769 109.068L129.375 110.151C128.927 111.382 127.5 112.38 126.188 112.38C124.876 112.38 124.175 111.382 124.623 110.151L125.017 109.068C123.005 108.492 121.609 107.126 121.272 105.27C121.073 104.174 122.024 102.831 123.397 102.271C124.768 101.71 126.043 102.144 126.242 103.24C126.426 104.255 127.47 104.912 128.901 104.912C130.848 104.912 132.868 103.715 133.403 102.245C134.004 100.595 133.579 100.215 130.373 99.5329C128.824 99.2035 127.222 98.8629 126.072 97.9447C124.573 96.7486 124.265 94.9013 125.156 92.454C126.328 89.2345 129.815 86.5083 133.548 85.6287Z"
        fill="#FBAF1B"
      />
      <mask
        id="mask0_205_177"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="24"
        y="44"
        width="75"
        height="67"
      >
        <path
          d="M30.3695 44.5143C30.4757 44.196 84.1735 44.4082 90.7531 44.5143C97.3327 44.6205 97.7572 48.9715 97.7572 48.9715C97.7572 48.9715 98.0755 79.4286 98.1816 92.8C97.3327 105.322 85.4474 109.899 81.6266 109.992C77.2756 110.098 56.4756 110.416 42.2552 110.204C26.3369 109.037 24.3206 94.1795 24.3206 94.1795C24.3206 94.1795 24.3206 90.846 24.3174 86.0081C24.3104 75.3087 24.2875 57.2516 24.2144 51.6245C24.1083 43.4531 30.2634 44.8327 30.3695 44.5143Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_205_177)">
        <path
          d="M25.5839 44.179C32.8951 39.9396 56.3494 34.1031 74.8076 46.8629C91.1487 58.1594 105.987 57.9075 105.987 57.9075L108.747 57.7712L108.787 98.3931L-15.5583 98.4826C-15.5583 98.4826 -15.0073 70.6383 -15.1909 58.2031C4.75164 58.3867 17.8698 48.6521 25.5839 44.179Z"
          fill="#FFC85C"
        />
        <path
          d="M58.1977 48.3201C65.5089 44.0807 88.9632 38.2442 107.421 51.004C123.763 62.3006 138.601 62.0486 138.601 62.0486L141.36 61.9123L141.4 102.534L17.0555 102.624C17.0555 102.624 17.6065 74.7795 17.4228 62.3442C37.3654 62.5278 50.4835 52.7932 58.1977 48.3201Z"
          fill="#FBAF1B"
        />
        <path
          d="M78.0336 55.7303C85.3448 51.4908 108.799 45.6543 127.257 58.4141C143.598 69.7107 158.437 69.4588 158.437 69.4588L161.196 69.3224L161.236 109.944L36.8914 110.034C36.8914 110.034 37.4424 82.1896 37.2588 69.7544C57.2013 69.9379 70.3195 60.2034 78.0336 55.7303Z"
          fill="#FB831B"
        />
        <path
          d="M-46.0101 55.7441C-38.6686 51.5146 -14.9985 45.5319 3.41787 58.4217C19.7402 69.8459 34.8869 69.6895 34.8869 69.6895H38.0684L37.5379 109.831L-87.323 109.92C-87.323 109.92 -86.7697 82.0956 -86.9542 69.6895C-66.8971 70.6492 -53.7563 60.2067 -46.0101 55.7441Z"
          fill="#FB831B"
        />
      </g>
      <path
        d="M30.2343 44.1681C30.3404 43.8498 84.0383 44.062 90.6178 44.1681C97.1974 44.2743 97.6219 48.6253 97.6219 48.6253C97.6219 48.6253 97.9403 79.0824 98.0464 92.4538C97.1974 104.976 85.3121 109.552 81.4913 109.646C77.1403 109.752 56.3403 110.07 42.12 109.858C26.2016 108.69 24.1853 93.8334 24.1853 93.8334C24.1853 93.8334 24.1853 90.4998 24.1821 85.6619C24.1751 74.9625 24.1522 56.9054 24.0792 51.2783C23.9731 43.1069 30.1282 44.4865 30.2343 44.1681Z"
        stroke="#202020"
        strokeWidth="5"
      />
      <path
        d="M30.2343 44.1681C30.3404 43.8498 84.0383 44.062 90.6178 44.1681C97.1974 44.2743 97.6219 48.6253 97.6219 48.6253C97.6219 48.6253 97.9403 79.0824 98.0464 92.4538C97.1974 104.976 85.3121 109.552 81.4913 109.646C77.1403 109.752 56.3403 110.07 42.12 109.858C26.2016 108.69 24.1853 93.8334 24.1853 93.8334C24.1853 93.8334 24.1853 90.4998 24.1821 85.6619C24.1751 74.9625 24.1522 56.9054 24.0792 51.2783C23.9731 43.1069 30.1282 44.4865 30.2343 44.1681Z"
        stroke="#202020"
        strokeWidth="5"
      />
      <path
        d="M24.0796 60.7233L24.0265 85.9539C24.0265 85.9539 23.5224 85.8478 22.3816 85.7682C13.5735 84.0702 8.26738 78.7641 6.35718 70.5927C4.44698 62.4213 10.8143 60.6172 10.8143 60.6172L24.0796 60.7233Z"
        stroke="#202020"
        strokeWidth="5"
      />
    </svg>
  );
};
