import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { callFunction } from "../controllers/apiClient";

import { AnimatePresence, motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";
import { Redeem } from "../lib/constants";

export async function loader({ params }: any) {
  const redeemId = params.redeemId;
  if (redeemId) {
    try {
      const rd = await callFunction("redeem", "get", undefined, {
        redeem_id: redeemId,
      });
      if (!rd) throw new Error("Could not retrieve redemption information");
      return { redeem: rd };
    } catch (err) {
      console.log("Error:", err);
      throw new Error("Could not retrieve redemption information");
    }
  }
}

export const CustomerRedeemShow = () => {
  const data = useLoaderData() as any;
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({
    isOpen: true,
    onClose: () => {
      navigate(`../`);
    },
  });
  const [redeem, setRedeem] = useState<Redeem>();

  useEffect(() => {
    if (data && data.redeem) {
      const redeem = data.redeem as Redeem;
      setRedeem(redeem);
    }
  }, [data]);

  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h3" size="md">
            {"Please present this QR code!"}
          </Heading>
        </ModalHeader>
        <ModalBody
          padding={6}
          position={"relative"}
          height={"300px"}
          flex={"unset"}
        >
          <AnimatePresence>
            {redeem && (
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.3,
                }}
              >
                <Skeleton isLoaded={!!redeem}>
                  <Flex
                    id={redeem.id}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <QRCodeSVG
                      size={250}
                      style={{ margin: "auto" }}
                      value={`${window.location.protocol}//${
                        window.location.host
                      }/gateway/redeem/${redeem!.id}`}
                    />
                    {process.env.NODE_ENV !== "production" && (
                      <p>
                        {`${window.location.protocol}//${
                          window.location.host
                        }/gateway/redeem/${redeem!.id}`}
                      </p>
                    )}
                  </Flex>
                </Skeleton>
              </motion.div>
            )}
          </AnimatePresence>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
